import ColoredBox from 'components/ColoredBox'
import { useAppDispatch, useAppSelector } from 'commons/store/hooks'
import React, {useEffect, useState} from 'react'
import {
    fetchAdverseEvents,
    fetchAdverseEventsFields,
    selectAdverseEvents,
    selectEcrf
} from 'features/ecrf/redux/ecrfSlice'
import TableForm from 'components/Table/TableForm'
import { Box, Button, Collapse, TextField, Tooltip } from "@mui/material";
import { StyledGlobalMedicalCentersFiltersButton } from "../DashboardStats/DashboardStats.styled";
import FilterListIcon from "@mui/icons-material/FilterList";
import DeleteSweepRoundedIcon from "@mui/icons-material/DeleteSweepRounded";
import ModalForm from "components/Form/Modal/ModalForm";
import ElementTable from "core/helpers/ElementTable";
import { enqueueSnackbar } from 'notistack'

const DashboardAdverseEvents = () => {
    const dispatch      = useAppDispatch()
    const adverseEvents = useAppSelector(selectAdverseEvents)
    const ecrf          = useAppSelector(selectEcrf)

    const [page, setPage] = useState(1)
    const [columns, setColumns] = useState<any[]>([])
    const [isLoading, setLoading] = useState(true)

    const [data, setData] = useState<any>({
        itemsPerPage: 0,
        totalItems: 0,
        _embedded: {item: []}
    })

    /**
     * This function is used to fetch ecrves list
     */
    const fetchData = async (initial?: boolean) => {
        setLoading(true)

        try {
            let fields: any = [];
            if (initial) {
                fields = await dispatch(fetchAdverseEventsFields()).unwrap()
                fields && setColumns(ElementTable.createColumns(fields.slice(0, 4)) || [])
            }

            const randomId = (ecrfRandomId !== '' && !initial) ? ecrfRandomId : null
            const response = await dispatch(fetchAdverseEvents({page, randomId})).unwrap()
            response && setData({rows: response._embedded?.item})
        } catch (error: any) {
            enqueueSnackbar(error?.detail || error?.response?.data, {variant: 'warning'})
        }

        setLoading(false)
    }

    /**
     * Change status of filters buttons
     */
    const [displayFilters, setDisplayFilters] = useState(false)
    const [isButtonFiltersDisabled, setIsButtonFiltersDisabled] = useState(true)
    const changeButtonFiltersStatus = () => {
        if (ecrfRandomId !== '') {
            setIsButtonFiltersDisabled(false)
        } else {
            setIsButtonFiltersDisabled(true)
        }
    }

    /**
     * Handle change value of ecrf input
     */
    const [ecrfRandomId, setEcrfRandomId] = useState<string>('')
    const handleEcrfRandomId = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEcrfRandomId(e.target.value)
    }

    useEffect(() => {
        changeButtonFiltersStatus()
    }, [ecrfRandomId])

    /**
     * Clear all filters values
     */
    const clearFilters = () => {
        setEcrfRandomId('')
        setPage(1)
        fetchData(true).then()
    }

    useEffect(() => {
        fetchData(true).then()
    }, [dispatch, page, ecrf]);

    return (
        <ColoredBox pt={0} px={5} maxWidth="100%" ml="auto" mr="auto">
            {/* <--- FILTERS ZONE | BEGIN ---> */}
            <Box display={'flex'} gap={'20px'} justifyContent={'end'}>
                <Button
                    style={{whiteSpace: 'nowrap', marginTop: '20px'}}
                    color={'primary'}
                    size={'small'}
                    onClick={() => setDisplayFilters(!displayFilters)}
                    variant={'outlined'}
                >
                    {displayFilters ? 'Ukryj filtry' : 'Pokaż filtry'}
                </Button>
            </Box>
            <Collapse in={displayFilters}>
                <Box display={'flex'} alignItems={'center'} mt={1} border={`1px solid #e7e7e7`} p={3} borderRadius={'10px'}>
                    <Box display="grid" gridTemplateColumns={'1fr 1fr 1fr 1fr 1fr'} alignItems="center" style={{gap: "20px"}}>
                        <Tooltip title="Numer randomizacyjny" arrow>
                            <TextField
                                label={'Numer randomizacyjny'}
                                onChange={handleEcrfRandomId}
                                value={ecrfRandomId}
                                fullWidth
                                variant={'outlined'}
                                size={'small'}
                            />
                        </Tooltip>

                        <Box display={'flex'} gap={'20px'} alignItems={'center'}>
                            <Tooltip title={'Filtruj po wybranych opcjach'} arrow>
                                <StyledGlobalMedicalCentersFiltersButton
                                    onClick={() => {
                                        if (!isButtonFiltersDisabled) fetchData()
                                    }}
                                    status={!isButtonFiltersDisabled && !isLoading}>
                                    <FilterListIcon/> Filtruj
                                </StyledGlobalMedicalCentersFiltersButton>
                            </Tooltip>

                            <Tooltip title={'Wyczyść wszystkie filtry'} arrow>
                                <StyledGlobalMedicalCentersFiltersButton
                                    onClick={() => clearFilters()}
                                    status={!isButtonFiltersDisabled && !isLoading}
                                >
                                    <DeleteSweepRoundedIcon/> Wyczyść
                                </StyledGlobalMedicalCentersFiltersButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
            </Collapse>
            {/* <--- FILTERS ZONE | END ---> */}

            <TableForm
                collections={!isLoading && {rows: adverseEvents._embedded?.item || []}}
                pagination={{
                    current: page,
                    pageSize: adverseEvents.itemsPerPage,
                    total: adverseEvents.totalItems
                }}
                page={page}
                setPage={setPage}
                columns={columns}
                tableName='Zdarzenia niepożądane'
                elementName={adverseEvents && adverseEvents.length ? adverseEvents[0].elementName : ''}
                isLoading={isLoading}
                ecrfOption={true}
            />
            <ModalForm/>
        </ColoredBox>
    )
}

export default DashboardAdverseEvents
