import React, { useState } from 'react';
import { Box, Collapse, TextField, Tooltip } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { statusDescriptions, statusDictionary } from '../static/DrugsManagementDictionary';
import {
    StyledDrugsButton,
    StyledDrugsButtonFilter,
    StyledDrugsFiltersItems,
    StyledDrugsFiltersItemsFilters,
    StyledDrugsFiltersWrapper,
    StyledDrugsTitle
} from '../views/DrugsManagement.styled';
import { DATE_FORMAT } from "commons/constants/env";
import { useAppSelector } from "commons/store/hooks";
import { useSearchParams } from "react-router-dom";
import { selectDrugTypes } from "../../drugs/redux/drugsSlice";
import { selectMedicalCenters } from "../../medicalCenters/redux/medicalCentersSlice";
import { format, isValid, parse } from "date-fns";
import { isEmpty } from "lodash";

// export interface FilterParams {
//     type?: string;
//     serial?: string;
//     number?: string;
//     medicalCenter?: string;
//     ecrf?: string;
//     status?: string;
//     expiredAt?: string;
// }

export interface FilterProps {
    displaySerial?: boolean;
    displayType?: boolean;
}

export const Filter = (props: FilterProps) => {
    const { displaySerial = true, displayType = true } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const [filters, setFilters] = useState<any>(Object.fromEntries(searchParams));
    const [show, setShow] = useState<boolean>(searchParams?.size > 0);
    const [loading, setLoading] = useState<boolean>(false);
    const drugTypes = useAppSelector(selectDrugTypes);
    const medicalCenters = useAppSelector(selectMedicalCenters);

    const handleChange = async (value: any, type: any) => {
        if (isValid(value)) {
            filters[type] = format(value, DATE_FORMAT);
        } else {
            filters[type] = value;
        }

        setFilters((previousState: any) => ({
            ...previousState,
            // [type]: value,
            ...filters,
        }));
    };

    const filter = async () => {
        setLoading(true);

        for (const [key, value] of Object.entries(filters)) {
            if (value === null || value === '') {
                searchParams.delete(key);
            } else {
                searchParams.set(key, String(value));
            }
        }

        setSearchParams(searchParams);

        setLoading(false);
    }

    const clearFilters = async () => {
        setFilters({
            type: null,
            serial: '',
            number: '',
            medicalCenter: null,
            status: null,
            "expiredAt[after]": null,
            "expiredAt[before]": null,
        });
        setSearchParams({});
    }

    return (
        <>
            <StyledDrugsFiltersWrapper>
                <Box style={{marginTop: '-40px'}}>
                    <StyledDrugsFiltersItems>
                        <StyledDrugsTitle>Moduł zarządzania lekiem</StyledDrugsTitle>
                        <StyledDrugsButton
                            onClick={() => setShow(!show)}>
                            {show ? 'Ukryj filtry' : 'Pokaż filtry'}
                        </StyledDrugsButton>
                        {(show && !isEmpty(filters)) &&
                            <StyledDrugsFiltersItems>
                                <StyledDrugsButton onClick={(() => filter())}>
                                    Filtruj
                                </StyledDrugsButton>
                                <StyledDrugsButton onClick={(() => clearFilters())}>
                                    Wyczyść
                                </StyledDrugsButton>
                            </StyledDrugsFiltersItems>
                        }
                    </StyledDrugsFiltersItems>
                </Box>
            </StyledDrugsFiltersWrapper>
            <Collapse in={show} unmountOnExit>
                <>
                    <StyledDrugsFiltersWrapper>
                        {(displaySerial && <StyledDrugsFiltersItemsFilters>
                            <Tooltip title={'Wprowadź serię'} arrow>
                                <Box>
                                    <TextField
                                        label={'Seria'}
                                        value={filters?.serial}
                                        size="small"
                                        variant={'outlined'}
                                        onChange={(event) =>
                                            handleChange(event.target.value, 'serial')
                                        }
                                    />
                                </Box>
                            </Tooltip>
                        </StyledDrugsFiltersItemsFilters>)}
                        <StyledDrugsFiltersItemsFilters>
                            <Tooltip title={'Wprowadź numer'} arrow>
                                <Box>
                                    <TextField
                                        label={'Numer'}
                                        value={filters?.number}
                                        size="small"
                                        variant={'outlined'}
                                        onChange={(event) =>
                                            handleChange(event.target.value, 'number')
                                        }
                                    />
                                </Box>
                            </Tooltip>
                        </StyledDrugsFiltersItemsFilters>
                        <StyledDrugsFiltersItemsFilters>
                            <DatePicker
                                label={'Data ważności od'}
                                value={
                                    isValid(parse(filters?.["expiredAt[after]"], DATE_FORMAT, new Date()))
                                        ? parse(filters?.["expiredAt[after]"], DATE_FORMAT, new Date())
                                        : null
                                }
                                format={DATE_FORMAT}
                                onChange={(newValue) => handleChange(newValue, 'expiredAt[after]')}
                                slotProps={{
                                    actionBar: {
                                        actions: ['today', 'clear']
                                    },
                                    textField: {
                                        size: 'small',
                                        variant: 'outlined'
                                    }
                                }}
                            />
                        </StyledDrugsFiltersItemsFilters>
                        <StyledDrugsFiltersItemsFilters>
                            <DatePicker
                                label={'Data ważności do'}
                                value={
                                    isValid(parse(filters?.["expiredAt[before]"], DATE_FORMAT, new Date()))
                                        ? parse(filters?.["expiredAt[before]"], DATE_FORMAT, new Date())
                                        : null
                                }
                                format={DATE_FORMAT}
                                onChange={(newValue) => handleChange(newValue, 'expiredAt[before]')}
                                slotProps={{
                                    actionBar: {
                                        actions: ['today', 'clear']
                                    },
                                    textField: {
                                        size: 'small',
                                        variant: 'outlined'
                                    }
                                }}
                            />
                        </StyledDrugsFiltersItemsFilters>
                    </StyledDrugsFiltersWrapper>

                    <StyledDrugsFiltersWrapper>
                        <StyledDrugsFiltersItemsFilters>
                            {medicalCenters?.data?.map((center: any) =>
                                <Tooltip title={center?.name} arrow key={center.id}>
                                    <StyledDrugsButtonFilter
                                        selectedFilter={filters?.medicalCenter}
                                        id={center.id}
                                        onClick={() => handleChange(center?.id, 'medicalCenter')}>
                                        {center?.number}
                                    </StyledDrugsButtonFilter>
                                </Tooltip>
                            )}
                        </StyledDrugsFiltersItemsFilters>
                    </StyledDrugsFiltersWrapper>

                    <StyledDrugsFiltersWrapper>
                        <StyledDrugsFiltersItemsFilters>
                            {statusDictionary.map((status: any, index: any) =>
                                <Tooltip title={statusDescriptions[index]} arrow key={String(index)}>
                                    <StyledDrugsButtonFilter
                                        selectedFilter={filters?.status}
                                        id={String(index)}
                                        onClick={() => handleChange(String(index), 'status')}>
                                        {status}
                                    </StyledDrugsButtonFilter>
                                </Tooltip>
                            )}
                        </StyledDrugsFiltersItemsFilters>
                    </StyledDrugsFiltersWrapper>

                    {displayType && (
                        <StyledDrugsFiltersWrapper>
                            <StyledDrugsFiltersItemsFilters>
                                {drugTypes?.data?.map((drug: any) =>
                                    <Tooltip title={`Pokaż tylko ${drug?.name}`} arrow key={drug.id}>
                                        <StyledDrugsButtonFilter
                                            selectedFilter={filters?.type}
                                            id={drug.id}
                                            onClick={() => handleChange(drug?.id, 'type')}>
                                            {drug?.name}
                                        </StyledDrugsButtonFilter>
                                    </Tooltip>
                                )}
                            </StyledDrugsFiltersItemsFilters>
                        </StyledDrugsFiltersWrapper>
                    )}
                </>
            </Collapse>
        </>
    );
};
