import {CircularProgress, Tooltip} from '@mui/material';
import {useAppSelector} from 'commons/store/hooks';
import {selectCurrentSection, selectVisits} from 'features/ecrf/redux/ecrfSlice';
import React from 'react';
import {StyledEcrfSaveAll} from '../Ecrf.styled';
import { DISPLAY_MULTISAVE, RESEARCH } from "commons/constants/env";
import Button from "@mui/material/Button";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import SaveIcon from "@mui/icons-material/Save";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";

interface Props {
    isAllSaving: boolean;
    setIsAllSaving: (isAllSaving: boolean) => void;
    updateSection: (sectionId: string, visits: any | null) => void;
}

export const NavWidget: React.FC<Props> = ({
   isAllSaving,
   setIsAllSaving,
   updateSection
}) => {
    /** Visits */
    const visits = useAppSelector(selectVisits);

    /** This is current selected section data */
    const currentSection = useAppSelector(selectCurrentSection);

    const displayMultiSave = (): boolean => (DISPLAY_MULTISAVE !== 'FALSE');
    const disableNextPrevButton = (): boolean => RESEARCH === 'IMPROVE';

    return (
        <StyledEcrfSaveAll>
            <Tooltip placement={'left'} title={'Przejdź do kolejnej sekcji'} arrow>
                <span>
                    <Button
                        size={'small'}
                        disabled={currentSection?.next === false || disableNextPrevButton()}
                        color={'primary'}
                        variant={'outlined'}
                        onClick={() => updateSection(currentSection?.next, visits)}
                    >
                        <NavigateNextRoundedIcon />
                    </Button>
                </span>
            </Tooltip>

            {displayMultiSave() && (
                <Tooltip
                    placement={'left'}
                    title={
                        'Zapisz wszystkie wypełnione pola. Po zapisie upewnij się czy nie wywołałeś dodatkowych zależności.'
                    }
                    arrow>
                    <span>
                        <Button
                            disabled={isAllSaving}
                            color={'primary'}
                            type={'submit'}
                            variant={'outlined'}>
                            {isAllSaving ? (
                                <CircularProgress color={'inherit'} size={24} />
                            ) : (
                                <SaveIcon />
                            )}
                        </Button>
                    </span>
                </Tooltip>
            )}

            <Tooltip placement={'left'} title={'Przejdź do poprzedniej sekcji'} arrow>
                <span>
                    <Button
                        size={'small'}
                        disabled={currentSection?.prev === false || disableNextPrevButton()}
                        color={'primary'}
                        variant={'outlined'}
                        onClick={() => updateSection(currentSection?.prev, visits)}
                    >
                        <NavigateBeforeRoundedIcon />
                    </Button>
                </span>
            </Tooltip>
        </StyledEcrfSaveAll>
    );
};
