import {Modal, Box, Button} from "@mui/material";
import React from "react";
import Table from "components/Table";
import {useAppSelector} from "commons/store/hooks";
import {selectElementHistory} from "features/ecrf/redux/elementSlice";
import DateTime from "core/helpers/date/DateTime";
import CloseIcon from "@mui/icons-material/Close";
import {DATETIME_FORMAT} from "../../commons/constants/env";
import {isArray} from "lodash";

interface ModalProps {
    openModal: boolean;
    closeModal: () => void;
}

/** TODO: MAP ELEMENTS FROM oldValue and newValue ARRAYS */

export const ElementHistoryModal: React.FC<ModalProps> = (props) => {
    const elementHistory = useAppSelector(selectElementHistory);

    return (
        <>
            <Modal
                open={props.openModal}
                onClose={props.closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{overflow: "scroll"}}
            >
                <Box m={3} ml="20vw" mr="20vw" p={3} bgcolor="white" borderRadius="10px">
                    <Box textAlign="end">
                        <Button
                            startIcon={<CloseIcon/>}
                            color="primary"
                            onClick={props.closeModal}
                            variant="outlined"
                        >
                            CLOSE
                        </Button>
                    </Box>
                    <Table
                        data={elementHistory}
                        tableName="Element changes history"
                        columns={[
                            {
                                title: <Box ml="20px">Date</Box>,
                                render: (elementHistory: any) => (
                                    <Box alignItems="left">
                                        {DateTime.toIso(elementHistory?.createdAt, DATETIME_FORMAT)}
                                    </Box>
                                ),
                                key: 'time',
                            },
                            {
                                title: <Box ml="20px">Field</Box>,
                                render: (elementHistory: any) => (
                                    <Box alignItems="left">
                                        {elementHistory?.field}
                                    </Box>
                                ),
                                key: 'field',
                            },
                            {
                                title: <Box ml="20px">Old value</Box>,
                                render: (elementHistory: any) => (
                                    <Box alignItems="left">
                                        {elementHistory?.oldValue.map((value: any) => {
                                            const val = (value?.value) ? value?.value : value;
                                            return (
                                                <Box>
                                                    {DateTime.isDateTime(val) ? DateTime.toIso(val, DATETIME_FORMAT) : val}
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                ),
                                key: 'oldValue',
                            },
                            {
                                title: <Box ml="20px">New value</Box>,
                                render: (elementHistory: any) => (
                                    <Box alignItems="left">
                                        {elementHistory?.newValue.map((value: any) => {
                                            const val = (value?.value) ? value?.value : value;
                                            return (
                                                <Box>
                                                    {DateTime.isDateTime(val) ? DateTime.toIso(val, DATETIME_FORMAT) : val}
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                ),
                                key: 'newValue',
                            },
                            {
                                title: <Box ml="20px">User</Box>,
                                render: (elementHistory: any) => (
                                    <Box ml="20px">
                                        {elementHistory?.owner?.firstname}<br/>
                                        {elementHistory?.owner?.lastname}
                                    </Box>
                                ),
                                key: 'userSecondName',
                            },
                        ]}
                    />
                </Box>
            </Modal>
        </>
    );
};
