import {StyledDrawer, StyledDrawerContentWrapper} from "./QueriesPanel.styled";
import Box from "@mui/material/Box";
import ColoredIconWrapper from "components/ColoredIconWrapper";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Typography from "@mui/material/Typography";
import {Form, Formik, FormikHelpers} from "formik";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import Button from "@mui/material/Button";
import {useCallback, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "commons/store/hooks";
import SendIcon from "@mui/icons-material/Send";
import {IconButton} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {getRoleByRoleId} from "commons/constants/ditionaries";
import DateTime from "core/helpers/date/DateTime";
import TextAreaFormik from "components/Form/TextAreaFormik";
import {useSnackbar} from "notistack";
import {DATETIME_FORMAT} from "commons/constants/env";
import {
    fetchFileQueries,
    selectFileQueries,
    resetFileQueries,
    putFile
} from "../redux/fileSlice";
import {addQuery} from "../../ecrf/redux/ecrfSlice";

export interface QueriesPanelProps {
    isOpen: boolean;
    onClose: () => void;
    file?: any | undefined;
    queryId?: string | null;
}

const QueriesPanel = ({
  isOpen,
  onClose,
  file,
  queryId,
}: QueriesPanelProps) => {
    const dispatch = useAppDispatch();
    const queries  = useAppSelector(selectFileQueries);

    const [isLoading, setIsLoading] = useState(true);
    const [isSending, setIsSending] = useState(false);
    const {enqueueSnackbar}         = useSnackbar();

    const formInitialValues = {content: ''};

    const fetch = useCallback(async () => {
        await dispatch(fetchFileQueries(file?.id)).unwrap();
        setIsLoading(false);
    }, [dispatch, file]);

    useEffect(() => {
        if (!queryId) {
            dispatch(resetFileQueries())
        }

        if (file?.id) {
            setIsLoading(true);
            fetch();
        }
    }, [dispatch, file, fetch]);

    /**
     * This function is used to add new query or reply to an existing query
     * @param values
     * @param actions
     */
    const handleAddQuery = async (values: typeof formInitialValues, actions: FormikHelpers<typeof formInitialValues>) => {
        if (!values.content) {
            return null;
        }

        setIsSending(true);

        try {
            /* @todo addQuery from query slice */
            if (queries.length > 0 && queries[0]?.id) {
                await dispatch(
                    addQuery({content: values.content, parent: `/api/comments/${queries[0]?.id}`})
                ).unwrap();
            } else {
                await dispatch(putFile({id: file?.id, comments: [{content: values.content}]})).unwrap();
            }

            actions.resetForm({});
            await fetch();

            enqueueSnackbar('Dodano query',{variant: 'success',});
        } catch (err: any) {
            enqueueSnackbar(err?.message || err?.detail, {variant: 'error'});
        }

        setIsSending(false)
    };

    return (
        <StyledDrawer anchor="right" open={isOpen} onClose={onClose} variant="persistent">
            <StyledDrawerContentWrapper>
                <Box pt={1} pb={2}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                        <Typography variant="subtitle2" color="primary" onClick={() => onClose()}>
                            <Button endIcon={<ChevronRightIcon />}>Zwiń panel</Button>
                        </Typography>
                    </Box>

                    <Box p={2} pt={0}>
                        <Box mb={2}>
                            <ColoredIconWrapper color="primary"><QuestionAnswerIcon /></ColoredIconWrapper>
                        </Box>
                        <Typography variant="button">Panel zapytań (Queries) i odpowiedzi</Typography>
                        <Typography variant="body2" color="textSecondary">
                            W tym miejscu możesz zadawać pytania i udzielać odpowiedzi dotyczące danych
                        </Typography>
                    </Box>

                    <Box p={2} borderTop={1} borderColor="rgba(0, 0, 0, 0.12)">
                        <Typography variant="subtitle2">
                            <strong>Plik:</strong>
                        </Typography>
                        <Typography variant="subtitle2">{file?.name}</Typography>
                    </Box>

                    {isLoading && (
                        <Box textAlign="center">
                            <CircularProgress />
                        </Box>
                    )}

                    {!isLoading && (
                        <>
                            {queries.length > 0 && (
                                <Box p={2} borderTop={1} borderColor="rgba(0, 0, 0, 0.12)" key={queries[0].id}>
                                    {queries[0]?.owner?.id && (
                                        <>
                                            <Box display="flex" mb={1} alignItems="center">
                                                <Box mr={1} mt={2} mb={1} display="flex" style={{gap: "60px"}}>
                                                    <Typography variant="subtitle2">
                                                        {queries[0]?.owner?.id ? queries[0]?.owner?.firstname : ''}{' '}
                                                        {queries[0]?.owner?.id ? queries[0]?.owner?.lastname : ''}
                                                        <br/><small>({queries[0]?.owner?.id ? getRoleByRoleId(queries[0]?.owner?.roles[0]) : '-'})</small>
                                                    </Typography>
                                                    <Typography variant="subtitle2" color="textSecondary">
                                                        {queries[0].createdAt && DateTime.toIso(queries[0].createdAt, DATETIME_FORMAT)}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Typography variant={"body2"}>{queries[0]?.content}</Typography>
                                        </>
                                    )}
                                    {queries[0]?.children?.map((i: any) => (
                                        <Box>
                                            <Box mr={1} mt={2} key={i.id} mb={1} display="flex" style={{gap: "10px"}}>
                                                <Typography variant="subtitle2">
                                                    {i?.owner?.id ? i?.owner?.firstname : ''}{' '}
                                                    {i?.owner?.id ? i?.owner?.lastname : ''}
                                                    <br/><small>({i?.owner?.id ? getRoleByRoleId(i?.owner?.roles[0]) : '-'})</small>
                                                </Typography>

                                                <Typography variant="subtitle2" color="textSecondary">
                                                    {i.createdAt && DateTime.toIso(i.createdAt, DATETIME_FORMAT)}
                                                </Typography>
                                            </Box>
                                            <Typography variant={"body2"}>{i?.content}</Typography>
                                        </Box>
                                    ))}
                                </Box>
                            )}

                            <Box p={2}>
                                <Formik initialValues={formInitialValues} onSubmit={handleAddQuery}>
                                    <Form>
                                        <Box display="flex" alignItems="center">
                                            <TextAreaFormik
                                                name="content"
                                                placeholder="Dodaj odpowiedź..."
                                                fullWidth
                                                variant="outlined"
                                                rows={5}
                                                disabled={isSending}
                                            />
                                            <Box ml={1}>
                                                <IconButton type="submit">
                                                    {isSending ? <CircularProgress size={24} /> : <SendIcon />}
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Form>
                                </Formik>
                            </Box>
                        </>
                    )}
                </Box>
            </StyledDrawerContentWrapper>
        </StyledDrawer>
    );
};

export default QueriesPanel;
