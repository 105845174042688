/*
 * Project: ecrf-front
 * Author: Dominik Obłoza
 * User: @dominik.obloza
 * Date: 07.03.2022
 * Time: 13:38
*/

import React, {Component} from 'react';
import {
    StyledUploaderFilesItem,
    StyledUploaderFilesItemSize,
    StyledUploaderFilesItemText
} from "./S3UploaderFormik.styled";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import {CircularProgress, Tooltip} from "@mui/material";
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import ConfirmationAction from 'components/ConfirmationAction/ConfirmationAction';
import GetAppIcon from '@mui/icons-material/GetApp';
import {API_URL} from 'commons/constants/env';
import {connect} from "react-redux";
import axiosSecureInstance from "../../../commons/axios/axiosSecureInstance";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { roles } from 'commons/auth/roles';

interface IFileItem {
    name: string,
    size: string,
    link?: string,
    id: string,
    userProfile?: any;
    isUploading?: boolean,
    removeRow?: (id: string) => void;
    update?: (id: string) => void;
    dataId?: string;
}

class FileItem extends Component<IFileItem, any> {

    state = {
        isDataDeleting: false,
        isResearcher: this.props.userProfile.roles.some(
            (role: string) => roles.admin.includes(role) || roles.researcher.includes(role)
        ),
    }

    /**
     * This method is used to convert file size to human units
     * @params bytes
     */
    public static formatBytes(bytes: any, decimals = 2) {

        if (bytes === null) return 'unknown'

        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    /**
     * This method is used to download file from list
     * @param id
     * @private
     */
    private static downloadFile(id: string) {
        window.open(`${API_URL}/api/element_resource_transfers/${id}/download`)
    }

    /**
     * This method deleting the file from list
     * @param id
     * @private
     */
    private async deleteFile(id: string) {
        this.setState({isDataDeleting: true});

        if (this.state.isResearcher) {
            await axiosSecureInstance.delete(`/api/element_resource_transfers/${id}`)
            if (this.props.update) this.props.update(this.props.dataId || '-')
        }

        this.setState({isDataDeleting: false});
    }

    render() {
        return (
            <StyledUploaderFilesItem isAdmin={this.props.userProfile.role === 'ROLE_ADMIN'}>
                <InsertDriveFileOutlinedIcon/>

                <Tooltip title={this.props.name} arrow>
                    <StyledUploaderFilesItemText>
                        {this.props?.name || 'name'}
                    </StyledUploaderFilesItemText>
                </Tooltip>

                <StyledUploaderFilesItemSize>
                    {FileItem.formatBytes(this.props?.size) || 'size'}
                </StyledUploaderFilesItemSize>

                {/* <--- Remove file from selected list ---> */}
                {!this.props.isUploading && !this.props.link && this.props.size && (
                    <Tooltip title={'Remove from list'} arrow>
                        <StyledUploaderFilesItemSize onClick={() => {
                            if (this.props.removeRow) {
                                this.props.removeRow(this.props.name)
                            }
                        }}
                            style={{cursor: 'pointer'}}>
                            <ClearRoundedIcon style={{fontSize: '15px'}}/>
                        </StyledUploaderFilesItemSize>
                    </Tooltip>
                )}

                {/* <--- Download file from transfers list ---> */}
                {this.props.link && (
                    <Tooltip title={'Download file'} arrow>
                        <StyledUploaderFilesItemSize onClick={() => FileItem.downloadFile(this.props.id)}
                            style={{cursor: 'pointer'}}>
                            <GetAppIcon style={{fontSize: '15px'}}/>
                        </StyledUploaderFilesItemSize>
                    </Tooltip>
                )}

                {this.props.isUploading && (
                    <CircularProgress size={22}/>
                )}

                {this.props.link && this.state.isResearcher && (
                    <ConfirmationAction
                        confirmationTitle={'Are you sure you want to delete this file?'}
                        onClick={() => this.deleteFile(this.props.id)}
                        isProcessing={this.state.isDataDeleting}
                    >
                        {({handleClick}) => (
                            <Tooltip title={'Delete file'} arrow>
                                <StyledUploaderFilesItemSize
                                    onClick={handleClick}
                                    style={{cursor: 'pointer'}}
                                    disabled={this.state.isDataDeleting}
                                >
                                    <DeleteRoundedIcon style={{fontSize: '15px'}}/>
                                </StyledUploaderFilesItemSize>
                            </Tooltip>
                        )}
                    </ConfirmationAction>
                )}
            </StyledUploaderFilesItem>
        );
    }
}

/**
 * This function is used to get user profile data from Redux
 * @param state
 */
const mapStateToProps = (state: any) => ({
    userProfile: state.core.user.userProfile
})

export default connect(mapStateToProps, null)(FileItem)