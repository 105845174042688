import React, { useEffect, useState } from 'react';
import {
    AutocompleteFormik,
    CheckboxFormik,
    CheckboxMultipleFormik,
    DatePickerFormik,
    DateTimePickerFormik,
    PharmIndexFormik,
    RadioFormik, SelectFormik,
    TextFieldFormik,
    TimePickerFormik
} from "../index";
import { flatten, map } from "lodash";
import { useAppDispatch } from "../../../commons/store/hooks";
import { putCollectElementData, putDataToElement } from "../../../features/ecrf/redux/ecrfSlice";
import { useField } from "formik";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { DATE_FORMAT } from "commons/constants/env";

const TableGridFormField: React.FC<any> = (props) => {
    const dispatch = useAppDispatch()
    const [field] = useField(props.name);
    const [timer, setTimer] = useState<any>(null);
    const {enqueueSnackbar} = useSnackbar();
    const {id}              = useParams<{ id: string }>();

    /**
     * This function is used to auto-save changed value
     */
    const updateForm = async () => {
        clearTimeout(timer);
        setTimer(
            setTimeout(async () => {
                await save();
                enqueueSnackbar(`Saved ${props.label} ${new Date().toLocaleTimeString()}`, {
                    variant: 'info',
                    autoHideDuration: 2000,
                });
            }, 2000)
        );
    };

    const save = async () => {
        if (props?.dataId) {
            await dispatch(putDataToElement({
                data: flatten([field.value]),
                elementDataId: props.dataId,
            }))
        } else {
            await dispatch(putCollectElementData({
                id: props.rowId,
                elements: [{
                    ecrf: '/api/ecrves/' + id,
                    element: '/api/elements/' + props.name,
                    data: flatten([field.value])
                }]
            }));
        }

        props.reloadData()
    }

    useEffect(() => {
        if (props.options?.saveRow !== true) {
            const isArray = Array.isArray(field.value)
            if ((!isArray && (props.data && props?.data[0]) !== [field.value][0] && field.value)
                || (isArray && props.data.join() !== field.value.join())) {
                updateForm().then()
            }
        }
    }, [field.value])

    const renderInput = () => {
        switch (props.type) {
            case 'autocomplete':
                return <AutocompleteFormik
                    name={props.name || ''}
                    label={props.title || props.label}
                    options={props.options}
                    status={props.status}
                    disabled={props.disabled || props.status}
                    fullWidth
                />
            case 'number':
            case 'text': {
                return (
                    <TextFieldFormik
                        fullWidth
                        type={props.type}
                        name={props.name}
                        data={props?.data}
                        label={props.title || props.label}
                        status={props.status}
                        disabled={props.disabled || props.status}
                    />
                );
            }
            case 'choice':
            case 'select': {
                return (
                    <>
                      {props.options?.pharmindex === "true"
                        ?
                        <PharmIndexFormik
                            name={props.name}
                            status={props.status}
                            label={props.title || props.label}
                            value={field.value}
                        />
                        :
                        <SelectFormik
                            name={props.name}
                            fullWidth
                            status={props.status}
                            label={props.title || props.label}
                            placeholder={props.options?.placeholder}
                            helperText={props.options?.help}
                            options={props.options.choices}
                            disabled={props.disabled || props.status}
                        />
                      }
                    </>
                );
            }
            case 'date': {
                return (
                    <DatePickerFormik
                        name={props.name}
                        label={props.title || props.label}
                        status={props.status}
                        fullWidth
                        placeholder={props.options?.placeholder}
                        format={props.options.format || DATE_FORMAT}
                    />
                );
            }
            case 'time': {
                return (
                    <TimePickerFormik
                        status={props.status}
                        name={props.name}
                        fullWidth
                        label={props.label}
                        placeholder={props.options?.placeholder}
                        format={props.options?.format || undefined}
                    />
                );
            }
            case 'datetime': {
                return (
                    <DateTimePickerFormik
                        status={props.status}
                        name={props.name}
                        fullWidth
                        label={props.label}
                        placeholder={props.options?.placeholder}
                        // format={props.options?.format || undefined}
                    />
                );
            }
            case 'radio': {
                return map(props.options?.choices, (value, key) => {
                    return (
                        <RadioFormik
                            status={props.status}
                            key={value.toString()}
                            name={props.name}
                            label={key}
                            value={value.toString()}
                        />
                    );
                });
            }
            case 'checkbox': {
                return <CheckboxFormik
                    status={props.status}
                    name={props.name}
                    label={props.label}
                />;
            }
            case 'checkbox_multiple': {
                return (
                    <CheckboxMultipleFormik
                        id={props.name || ''}
                        options={props.options}
                        status={props.status}
                    />
                )
            }
        }
    }

    return (<>{renderInput()}</>);
}

export default TableGridFormField;
