import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {ROUTES_MEDICAL_CENTERS} from "commons/constants/paths";
import {useAppDispatch, useAppSelector} from "commons/store/hooks";
import DashboardLayoutWrapper from "commons/wrappers/DashboardLayoutWrapper";
import ColoredBox from "components/ColoredBox";
import ConfirmationAction from "components/ConfirmationAction";
import Table from "components/Table";
import {
    deleteMedicalCenter,
    fetchMedicalCenters,
    selectMedicalCenters,
} from "features/medicalCenters/redux/medicalCentersSlice";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

const MedicalCentersList = () => {
    const [page, setPage] = useState(1);
    const dispatch = useAppDispatch();
    const medicalCenters = useAppSelector(selectMedicalCenters);

    useEffect(() => {
        dispatch(fetchMedicalCenters({page}));
    }, [dispatch, page]);

    const handleDeleteMedicalCenter = async (id: string) => {
        await dispatch(deleteMedicalCenter(id));
        dispatch(fetchMedicalCenters({page}));
    };

    return (
        <DashboardLayoutWrapper>
            <Box display="flex" justifyContent="flex-end" mb={2}>
                <Button
                    component={Link}
                    to={ROUTES_MEDICAL_CENTERS.ADD}
                    variant="contained"
                    color="primary"
                >
                    Dodaj ośrodek badawczy
                </Button>
            </Box>
            <ColoredBox p={1} maxWidth="100%" ml="auto" mr="auto">
                <Table
                    pagination={{
                        pageSize: medicalCenters.itemsPerPage,
                        current: page,
                        total: medicalCenters.totalItems,
                    }}
                    onChangePage={(page) => setPage(page)}
                    data={medicalCenters.data}
                    tableName="Ośrodki badawcze"
                    columns={[
                        {
                            title: "Nazwa",
                            render: (row) => row.name,
                            key: "name",
                        },
                        {
                            title: "Numer",
                            render: (row) => row.number,
                            key: "number",
                        },
                        {
                            title: "Adress",
                            render: ({address}) =>
                                `${(address?.city as any)._embedded?.country?.name}, ${
                                    address?.city?.name
                                }, ${address?.postcode} ${address?.street} ${address?.number}`,
                            key: "address",
                        },
                        {
                            title: "Actions",
                            noWrap: true,
                            render: (row) => (
                                <div>
                                    <Box mr={1} display="inline-flex">
                                        <Button
                                            component={Link}
                                            to={ROUTES_MEDICAL_CENTERS.EDIT(row.id || "")}
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                        >
                                            Edytuj
                                        </Button>
                                    </Box>
                                    <ConfirmationAction
                                        onClick={() => handleDeleteMedicalCenter(row.id || "")}
                                    >
                                        {({handleClick}) => (
                                            <Button
                                                type="button"
                                                onClick={handleClick}
                                                variant="contained"
                                                size="small"
                                                color="primary"
                                            >
                                                Usuń
                                            </Button>
                                        )}
                                    </ConfirmationAction>
                                </div>
                            ),
                            key: "actions",
                        },
                    ]}
                />
            </ColoredBox>
        </DashboardLayoutWrapper>
    );
};

export default MedicalCentersList;
