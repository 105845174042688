import {
    StyledDrawer,
    StyledDrawerContentWrapper,
    StyledHideNotifyMenuBtn,
    StyledNotificationBox,
    StyledNoteContent,
} from "./DashboardNotifyBar.styled";
import Box from "@mui/material/Box";
import ColoredIconWrapper from "components/ColoredIconWrapper";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Avatar from "@mui/material/Avatar";
import avatarPNG from "commons/assets/avatar.png";
import {Notification} from "types/api.types";
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ConfirmationAction from "components/ConfirmationAction";
import {useAppDispatch} from "commons/store/hooks";
import {fetchElementDatas, getSectionId} from "features/ecrf/redux/ecrfSlice";
import {ROUTES_ECRF} from "commons/constants/paths";
import {useNavigate} from "react-router";
import {useState} from "react";
import {useSnackbar} from "notistack";
import DateTime from "../../../../../core/helpers/date/DateTime";
import {DATETIME_FORMAT} from "../../../../constants/env";

export interface DashboardNotifyBarProps {
    isOpen: boolean;
    onClose: () => void;
    notifications?: Notification[];
}

const DashboardNotifyBar = ({isOpen, onClose, notifications}: DashboardNotifyBarProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [redirectDetails, setRedirectDetails] = useState({type: '', ecrf: '', section: '', element: ''});
    const {enqueueSnackbar} = useSnackbar();

    const redirect = async () => {
        const destination = {section: redirectDetails.section, element: redirectDetails.element};
        await dispatch(getSectionId(destination));
        navigate(ROUTES_ECRF.DETAILS(redirectDetails.ecrf));
    };

    const fetchData = async (context: any) => {
        try {
            if (context?.comment && context?.type) {

            } else if (context?.element_data) {
                // @todo old structure/flow
                const data = await dispatch(fetchElementDatas(context?.element_data)).unwrap();
                setRedirectDetails({
                    type: data?.comment,
                    ecrf: data?.ecrf?.id,
                    section: data?.element?.section?.id,
                    element: data?.element?.id,
                });
            }

            return true;
        } catch (error: any) {
            enqueueSnackbar('Brak danych', {variant: 'error'});
            return false;
        }
    };

    return (
        <StyledDrawer anchor="right" open={isOpen} onClose={onClose} variant="temporary">
            <StyledDrawerContentWrapper>
                <Box pt={1} pb={2}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                        <StyledHideNotifyMenuBtn variant="subtitle2" color="primary" onClick={onClose}>
                            Zwiń panel
                            <ColoredIconWrapper color="primary">
                                <ChevronRightIcon/>
                            </ColoredIconWrapper>
                        </StyledHideNotifyMenuBtn>
                    </Box>
                    <Box pt={1}>
                        {/* #TODO: below is notifications list. Need to add answers list too when its logic is ready  */}
                        {notifications?.map((item) => {
                            return (
                                <StyledNotificationBox key={item.id}>
                                    <Box mr={1}>
                                        <Avatar src={avatarPNG}/>
                                    </Box>
                                    <Box>
                                        <StyledNoteContent variant="subtitle2" $isNewNotify={!item?.seenAt}>
                                            {item.content}
                                        </StyledNoteContent>
                                        {item.createdAt && (
                                            <Typography variant="subtitle2" color="textSecondary">
                                                {new Date(item.createdAt).toLocaleString()}
                                            </Typography>
                                        )}
                                        {/*{(item.context?.comment || item.context?.element_data) && (*/}
                                        {item.context?.element_data && (
                                            <Box textAlign="end" width="100%">
                                                <ConfirmationAction
                                                    confirmationTitle={"Czy na pewno chcesz przejść do query?"}
                                                    onClick={() => redirect()}
                                                >
                                                    {({handleClick}) => (
                                                        <Button
                                                            endIcon={<ArrowRightAltIcon/>}
                                                            size="small"
                                                            variant="outlined"
                                                            color="secondary"
                                                            onClick={async () => {
                                                                if (await fetchData(item.context)) {
                                                                    handleClick();
                                                                }
                                                            }}
                                                        >
                                                            Przejdź
                                                        </Button>
                                                    )}
                                                </ConfirmationAction>
                                            </Box>
                                        )}
                                        {item?.seenAt && (
                                            <Typography style={{marginTop: '15px'}} variant="subtitle2"
                                                color="textSecondary">
                                                Odczytano: {DateTime.toIso(item?.seenAt, DATETIME_FORMAT)}
                                            </Typography>
                                        )}
                                    </Box>
                                </StyledNotificationBox>
                            );
                        })}
                    </Box>
                </Box>
            </StyledDrawerContentWrapper>
        </StyledDrawer>
    );
};

export default DashboardNotifyBar;
