import React, {Component} from 'react';
import {StyledFileStatus} from "../views/Documentation.styled";

class FileStatus extends Component<any, any> {
    state = {
        bg: '#fff',
        color: '#black',
        text: '-'
    }

    /**
     * This method is used to load variant and render it
     */
    private loadVariant() {
        switch (this.props.variant) {
            case 1: {
                this.setState({bg: '#FEF9C3', color: '#CA8A04', text: 'Approved'});
                break;
            }
            case 2: {
                this.setState({bg: '#DCFCE7', color: '#16A34A', text: 'Opened'});
                break;
            }
            case 3: {
                this.setState({bg: '#DCFCE7', color: '#16a389', text: 'Outdated'});
                break;
            }
            case 0:
            default: {
                this.setState({bg: '#bbbbba', color: '#333232', text: 'Draft'});
                break;
            }
        }
    }

    componentDidMount() {
        this.loadVariant()
    }

    componentDidUpdate(prevProps: any){
        if (prevProps !== this.props) {
            this.loadVariant()
        }
    }

    render() {
        return (
            <StyledFileStatus color={this.state.color} bg={this.state.bg}>
                {this.state.text}
            </StyledFileStatus>
        );
    }
}

export default FileStatus;