/*
 * Project: ecrf-front
 * Author: Dominik Obłoza
 * User: @dominik.obloza
 * Date: 08.03.2022
 * Time: 16:13
 */

import React, {useState} from 'react';
import { Box, Button, Collapse, TextField, Tooltip } from "@mui/material";
import { useAppDispatch } from "commons/store/hooks";
import {fetchEcrf, getSectionId} from "../../../redux/ecrfSlice";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import {API_URL, COLOR_BG_NAV} from "commons/constants/env";
import {Link} from "react-router-dom";
import {ROUTES_ECRF} from "../../../../../commons/constants/paths";
import CallMadeIcon from "@mui/icons-material/CallMade";

const AdminTools: React.FC<any> = (props) => {
    const dispatch = useAppDispatch();
    const [isEditFormVisible, setIsEditFormVisible] = useState(false)

    /* Input handlers */
    const [positionValue, setPositionValue] = useState<any>(props.element.position || undefined)
    const [titleValue, setTitleValue] = useState<any>(props.element.title || undefined)

    const handlePosition = (e: React.ChangeEvent<HTMLInputElement>) => setPositionValue(e.target.value)
    const handleTitle = (e: React.ChangeEvent<HTMLInputElement>) => setTitleValue(e.target.value)

    /**
     * This function is used to change element details: position, title
     */
    const updateElementData = async () => {
        const data = {
            position: parseInt(positionValue),
            title: titleValue,
        }

        await editElement(data)
        setIsEditFormVisible(false)
        await dispatch(fetchEcrf(props.id));
    }

    /**
     * This function is used to create request with new element details
     * @param data
     */
    const editElement = async (data: any) => {
        try {
            await axiosSecureInstance.put(`/api/elements/${props.element.id}`, data)
        } catch (error: any) {
            alert(error?.detail)
        }
    }

    return (
        <Box>
            <Box display={'flex'} mt={3} gap={'10px'} flexWrap={'wrap'}>
                <Box fontSize={'.7rem'} bgcolor={'#F3F4F6'} color={'#4B5563'} borderRadius={'522px'} p={'4px 21px'}>
                    Id: {props.element?.id}
                </Box>
                <Box fontSize={'.7rem'} bgcolor={'#F3F4F6'} color={'#4B5563'} borderRadius={'522px'} p={'4px 21px'}>
                    Position: {props.element?.position}
                </Box>

                <Box fontSize={'.7rem'} bgcolor={'#F3F4F6'} color={'#4B5563'} borderRadius={'522px'} p={'4px 21px'}>
                    Type: {props.element?.type}
                </Box>

                <Box fontSize={'.7rem'} bgcolor={'#F3F4F6'} color={'#4B5563'} borderRadius={'522px'} p={'4px 21px'}>
                    Data Id: {props?.data?.id}
                </Box>

                <Tooltip title={'Click to display element details in console'} arrow>
                    <Box style={{cursor: 'pointer'}} fontSize={'.7rem'} bgcolor={'#F3F4F6'} color={'#4B5563'}
                        borderRadius={'522px'} p={'4px 21px'} onClick={() => console.log(props.element)}>
                        Info
                    </Box>
                </Tooltip>

                <Tooltip title={'Click to display data in console'} arrow>
                    <Box style={{cursor: 'pointer'}} fontSize={'.7rem'} bgcolor={'#F3F4F6'} color={'#4B5563'}
                        borderRadius={'522px'} p={'4px 21px'} onClick={() => console.log(props.data)}>
                        Data
                    </Box>
                </Tooltip>

                <Box style={{cursor: 'pointer'}} fontSize={'.7rem'} bgcolor={'#F3E8FF'} color={'#9333EA'}
                    borderRadius={'522px'} p={'4px 21px'} onClick={() => setIsEditFormVisible(!isEditFormVisible)}>
                    {isEditFormVisible ? 'Hide form' : 'Show form'}
                </Box>

            </Box>

            {/* <--- Edit form ---> */}
            <Collapse in={isEditFormVisible} unmountOnExit mountOnEnter>
                <Box display={'flex'} mt={3} p={3} gap={'10px'} bgcolor={COLOR_BG_NAV} borderRadius={'10px'}>
                    <TextField
                        value={positionValue}
                        label={'Position'}
                        variant={"outlined"}
                        size={'small'}
                        onChange={handlePosition}
                    />
                    <TextField
                        value={titleValue}
                        label={'Title'}
                        variant={"outlined"}
                        size={'small'}
                        onChange={handleTitle}
                    />
                    <Button size={'small'} variant={'outlined'} onClick={() => updateElementData()}>
                        Save
                    </Button>
                </Box>
            </Collapse>
        </Box>
    );
};

export default AdminTools;