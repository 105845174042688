import {Modal, Box, Button} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import Table from "components/Table";
import {useAppDispatch, useAppSelector} from "commons/store/hooks";
import DateTime from "core/helpers/date/DateTime";
import CloseIcon from "@mui/icons-material/Close";
import {fetchFileHistory, resetFileHistory, selectFileHistory} from "../redux/fileSlice";
import {DATETIME_FORMAT} from "../../../commons/constants/env";
import {QueriesPanelProps} from "./QueriesPanel";

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    file?: any | undefined;
}

/** TODO: MAP ELEMENTS FROM oldValue and newValue ARRAYS */
export const HistoryModal: React.FC<ModalProps> = ({
   isOpen,
   onClose,
   file,
}: QueriesPanelProps) => {
    const dispatch = useAppDispatch();
    const history  = useAppSelector(selectFileHistory);

    const [isLoading, setIsLoading] = useState(true);

    const fetch = useCallback(async () => {
        setIsLoading(true);
        await dispatch(fetchFileHistory(file?.id)).unwrap();
        setIsLoading(false);
    }, [dispatch, file]);

    useEffect(() => {
        if (file?.id) {
            fetch();
        }

        /** clear state after unmount */
        return () => {
            dispatch(resetFileHistory());
        };
    }, [dispatch, file, fetch]);

    return (
        <>
            <Modal
                open={isOpen}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{overflow: "scroll"}}
            >
                <Box m={3} ml="20vw" mr="20vw" p={3} bgcolor="white" borderRadius="10px">
                    <Box textAlign="end">
                        <Button
                            startIcon={<CloseIcon/>}
                            color="primary"
                            onClick={onClose}
                            variant="outlined"
                        >
                            CLOSE
                        </Button>
                    </Box>
                    <Table
                        data={history}
                        tableName="File changes history"
                        columns={[
                            {
                                title: <Box ml="20px">Date</Box>,
                                render: (history: any) => (
                                    <Box alignItems="left">
                                        {DateTime.toIso(history?.createdAt, DATETIME_FORMAT)}
                                    </Box>
                                ),
                                key: 'date',
                            },
                            {
                                title: <Box ml="20px">Field</Box>,
                                render: (history: any) => (
                                    <Box alignItems="left">
                                        {history?.field}
                                    </Box>
                                ),
                                key: 'field',
                            },
                            {
                                title: <Box ml="20px">Old value</Box>,
                                render: (history: any) => (
                                    <Box alignItems="left">
                                        {history?.oldValue.map((value: any) => (
                                            <Box>
                                                {DateTime.isDateTime(value) ? DateTime.toIso(value, DATETIME_FORMAT) : value}
                                            </Box>
                                        ))}
                                    </Box>
                                ),
                                key: 'oldValue',
                            },
                            {
                                title: <Box ml="20px">New value</Box>,
                                render: (history: any) => (
                                    <Box alignItems="left">
                                        {history?.newValue.map((value: any) => (
                                            <Box>
                                                {DateTime.isDateTime(value) ? DateTime.toIso(value, DATETIME_FORMAT) : value}
                                            </Box>
                                        ))}
                                    </Box>
                                ),
                                key: 'newValue',
                            },
                            {
                                title: <Box ml="20px">User</Box>,
                                render: (history: any) => (
                                    <Box ml="20px">
                                        {history?.owner?.firstname}<br/>
                                        {history?.owner?.lastname}
                                    </Box>
                                ),
                                key: 'owner',
                            },
                        ]}
                        isLoading={isLoading}
                    />
                </Box>
            </Modal>
        </>
    );
};
