/*
 * Project: ecrf-front
 * Author: Dominik Obłoza
 * User: @dominik.obloza
 * Date: 14.01.2022
 * Time: 11:26
 */

import { Box, CircularProgress } from "@mui/material";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import { fetchStats, selectStats } from "features/dashboard/redux/dashboardSlice";
import { useEffect, useState } from "react";
import {
    StyledGlobalContainer,
    StyledGlobalStatsContainer,
    StyledHeaderContainer,
    StyledHeaderDate,
    StyledHeaderSubtitle,
    StyledHeaderTitle,
    StyledStatsDotPurple,
    StyledStatsTab,
    StyledStatsTitle,
    StyledStatsTitleText,
    StyledSText1,
    StyledSText2,
    StyledGlobalMedicalCentersContainer,
    StyledStatsDotYellow,
    StyledGlobalMedicalCentersFiltersContainer,
    StyledGlobalMedicalCentersFiltersButton,
    StyledGlobalMedicalCentersFiltersInput,
} from "./DashboardStats.styled";
import { MedicalCenterItem } from "./MedicalCenterItem";
import { FluffyItem, PurpleItem } from "./StatsTypes";
import { PROJECT_NAME } from "commons/constants/env";

const DashboardStats = () => {
    const dispatch = useAppDispatch();

    /** Loading data state status */
    const [isDataLoading, setIsDataLoading] = useState(false);

    /** This state is holding stats about research */
    const stats = useAppSelector(selectStats);

    /** Fetch data  */
    const fetchData = async () => {
        setIsDataLoading(true);
        await dispatch(fetchStats());
        setIsDataLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [dispatch]);

    return (
        <>
            {isDataLoading ? (
                <Box p={1} mt={5} maxWidth="100%" ml="auto" mr="auto" display="grid" style={{placeItems: "center"}}>
                    <CircularProgress/>
                </Box>
            ) : (
                <Box p={1} mt={5} maxWidth="100%" ml="auto" mr="auto" style={{letterSpacing: "0.3px"}}>
                    <StyledHeaderContainer>
                        <StyledHeaderTitle>{PROJECT_NAME}</StyledHeaderTitle>
                        <StyledHeaderSubtitle>Statystyki</StyledHeaderSubtitle>
                        <StyledHeaderDate>24.12.2021 - teraz</StyledHeaderDate>
                    </StyledHeaderContainer>

                    <StyledGlobalContainer>
                        <Box>
                            <StyledStatsTitle>
                                <StyledStatsDotPurple/>
                                <StyledStatsTitleText>{stats?.item[0]?.name}</StyledStatsTitleText>
                            </StyledStatsTitle>

                            <StyledGlobalStatsContainer>
                                {stats?.item[0]?.item?.map((element: FluffyItem) => (
                                    <StyledStatsTab key={element.name}>
                                        <StyledSText1>{element.count}</StyledSText1>
                                        <StyledSText2>{element.name}</StyledSText2>
                                    </StyledStatsTab>
                                ))}
                            </StyledGlobalStatsContainer>
                        </Box>

                        <Box>
                            <StyledStatsTitle>
                                <StyledStatsDotPurple/>
                                <StyledStatsTitleText>{stats?.item[1]?.name}</StyledStatsTitleText>
                            </StyledStatsTitle>

                            <StyledGlobalStatsContainer>
                                {stats?.item[1]?.item?.map((element: FluffyItem) => (
                                    <StyledStatsTab key={element.name}>
                                        <StyledSText1>{element.count}</StyledSText1>
                                        <StyledSText2>{element.name}</StyledSText2>
                                    </StyledStatsTab>
                                ))}
                            </StyledGlobalStatsContainer>
                        </Box>
                    </StyledGlobalContainer>

                    <StyledGlobalMedicalCentersContainer>
                        <StyledStatsTitle>
                            <StyledStatsDotYellow/>
                            <StyledStatsTitleText>{stats?.item[2]?.name}</StyledStatsTitleText>
                        </StyledStatsTitle>

                        <StyledGlobalMedicalCentersFiltersContainer>
                            <Box display="flex" alignItems="center" style={{gap: "20px"}}>
                                <StyledGlobalMedicalCentersFiltersInput type="date" defaultValue="2021-12-24"/>
                                -
                                <StyledGlobalMedicalCentersFiltersInput type="date" defaultValue="2021-12-24"/>
                            </Box>

                            <Box display="flex" style={{gap: "10px"}}>
                                <StyledGlobalMedicalCentersFiltersInput type="text" placeholder="Szukaj..."/>
                                <StyledGlobalMedicalCentersFiltersButton>
                                    EKSPORTUJ
                                </StyledGlobalMedicalCentersFiltersButton>
                                <StyledGlobalMedicalCentersFiltersButton>
                                    SORTUJ WEDŁUG
                                </StyledGlobalMedicalCentersFiltersButton>
                            </Box>
                        </StyledGlobalMedicalCentersFiltersContainer>

                        {stats?.item[2]?.item?.map((element: PurpleItem) => (
                            <MedicalCenterItem
                                key={element.name}
                                number={element.number}
                                name={element.name}
                                item={element.item}
                            />
                        ))}
                    </StyledGlobalMedicalCentersContainer>
                </Box>
            )}
        </>
    );
};

export default DashboardStats;
