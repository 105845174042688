import { Box, Button, Typography } from "@mui/material";
import { useAppDispatch } from "commons/store/hooks";
import TextFieldFormik from "components/Form/TextFieldFormik";
import { Form, Formik, FormikHelpers } from "formik";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import ColoredBox from "components/ColoredBox";
import { useTheme } from "@mui/material/styles";
import yup from "commons/yup";
import { Link } from "react-router-dom";
import { ROUTES_CORE } from "commons/constants/paths";
import { resetPassword } from "core/redux/userSlice";

const validationSchema = yup.object({
  code: yup.string().required(),
  plainPassword: yup
    .string()
    .min(8, "Hasło musi składać się z przynajmniej 8 znaków")
    .required(),
  plainPasswordConfirmation: yup
    .string()
    .required()
    .oneOf(
      [yup.ref('plainPassword'), ''],
      'Hasła nie zgadzają się. Wpisz takie samo hasło jak w polu Nowe hasło'
    ),
});

type ResetPasswordParams = {
  token: string;
};

const ResetPassword = () => {
  const { token } = useParams<ResetPasswordParams>();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const initialValues = {
    code: "",
    plainPassword: "",
    plainPasswordConfirmation: "",
  };

  const handleSubmit = async (
    values: typeof initialValues,
    formikHelpers: FormikHelpers<typeof initialValues>
  ) => {
    try {
      await dispatch(
        resetPassword({
          code: values.code,
          token: token || '',
          plainPassword: values.plainPassword,
        })
      );
      enqueueSnackbar(
        "Twoje hasło zostało zmienione. Przejdź do strony logowania klikając w przycisk Logowanie.",
        { variant: "success" }
      );
      formikHelpers.resetForm();
    } catch (error: any) {
      enqueueSnackbar(error?.detail, {
        variant: "error",
      });
    }
  };

  return (
    <Box
      minHeight="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      pl={2}
      pr={2}
    >
      <ColoredBox p={1} width={352} maxWidth="100%">
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <Form>
            <Typography variant="h5">Zmiana hasła</Typography>
            <Box mt={3.5}>
              <TextFieldFormik
                margin="dense"
                name="code"
                label="Kod sms"
                type="text"
                variant="filled"
                fullWidth
              />
            </Box>
            <Box mt={3.5}>
              <TextFieldFormik
                margin="dense"
                name="plainPassword"
                label="Nowe hasło"
                type="password"
                variant="filled"
                fullWidth
              />
            </Box>
            <Box mt={3.5}>
              <TextFieldFormik
                margin="dense"
                name="plainPasswordConfirmation"
                label="Potwierdź nowe hasło"
                type="password"
                variant="filled"
                fullWidth
              />
            </Box>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Box boxShadow={theme.shadows[4]}>
                <Button type="submit" color="primary" variant="contained">
                  Zmień hasło
                </Button>
              </Box>
            </Box>
            <Box mt={1.5} display="flex" justifyContent="flex-end">
              <Button
                color="primary"
                component={Link}
                to={ROUTES_CORE.LOGIN}
                sx={{ textTransform: 'unset' }}
              >
                Logowanie
              </Button>
            </Box>
          </Form>
        </Formik>
      </ColoredBox>
    </Box>
  );
};

export default ResetPassword;
