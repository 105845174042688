import styled from 'styled-components';
import { COLOR_BG_DARK, COLOR_PRIMARY, COLOR_PRIMARY_LIGHT, COLOR_SECONDARY } from "../../../../commons/constants/env";

/** ECRF LEFT PANEL CONTENT */

export const StyledEcrfLayout = styled.div`
  display: grid;
  grid-template-areas: 'panel visits' 'panel content';
  grid-template-columns: 300px 1fr;
  grid-template-rows: 150px 1fr;
  color: ${COLOR_SECONDARY};

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-template-areas: 'visits' 'content';
  }
`;

export const StyledEcrfPanel = styled.div<any>`
  position: fixed;
  grid-area: panel;
  height: max(100vh, 100%);
  width: 260px;
  background-color: ${COLOR_SECONDARY};
  color: ${COLOR_BG_DARK};
  padding: 20px;
  padding-top: 90px;
  z-index: 9;

  @media (max-width: 900px) {
    z-index: 100;
    margin-left: ${(props) => (props.show ? '0px' : '-300px')};
  }
`;

export const StyledEcrfTitle = styled.div`
  font-size: 12px;
  opacity: 50%;
`;

export const StyledEcrfTitleRand = styled.small`
  opacity: 50%;
`;

export const StyledEcrfTitleSmall = styled.small`
  font-size: 9px;
  opacity: 50%;
`;

export const StyledEcrfSubTitle = styled.div`
  font-size: 20px;
  margin-top: 3px;
`;

export const StyledEcrfSubTitleSmall = styled.div`
  font-size: 10px;
  margin-top: 3px;
`;

export const StyledEcrfTitleFull = styled.div`
  font-size: 12px;
  opacity: 50%;
  cursor: pointer;
  transition: .2s ease;
  display: flex;

  &:hover {
    opacity: 100%;
  }
`;

export const StyledEcrfSectionsTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
`;

export const StyledEcrfSectionsTitle = styled.div`
  font-size: 18px;
`;

type SectionProps = {
    section: any;
    selected: any;
};

export const StyledEcrfSectionsWrapper = styled.div`
  overflow-y: auto;
  height: calc(100vh - 520px);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 6px;
  }
  
  ::-webkit-scrollbar-track {
    background: rgba(0,0,0,0);
  }
  
  ::-webkit-scrollbar-thumb {
    background: ${COLOR_PRIMARY};
    border-radius: 3px; 
  }
`;

export const StyledEcrfSectionsItem = styled.div<SectionProps>`
  padding-top: 8px;
  padding-bottom: ${(props) => (props.section?.children.length > 0 ? '0px' : '8px')};
  padding-left: 20px;
  font-size: 14px;
  border-left: 3px solid ${COLOR_PRIMARY};
  cursor: pointer;
  opacity: ${(props) => (props.selected?.id === props.section?.id ? '100%' : '40%')};
  transition: 0.2s ease;
  display: flex;
  justify-content: space-between;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  text-decoration: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  &:hover {
    opacity: 100%;
  }
`;

export const StyledEcrfSectionsItemChild = styled.div<SectionProps>`
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 40px;
  font-size: 14px;
  border-left: 3px solid ${COLOR_PRIMARY};
  cursor: pointer;
  opacity: ${(props) => (props.selected?.id === props.section?.id ? '100%' : '40%')};
  display: flex;
  justify-content: space-between;
  position: relative;
  transition: 0.3s ease;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

  &:hover {
    opacity: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    height: 2px;
    border-radius: 10000px;
    background-color: ${COLOR_PRIMARY};
    transition: 0.3s ease;
    width: ${(props) => (props.selected?.id === props.section?.id ? '25px' : '10px')};
    opacity: ${(props) => (props.selected?.id === props.section?.id ? '100%' : '70%')};
  }
`;

/** ECRF VISITS LIST */

export const StyledEcrfVisits = styled.div`
  grid-area: visits;
  position: fixed;
  width: calc(100% - 360px);
  min-height: 90px;
  max-height: 140px;
  background-color: ${COLOR_BG_DARK};
  top: 56px;
  left: 300px;
  display: flex;
  overflow-x: auto;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 9;
  padding-bottom: 5px;
  scrollbar-width: thin;

  @media (max-width: 900px) {
    left: 0;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }

  &::-webkit-scrollbar {
    all: unset;
    height: 10px;
    padding-top: 15px;
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background: ${COLOR_PRIMARY_LIGHT};
    height: 3px;
    border-radius: 9999px;
    width: 30px;
    cursor: auto;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${COLOR_PRIMARY};
    cursor: auto;
  }
`;

type VisitProps = {
    visit: any;
    selected: any;
};

export const StyledEcrfVisitsItem = styled.div<VisitProps>`
  text-align: center;
  font-size: 13px;
  transition: 0.2s ease;
  padding: 15px 20px;
  cursor: pointer;
  color: ${(props) => (props.selected?.id === props.visit?.id ? COLOR_PRIMARY : COLOR_SECONDARY)};
  border-bottom: ${(props) => (props.selected?.id === props.visit?.id ? `3px solid ${COLOR_PRIMARY}` : `3px solid ${COLOR_BG_DARK}`)};
  display: grid;
  place-items: center;
  max-width: 200px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  &:hover {
    border-bottom: 3px solid ${COLOR_PRIMARY};
  }
`;

/** ECRF FORM CONTENT */

export const StyledEcrfForm = styled.div`
  grid-area: content;
  margin-left: 20px;
  margin-right: 90px;
  margin-top: 40px;

  @media (max-width: 900px) {
    margin-right: 20px;
  }
`;

interface ElementProps {
    table: boolean | undefined;
    collection: boolean | undefined;
}

export const StyledEcrfElement = styled.div<ElementProps>`
  display: ${(props) => (props.collection ? 'block' : 'grid')};
  grid-template-columns: ${(props) => (!props.table ? '15vw 1fr 1fr' : '15vw 1fr')};
  align-items: center;
  gap: 20px;

  @media (max-width: 1300px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledEcrfElementButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
    display: grid;
  }

  @media (max-width: 600px) {
    justify-content: start;
  }
`;

export const StyledEcrfElementButtons = styled.div`
  display: grid;
  align-items: center;
  gap: 5px;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 1300px) {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 1150px) {
    display: grid;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    justify-content: start;
  }
`;

export const StyledEcrfElementLabels = styled.div`
  margin-top: 8px;
  font-size: .7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  @media (max-width: 950px) {
    align-items: start;
    flex-direction: column;
  }
`;

export const StyledEcrfSaveAll = styled.div`
  position: fixed;
  top: 50%;
  right: 10px;
  display: grid;
  gap: 10px;
`;

export const StyledButtonShow = styled.div`
  display: none;

  @media (max-width: 900px) {
    display: block;
  }
`;