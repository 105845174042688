import { SelectProps } from "@mui/material/Select";
import { useField } from 'formik';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useEffect, useState, Fragment, useRef } from 'react';
import { useAppDispatch, useAppSelector } from "../../commons/store/hooks";
import { fetchAutocomplete, selectAutocomplete } from "../../features/ecrf/redux/elementDataSlice";
import { selectEcrf } from "../../features/ecrf/redux/ecrfSlice";
import { isArray } from "lodash";

interface Option {
    id: string;
    value: string;
}

export interface AutocompleteFormikProps
  extends Omit<SelectProps, "name" | "value" | "onChange" | "onBlur" | "error">
{
  name: string;
  helperText?: string;
  options: any;
  dataId?: string;
  status?: boolean;
}

const AutocompleteFormik = ({
  name,
  helperText,
  fullWidth,
  options,
  dataId,
  status,
  ...props
}: AutocompleteFormikProps) => {
  const dispatch= useAppDispatch()
  const ecrf = useAppSelector(selectEcrf);
  // const autocompleteData = useAppSelector(selectAutocomplete);
  const [field, meta, form] = useField(name);
  const { value } = meta;
  const { setValue } = form;
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [valueOptions, setValueOptions] = useState<readonly Option[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
      // console.log('AutocompleteFormik useEffect')
      // console.log(field.value)

    let active = true;

    if (!open || value?.value === inputValue) {
      return undefined;
    }

    setLoading(active);

    (async () => {
        // @todo support dynamic args, params
        const args = { dataId };
        const params = {
            'datas.ecrf': ecrf?.id,
            // name: inputValue,
            'elements.data': inputValue,
            'order[createdAt]': 'desc'
        };
        const data = await dispatch(fetchAutocomplete({link: options?.autocomplete_url, args, params})).unwrap();

        if (active) {
            const map = data?.map((item: any): any => {
                if (item?.elements?.length > 0) {
                    const elements: any =  item?.elements?.slice().sort(
                        (a: any, b: any) => a.position - b.position
                    );
                    if (elements[0] && isArray(elements[0]?.data) && elements[0]?.data[0]) {
                        return {
                            id: item?.id,
                            value: elements[0]?.data[0]
                        }
                    }
                }

                return {
                    id: item?.id,
                    value: item?.name
                }
            });

            setValueOptions(map);
        }
    })();

    return () => {
        active = false;
        setLoading(active);
    };
  }, [open, value, inputValue, dispatch]);

  return (
      <Autocomplete
          id={name}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          getOptionLabel={(option) => option.value}
          options={valueOptions}
          filterOptions={(x) => x}
          autoComplete
          includeInputInList
          filterSelectedOptions
          loading={loading}
          value={value || null}
          disabled={status || options?.disabled}
          fullWidth
          onChange={(event, value) => {
              setValue(value);
          }}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => {
              setOpen(false)
              form.setTouched(true)
          }}
          onInputChange={(event, value,reason) => {
              setInputValue(value);
          }}
          renderInput={(params) => (
              <TextField
                  {...params}
                  label={props.label}
                  fullWidth
                  // error={meta.touched && Boolean(meta.error)}
                  // helperText={(meta.touched && meta.error) || helperText}
                  InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                          <Fragment>
                              {loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                          </Fragment>
                      ),
                  }}
              />
          )}
      />
  );
};

export default AutocompleteFormik;
