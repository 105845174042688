import abm from "./images/abm.png";
import umw from "./images/umw.svg";
import neoVinci from "./images/logo_neo.svg";
import headerImg from "./images/empathy-logo.svg";
import {
    Button,
    Container,
    Grid,
} from "@mui/material";
import { ROUTES_CORE } from "commons/constants/paths";
import {
    StyledHeaderSection,
    StyledHeaderImg,
    StyledHeaderHolder,
    StyledHeaderTitle,
    StyledHeaderSubtitle,
    StyledSectionTitle,
    StyledInfoSectionDescription,
    StyledNavbarList,
    StyledNavbarListItem,
    StyledFooter,
    StyledFooterDescription,
    StyledFooterSection,
    StyledFooterImg,
    StyledFooterImgSpan,
    StyledFooterDescriptionContact,
    StyledMainPage,
} from "./Home.styled";
import { useEffect } from "react";
import { useAppDispatch } from "commons/store/hooks";
import MedicalCenter from "../components/MedicalCenter";
import { PROJECT_NAME, PROJECT_EMAIL, PROJECT_PHONE } from "commons/constants/env";
import { Link } from "react-router-dom";

function Home() {
    const dispatch = useAppDispatch();

    /** Tab title controller */
    useEffect(() => {
        document.title = `${PROJECT_NAME} - Strona Główna`;
        return () => {
            document.title = PROJECT_NAME;
        };
    }, [dispatch]);

    return (
        <StyledMainPage>
            <header>
                <Container maxWidth="lg">
                    <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="center">
                        <Grid container item lg={3} xs={12} spacing={0}></Grid>
                        <Grid container item lg={9} xs={12} spacing={0}>
                            <StyledNavbarList>
                                <StyledNavbarListItem>
                                    <Button size="medium">Information about trial</Button>
                                </StyledNavbarListItem>
                                <StyledNavbarListItem>
                                    <Button size="medium">Trial site(s)</Button>
                                </StyledNavbarListItem>
                                <StyledNavbarListItem>
                                    <Button size="medium">Contact</Button>
                                </StyledNavbarListItem>
                                <StyledNavbarListItem>
                                    <Button
                                        variant="outlined"
                                        sx={{borderWidth: "2px", borderColor: "#1D3E73", color: "#1D3E73"}}
                                        to={ROUTES_CORE.LOGIN}
                                        component={Link}
                                    >
                                        SIGN IN
                                    </Button>
                                </StyledNavbarListItem>
                            </StyledNavbarList>
                        </Grid>
                    </Grid>
                </Container>
            </header>

            <StyledHeaderSection>
                <StyledHeaderImg src={headerImg} alt="Background img"/>{" "}
                <StyledHeaderHolder>
                    <Container maxWidth="lg">
                        <StyledHeaderTitle>
                            <b>Empathy</b>
                        </StyledHeaderTitle>
                        <StyledHeaderSubtitle>
                            EMpagliflozin and daPAgliflozin in patients hospiTalized for acute decompensated Heart
                            failure (EMPATHY trial).
                        </StyledHeaderSubtitle>
                    </Container>
                </StyledHeaderHolder>
            </StyledHeaderSection>
            <section>
                <Container maxWidth="lg">
                    <StyledSectionTitle textAlign="center">Information about trial</StyledSectionTitle>
                    <StyledInfoSectionDescription>
                        Projekt „EMpagliflozin and daPAgliflozin in patients hospiTalized for acute
                        decompensated Heart failure (EMPATHY trial)” zainicjowany przez naukowców Warszawskiego
                        Uniwersytetu Medycznego uzyskał dofinansowanie Agencji Badań Medycznych w ramach
                        konkursu na działalność badawczo–rozwojową w zakresie niekomercyjnych badań klinicznych.
                        Wartość wsparcia to 30 084 379,80 zł. Kierownikami zwycięskiego projektu są dr hab.
                        Jolanta Siller-Matuła oraz prof. Marek Postuła z Katedry i Zakładu Farmakologii
                        Doświadczalnej i Klinicznej Warszawskiego Uniwersytetu Medycznego. Okres realizacji
                        projektu to: 1.09.2020 – 31.12.2025 r.
                    </StyledInfoSectionDescription>
                    <MedicalCenter />
                </Container>
            </section>
            <StyledFooter>
                <Container maxWidth="lg">
                    <StyledFooterSection>
                        <StyledFooterDescription>
                            Financial support: Polish Medical Research Agency (Grant number: 2019/ABM/01/00047-00)
                        </StyledFooterDescription>
                        <StyledFooterDescriptionContact>
                            e-mail: {PROJECT_EMAIL} | phone: {PROJECT_PHONE} <br/>
                            <br/>
                            Working days: Monday-Friday, hours: 9:00-17:00
                        </StyledFooterDescriptionContact>
                        <Grid container spacing={3} justifyContent="center">
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <StyledFooterImg src={headerImg} alt="Projekt Empathy logo"/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <StyledFooterImg src={abm} alt="ABM logo"/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2} style={{marginRight: "40px", marginLeft: "40px"}}>
                                <StyledFooterImg src={umw} alt="UMW logo"/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <StyledFooterImgSpan>Technical organizator:</StyledFooterImgSpan>
                                <StyledFooterImg src={neoVinci} alt="Neo-Vinci logo"/>
                            </Grid>
                        </Grid>
                    </StyledFooterSection>
                </Container>
            </StyledFooter>
        </StyledMainPage>
    );
}

export default Home;
