import {
    AppBar,
    Box,
    Button,
    IconButton,
    Menu,
    Tooltip,
    Typography
} from "@mui/material";
import {
    StyledToolbar,
    StyledBadge,
    StyledMenuItem,
    StyledSystemVersion,
} from "./DashboardNavBar.styled";
import {
    AddCircle,
    AccountCircle as AccountCircleIcon,
    Menu as MenuIcon,
    Notifications as NotificationsIcon
} from "@mui/icons-material";
import ColoredIconWrapper from "components/ColoredIconWrapper";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES_CORE, ROUTES_ECRF } from "commons/constants/paths";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import { fetchNotifications, readNotifications } from "features/notifications/redux/notificationsSlice";
import { COLOR_BG_NAV, PROJECT_EMAIL } from "commons/constants/env";
import { addEcrf } from "features/dashboard/redux/dashboardSlice";
import { useSnackbar } from "notistack";
import { roles } from 'commons/auth/roles';
import { selectUserProfile } from "core/redux/userSlice";
import ConfirmationAction from "components/ConfirmationAction";

export interface DashboardNavBarProps {
    newNotificationsCounter?: number;
    onNotifyIconClick?: () => void;
    onBurgerIconClick: () => void;
}

const DashboardNavBar = ({
  newNotificationsCounter,
  onNotifyIconClick,
  onBurgerIconClick,
}: DashboardNavBarProps) => {
    const navigate          = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const userProfile       = useAppSelector(selectUserProfile);

    const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLSpanElement>(null);
    const [isEcrfAdding, setEcrfAdding] = useState(false)

    const handleCloseUserMenu = () => {
        setUserMenuAnchorEl(null);
    };

    const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
        setUserMenuAnchorEl(event.currentTarget);
    };

    let displayAddEcrf: boolean = userProfile.roles.some(
        (role: string) => roles.admin.includes(role) || roles.researcher.includes(role)
    );

    const handleAddEcrf = async () => {
        setEcrfAdding(true)

        try {
            const ecrf = await dispatch(addEcrf()).unwrap();
            if (ecrf && ecrf?.id) {
                navigate(ROUTES_ECRF.DETAILS(ecrf.id));
            }
        } catch (error: any) {
            enqueueSnackbar(error?.detail, {variant: 'error'});
        }

        setEcrfAdding(false)
    };

    /**
     * This function is used to create read notify request
     */
    const itemsPerPage = 15;
    const dispatch = useAppDispatch()
    const readNotify = async () => {
        await dispatch(readNotifications());
        await dispatch(fetchNotifications({page: 1, itemsPerPage}));
    }

    /**
     * This function will display info text on NavBar depending on API URL
     */
    const displaySystemType = () => {
        let API = process.env.REACT_APP_API_URL || ''
        if (API.includes('neoserwer')) {
            return 'WERSJA DEVELOPERSKA SYSTEMU'
        } else if (API.includes('preprod')) {
            return 'WERSJA TESTOWA SYSTEMU'
        } else {
            return ''
        }
    }

    /**
     * This function is used to clear all cookies
     */
    const clearCacheData = () => {
        const cookies = document.cookie.split(";");

        for (const cookie of cookies) {
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
        window.location.reload();
    };

    return (
        <>
            <AppBar position="fixed" style={{backgroundColor: COLOR_BG_NAV, boxShadow: "none"}}>
                <StyledToolbar>
                    <Box mr={2}>
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={onBurgerIconClick}>
                            <MenuIcon/>
                        </IconButton>
                    </Box>
                    <Box flexGrow={1} display="grid" gridTemplateColumns="10% 1fr 1fr" alignItems={'center'}>
                        <Typography variant="h6">Menu</Typography>
                        <StyledSystemVersion> {displaySystemType()} </StyledSystemVersion>
                    </Box>
                    <Box display="flex">
                        {displayAddEcrf && (
                            <ConfirmationAction
                                isProcessing={isEcrfAdding}
                                onClick={handleAddEcrf}
                                confirmationTitle={"Czy na pewno chcesz dodać nowego uczestnika badania?"}
                            >
                                {({handleClick}) => (
                                    <Tooltip title={`Dodaj uczestnika badania`}>
                                        <IconButton
                                            size="small"
                                            onClick={handleClick}
                                            disabled={isEcrfAdding}
                                        >
                                            <ColoredIconWrapper color="secondary">
                                                <AddCircle/>
                                            </ColoredIconWrapper>
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </ConfirmationAction>
                        )}
                        <Box ml={3} onClick={() => readNotify()}>
                            <IconButton size="small" onClick={onNotifyIconClick}>
                                <StyledBadge badgeContent={newNotificationsCounter} color="primary">
                                    <ColoredIconWrapper color="secondary">
                                        <NotificationsIcon/>
                                    </ColoredIconWrapper>
                                </StyledBadge>
                            </IconButton>
                        </Box>
                        <Box ml={3}>
                            <Tooltip title={
                                `W razie problemów wyczyść pliki cookies klikając w przycisk. Jeśli nie uda się rozwiązać problemu, napisz na ${PROJECT_EMAIL}.`
                            }>
                                <span>
                                    <Button size={'small'} onClick={() => clearCacheData()}>
                                        HELP
                                    </Button>
                                </span>
                            </Tooltip>
                        </Box>
                        <Box ml={3}>
                            <IconButton size="small" onClick={handleProfileClick}>
                                <ColoredIconWrapper color="secondary">
                                    <AccountCircleIcon/>
                                </ColoredIconWrapper>
                            </IconButton>
                        </Box>
                    </Box>
                </StyledToolbar>
            </AppBar>

            <Menu
                anchorEl={userMenuAnchorEl}
                anchorOrigin={{vertical: "top", horizontal: "right"}}
                keepMounted
                transformOrigin={{vertical: "top", horizontal: "right"}}
                open={!!userMenuAnchorEl}
                onClose={handleCloseUserMenu}
                style={{marginTop: "30px"}}
            >
                <StyledMenuItem
                    component={Link}
                    to={ROUTES_CORE.LOGOUT}
                >
                    Wyloguj
                </StyledMenuItem>
            </Menu>
        </>
    );
};

export default DashboardNavBar;
