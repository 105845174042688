import {useAppSelector} from 'commons/store/hooks';
import {selectVisits} from 'features/ecrf/redux/ecrfSlice';
import React from 'react';
import {StyledEcrfVisits, StyledEcrfVisitsItem} from '../Ecrf.styled';

interface Props {
    selectedVisit: any;
    setSelectedVisit: (selectedVisit: any) => void;
    isDataLoading: boolean;
}

export const VisitsPanel: React.FC<Props> = ({
    selectedVisit,
    setSelectedVisit,
    isDataLoading
}) => {
    /** This state is holding list of all visits */
    const visits = useAppSelector(selectVisits);

    return (
        <StyledEcrfVisits>
            {visits.map((visit: any) => (
                <StyledEcrfVisitsItem
                    key={visit?.id}
                    onClick={() => {if (!isDataLoading) {
                        setSelectedVisit(visit)
                    }}}
                    visit={visit}
                    selected={selectedVisit}
                >
                    {visit?.name}
                </StyledEcrfVisitsItem>
            ))}
        </StyledEcrfVisits>
    );
};
