import {
    StyledFooterWrapper,
    StyledFooterImg,
    StyledFooterSectionText,
    StyledFooterSectionTitle,
    StyledFooterContnetWrapper,
    StyledFooterGridItem,
    StyledFooterGridContainer,
} from "./Footer.styled";
import Box from "@mui/material/Box";
import Neo_CRF_logo from "commons/assets/Neo_CRF_logo.png";
import ProjektRefsalPng from "commons/assets/projekt-refsal.png";

const Footer = () => {
    return (
        <StyledFooterWrapper>
            <StyledFooterContnetWrapper>
                <StyledFooterGridContainer>
                    <StyledFooterGridItem>
                        <Box flexGrow={1} display="flex" alignItems="center" pb={1}>
                            <StyledFooterImg
                                src={Neo_CRF_logo}
                                alt="Neo CRF logo"
                                marginRight={24}
                            />

                            <Box display="flex" flexDirection="column">
                                <StyledFooterSectionTitle>NEO-CRF</StyledFooterSectionTitle>
                                <StyledFooterSectionText>
                                    Neo-Vinci sp. z o.o.
                                </StyledFooterSectionText>
                                <StyledFooterSectionText>
                                    ul. Puławska 14 | 02-512 Warszawa
                                </StyledFooterSectionText>
                            </Box>
                        </Box>
                    </StyledFooterGridItem>
                    <StyledFooterGridItem>
                        <Box flexGrow={1} display="flex" pb={1}>
                            <Box display="flex" flexDirection="column">
                                <StyledFooterSectionTitle>
                                    Kontakt w sprawach technicznych:
                                </StyledFooterSectionTitle>
                                <StyledFooterSectionText>
                                    e-mail: kontakt@refsal.pl | telefon: 577 070 759
                                </StyledFooterSectionText>
                            </Box>
                        </Box>
                    </StyledFooterGridItem>
                    <StyledFooterGridItem>
                        <Box flexGrow={1} display="flex" pb={1}>
                            <Box display="flex" flexDirection="column">
                                <StyledFooterSectionTitle>
                                    Kontakt w sprawach merytorycznych:
                                </StyledFooterSectionTitle>
                                <StyledFooterSectionText>
                                    e-mail: sekretariat@refsal.pl | telefon: 22 596 61 55
                                </StyledFooterSectionText>
                            </Box>
                        </Box>
                    </StyledFooterGridItem>
                    <StyledFooterGridItem isLast>
                        <Box flexGrow={1} display="flex" pb={1}>
                            <StyledFooterImg
                                src={ProjektRefsalPng}
                                alt="projekt refsal logo"
                            />
                        </Box>
                    </StyledFooterGridItem>
                </StyledFooterGridContainer>
            </StyledFooterContnetWrapper>
        </StyledFooterWrapper>
    );
};

export default Footer;
