import axiosSecureInstance from 'commons/axios/axiosSecureInstance';

export default class Dependency {

  static actions = ['click', 'check', 'select'];
  static methods = ['show', 'hide', 'block'];

  /**
   * This method is used to sort elements in section by position number
   * @param elements
   */
  public static sortByPosition(elements: any): any {
    return elements?.slice().sort((a: any, b: any) => a.position! - b.position!);
  }

  /**
   * fetchDependencies()
   * @param elementId
   */
  public static async fetchDependencies(elementId: string) {
    const response = await axiosSecureInstance.get<any>(
        '/api/element_dependencies',
        {
          params: {
            'parents.childWithElement': elementId,
          },
        }
    );
    return response.data;
  }

  /**
   * This method is used to fetch dependency details by id
   * @param id
   */
  public static async fetchDependency(id: string) {
    const response = await axiosSecureInstance.get<any>(`/api/element_dependencies/${id}`);
    return response.data;
  }

  /**
   * This method is used to check dependency method.
   * Return true if is front-end method
   * @param method
   */
  public static checkMethod(method: string) {
    switch (method) {
      case 'show':
      case 'hide':
      case 'block':
        return true;
      default:
        return false;
    }
  }

  /**
   * This method is used to get data of parent to check the contest
   * @param data
   * @param id
   * @returns data of parent element
   */
  public static getParentData(data: any, id: string): any {
    return data?.elements?.find((elementData: any) => elementData?.element?.id === id);
  }

  /**
   * Assign data by `id` from element data
   * @param data
   * @param id
   * @returns data of parent element
   */
  public static assignData(data: any, id: string): any {
    return data?.find((elementData: any) => elementData?.element?.id === id);
  }

  /**
   * Assign data collection by `id` from element data
   * @param data
   * @param id
   * @returns data of parent element
   */
  public static assignDataCollection(data: any, id: string): any {
    return data.find((elementData: any) => elementData[0] === id);
  }

  /**
   * Filter element by `id`
   * @param elements
   * @param id
   * @returns data of parent element
   */
  public static filterElement(elements: any, id: string): any {
    return elements?.find((element: any) => element?.id === id);
  }

  /**
   * This method is used to create new array includes dependencies
   * @param elements
   * @param ecrf
   * @param allDependencies
   */
  public static async createArray(elements: any, ecrf: any, allDependencies: any) {
    let newArray: any = [];
    let elementsToHide: any = [];

    if (!elements) {
      return;
    }

    const sortedSection = await Dependency.sortByPosition(elements);
    for (const element of sortedSection) {
      if (element?.dependencies?.length > 0) {
        for (const dependencies of element?.dependencies) {
          if (Dependency.checkMethod(dependencies?.methodName)) {
            const dependency = allDependencies.find((e: any) => e.id === dependencies?.id)

            /** Finish if dependency hasn't elements */
            if (!dependency?.elements) return;

            const elementData = Dependency.getParentData(ecrf, element?.id);

            /** Handle dependency actions */
            for (const dependencyElement of dependency?.elements) {
              if (
                dependency?.actionName === 'click' &&
                dependency?.methodName === 'show' &&
                !elementData
              ) {
                elementsToHide.push(dependencyElement);
              } else if (
                dependency?.actionName === 'check' &&
                dependency?.methodName === 'show' &&
                !elementData?.data.includes(dependency?.fields?.[1])
              ) {
                elementsToHide.push(dependencyElement);
              }
            }
          }
        }
      }

      if (elementsToHide.find((e: any) => e.id === element?.id)) {
      } else newArray.push(element);
    }
    return newArray;
  }

  /**
   * This method is used to create new array includes dependencies
   * @param elements
   * @param data
   * @param ecrf
   * @param deps
   */
  public static async createDep(elements: any, data: any, ecrf: any, deps: any = []) {
    let result: any        = [];
    let hideElements: any  = [];
    let blockElements: any = [];

    if (!elements) {
      return result;
    }

    // const sort = await Dependency.sortByPosition(elements);
    for (const element of elements) {
      /** check dependencies */
      if (element?.dependencies?.length > 0) {
        for (const dependencies of element?.dependencies) {
          if (
              !this.actions.includes(dependencies?.actionName) ||
              !this.methods.includes(dependencies?.methodName) ||
              deps?.length === 0
          ) {
            continue;
          }

          const dependency = await deps?.find((e: any) => e.id === dependencies?.id)

          /** skip if dependency hasn't elements */
          if (!dependency?.elements) continue;

          const elementData = await Dependency.assignData(data, element?.id);

          /** handle dependency actions */
          for (const dependencyElement of dependency?.elements) {
            switch (dependency?.methodName) {
              case 'show':
                if (
                    (elementData?.data[0] === '') ||
                    !elementData ||
                    (dependency?.fields && !elementData?.data.includes(dependency?.fields?.[1]))
                ) {
                  hideElements.push(dependencyElement);
                }
                break;
              case 'hide':
                if (
                    elementData &&
                    (elementData?.data[0] !== '') &&
                    (dependency?.fields && elementData?.data.includes(dependency?.fields?.[1]))
                ) {
                  hideElements.push(dependencyElement);
                }
                break
              case 'block':
                if (elementData && (elementData?.data[0] !== '')) {
                  blockElements.push(dependencyElement);
                }
                break;
              default:
                break;
            }
          }
        }
      }

      if (hideElements.find((e: any) => e.id === element?.id)) {
      } else if (blockElements.find((e: any) => e.id === element?.id)) {
        const clone = JSON.parse(JSON.stringify(element));
        clone.mode  = 'block';

        result.push(clone)
      } else result.push(element);
    }

    return result;
  }

  /**
   * This method is used to create new array includes dependencies
   * @param elements
   * @param data
   * @param depends
   */
  public static async assign(elements: any, data: any, depends: any = []) {
    let result: any = [];

    for (const element of elements) {
      /** check dependencies */
      if (element?.dependencies?.length > 0) {
        const elementData = await Dependency.assignData(data, element?.id);

        if (!elementData?.elements) continue;

        for (const dependencies of element?.dependencies) {
          if (
              !this.actions.includes(dependencies?.actionName) ||
              !this.methods.includes(dependencies?.methodName) ||
              depends?.length === 0
          ) {
            continue;
          }

          const dependency = await depends?.find((dep: any) => dep.id === dependencies?.id)

          /** skip if dependency hasn't elements */
          if (!dependency?.elements) continue;

          result.push(dependency)
        }
      }
    }

    return result;
  }

  /**
   * This method is used to create new array in modals includes dependencies
   * @param elements
   * @param ecrf
   */
  public static async createModalArray(elements: any, ecrf: any) {
    let newArray: any = [];
    let elementsToHide: any = [];

    if (!elements) {
      return;
    }

    const sortedSection = await Dependency.sortByPosition(elements);
    for (const element of sortedSection) {
      if (element?.dependencies?.length > 0) {
        for (const dependencies of element?.dependencies) {
          const dependency = await Dependency.fetchDependency(dependencies?.id);

          /** Finish if dependency hasn't elements */
          if (!dependency?.elements) return;

          const elementData = Dependency.assignDataCollection(ecrf, element?.id);

          /** Handle dependency actions in modal */
          for (const dependencyElement of dependency?.elements) {
            if (
              dependency?.actionName === "click" &&
              dependency?.methodName === "show" &&
              !elementData
            ) {
              elementsToHide.push(dependencyElement);
            } else if (
              dependency?.actionName === "check" &&
              dependency?.methodName === "show" &&
              (!elementData || !elementData[1].includes(dependency?.fields?.[1]))
            ) {
              elementsToHide.push(dependencyElement);
            }
          }
        }
      }

      if (elementsToHide.find((e: any) => e.id === element?.id)) {
      } else newArray.push(element);
    }
    return newArray;
  }

  /**
   * Create data dependencies
   * @param element
   * @param data
   */
  public static async createDataCollection(element: any, data: any) {
    let result: any = [];

    if (!element.children) {
      return result;
    }

    let elements = element.children;

    // @todo filter dependencies via current elements
    const allDependencies = await Dependency.fetchDependencies(element.id);
    const collection      = await Dependency.sortByPosition(data);

    for (const item of collection) {
      let els: any = [];
      let dependElements: any = [];

      const sort = await Dependency.sortByPosition(item?.elements);
      for (let elementData of sort) {
        const element = Dependency.filterElement(elements, elementData?.element?.id);

        if (element?.dependencies?.length > 0) {
          for (const dependencies of element.dependencies) {
            const dependency = allDependencies.find((e: any) => e.id === dependencies?.id)

            /** Finish if dependency hasn't elements */
            if (!dependency?.elements) continue;

            if (
                (
                    dependency?.actionName === 'click'
                    || dependency?.actionName === 'check'
                ) &&
                dependency?.methodName === 'block' &&
                (
                    (elementData.data[0] && elementData.data[0] !== '')
                    || elementData?.data.includes(dependency?.fields?.[1])
                )
            ) {
              for (let dependencyElement of dependency?.elements) {
                dependElements.push(dependencyElement);
              }
            }
          }
        }
      }

      for (let elementData of sort) {
        const element = Dependency.filterElement(elements, elementData?.element?.id);
        if (dependElements.find((e: any) => e.id === element?.id)) {
          elementData      = JSON.parse(JSON.stringify(elementData));
          elementData.mode = 'block';
        }

        els.push(elementData)
      }

      const clone = { ...item };
      clone.elements = els
      clone.id = item.id

      result.push(clone);
    }

    return result;
  }

  /**
   * Prepare dependencies
   * @param element
   * @param data
   * @param depends
   */
  public static async prepareCollection(element: any, data: any, depends: any = []) {
    let result: any = [];

    if (!element.children) {
      return result;
    }

    let elements = element.children;

    // @todo filter dependencies via current elements
    // if (!depends) {
      depends = await Dependency.fetchDependencies(element.id);
    // }

    data = await Dependency.sortByPosition(data);

    for (const item of data) {
      let els: any = [];
      let dependElements: any = [];

      const sort = await Dependency.sortByPosition(item?.elements);
      for (let elementData of sort) {
        const el = Dependency.filterElement(elements, elementData?.element?.id);

        if (el?.dependencies?.length > 0) {
          for (const dependencies of el.dependencies) {
            const dependency = depends.find((e: any) => e.id === dependencies?.id)

            /** Finish if dependency hasn't elements */
            if (!dependency?.elements) continue;

            if (
                (dependency?.actionName === 'click' || dependency?.actionName === 'check') &&
                dependency?.methodName === 'block' &&
                (
                    (elementData.data[0] && elementData.data[0] !== '')
                    || elementData?.data.includes(dependency?.fields?.[1])
                )
            ) {
              for (let dependencyElement of dependency?.elements) {
                dependElements.push(dependencyElement);
              }
            }
          }
        }
      }

      for (let elementData of sort) {
        const el = Dependency.filterElement(elements, elementData?.element?.id);
        if (dependElements.find((e: any) => e.id === el?.id)) {
          elementData      = JSON.parse(JSON.stringify(elementData));
          elementData.mode = 'block';
        }

        els.push(elementData)
      }

      const clone = { ...item };
      clone.elements = els
      clone.id = item.id

      result.push(clone);
    }

    return result;
  }
}
