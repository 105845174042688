/*
 * Project: ecrf-front
 * Author: Dominik Obłoza
 * User: @dominik.obloza
 * Date: 03.03.2022
 * Time: 12:49
 */

import React from 'react';
import { Box, Button, CircularProgress, Modal } from "@mui/material";
import ColoredBox from "components/ColoredBox";
import {
    StyledListTable,
    StyledListTableHeadTitle,
    StyledListTitle
} from "./ObservationList.styled";
import ObservationListItem from "./ObservationListItem";
import { useAppSelector } from "commons/store/hooks";
import { selectEcrfVisits } from "features/dashboard/redux/dashboardSlice";

interface IObservationListProps {
    open: boolean,
    handleClose: () => void;
    loading: boolean;
}

const ObservationList: React.FC<IObservationListProps> = (props) => {
    const visitsData = useAppSelector(selectEcrfVisits)

    return (
        <Modal open={props.open} onClose={props.handleClose} style={{overflow: "scroll", background: "none"}}>
            <>
            <ColoredBox p={3} maxWidth="100%" ml="10vw" mr="10vw" mt="30px" mb="30px">
                <Box textAlign="end">
                    <Button variant="outlined" onClick={props.handleClose} color="primary">
                        ZAMKNIJ
                    </Button>
                </Box>

                <StyledListTitle>
                    Lista obserwacji
                </StyledListTitle>

                {props.loading &&
                    <Box display={'grid'} style={{placeItems: 'center'}}>
                        <CircularProgress/>
                    </Box>
                }

                <StyledListTable>
                    {!props.loading && (
                        <StyledListTableHeadTitle>
                            <span/>
                            <span/>
                            <span style={{textAlign: 'end'}}>Wypełnionych pól</span>
                            <span/>
                        </StyledListTableHeadTitle>
                    )}

                    {[].slice.call(visitsData).map((visit: any, idx: number) =>
                        <ObservationListItem key={visit.id} visit={visit} idx={idx + 1}/>
                    )}
                </StyledListTable>
            </ColoredBox>
            </>
        </Modal>
    );
};

export default ObservationList