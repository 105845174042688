import Footer from "components/Footer/Empathy";
import {
    Page404Styled,
    Page404Content,
    ImageWrapper,
    Content,
    Title,
    Subtitle,
    Action,
} from "./Page404.styled";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES_CORE } from "commons/constants/paths";
import Icon404 from "commons/assets/Icon404.png";

const Page404 = () => {
    let navigate = useNavigate();

    return (
        <Page404Styled>
            <Page404Content>
                <ImageWrapper>
                    <img src={Icon404} alt="404"/>
                </ImageWrapper>
                <Content>
                    <Title>BŁĄD 404</Title>
                    <Subtitle>Strona nie została odnaleziona</Subtitle>
                    <div>
                        <Action onClick={() => navigate(-1)}>
                            Wróć do poprzedniej strony
                        </Action>
                    </div>
                    {/*<div>*/}
                    {/*    <Action*/}
                    {/*        // as={Link}*/}
                    {/*        to={ROUTES_CORE.HOME}*/}
                    {/*    >*/}
                    {/*        Wróć do strony głównej*/}
                    {/*    </Action>*/}
                    {/*</div>*/}
                </Content>
            </Page404Content>
            <Footer></Footer>
        </Page404Styled>
    );
};

export default Page404;
