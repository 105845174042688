/*
 * Project: ecrf-front
 * Author: Dominik Obłoza
 * User: @dominik.obloza
 * Date: 14.02.2022
 * Time: 10:36
 */

import {
    StyledDashboardQueriesTable,
    StyledDashboardQueriesTableBody,
    StyledDashboardQueriesTableHead,
    StyledDashboardQueriesTitle,
    StyledDashboardQueriesWrapper
} from "./DashboardQueries.styled";
import QueryRow from "./QueryRow";
import axiosSecureInstance from "../../../../commons/axios/axiosSecureInstance";
import React, {Component} from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Collapse,
    TextField,
    Tooltip
} from "@mui/material";
import {connect} from "react-redux";
import { DeleteSweepRounded, FilterList, MoreHorizOutlined, } from '@mui/icons-material';
import QueriesPanel from "../../../ecrf/components/QueriesPanel";
import { DatePicker } from '@mui/x-date-pickers';
import {StyledGlobalMedicalCentersFiltersButton} from "../DashboardStats/DashboardStats.styled";
import DateTime from "core/helpers/date/DateTime";
import { enqueueSnackbar } from 'notistack'
import { DATE_FORMAT } from "commons/constants/env";

class DashboardQueries extends Component<any, any> {
    state = {
        selectedPage: 1,
        itemsPerPage: 30,
        isOpenQueryPanel: false,
        selectedQueryPanel: {
            currentEcrfID: null,
            elementDataId: null,
            elementName: null,
            queryId: null
        },
        data: [],
        isDataLoading: false,
        displayFilters: false,
        ecrfValue: '',
        authorValue: '',
        recipientValue: '',
        startDate: null,
        endDate: null
    }

    /**
     * This method is used to fetch queries from DB
     * @private
     */
    private async fetchData(initial?: boolean) {
        try {
            this.setState({isDataLoading: true});
            const response = await axiosSecureInstance.get<any>
            (`/api/comments?${this.state.startDate ? `&createdAt[after]=${this.state.startDate}` : ''}${this.state.endDate ? `&createdAt[before]=${this.state.endDate}` : ''}${this.state.ecrfValue !== '' ? `&element.ecrf.randomId=${this.state.ecrfValue}` : ''}${this.state.authorValue !== '' ? `&owner.lastname=${this.state.authorValue}` : ''}${this.state.recipientValue !== '' ? `&element.ecrf.owner.lastname=${this.state.recipientValue}` : ''}`, {
                headers: {
                    accept: "application/hal+json",
                },
                params: {
                    page: initial ? 1 : this.state.selectedPage,
                    pagination: true,
                },
            });

            let newData: any = []
            if (initial) {
                newData = response.data._embedded.item
            } else {
                newData = [...this.state.data, ...response.data._embedded.item]
            }

            this.setState({
                data: newData,
                itemsPerPage: response.data?.itemsPerPage,
                selectedPage: this.state.selectedPage + 1
            });

            this.setState({isDataLoading: false});
        } catch (error: any) {
            this.setState({isDataLoading: false});
            enqueueSnackbar('Nie znaleziono', {variant: 'warning'})
        }
    }

    /**
     * This method is used to open query panel
     * @private
     */
    private openQueryPanel() {
        this.setState({isOpenQueryPanel: true});
    }

    /**
     * This method is used to set props for query panel
     * @param ecrfId
     * @param elementId
     * @param elementName
     * @private
     */
    private selectRowData(ecrfId: string, elementId: string, elementName: string) {
        const newData = {
            currentEcrfID: ecrfId,
            elementDataId: elementId,
            elementName: {
                label: elementName
            },
            queryId: ecrfId
        }

        this.setState({selectedQueryPanel: newData});
    }

    /**
     * This method is used to show or hide filters
     * @private
     */
    private setDisplayFilters() {
        this.setState({displayFilters: !this.state.displayFilters});
    }

    /**
     * This method is used to handle randomization number and save it to state
     * @param e
     * @private
     */
    private handleEcrfValue(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ecrfValue: e.target.value});
    }

    /**
     * This method is used to handle author query value and save it to state
     * @param e
     * @private
     */
    private handleAuthorValue(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({authorValue: e.target.value});
    }

    /**
     * This method is used to handle recipient query value and save it to state
     * @param e
     * @private
     */
    private handleRecipientValue(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({recipientValue: e.target.value});
    }

    /**
     * This method is used to handle start date and save it to state
     * @param e
     * @private
     */
    private handleStartDate(e: any) {
        const formattedDate = DateTime.toIso(e, DATE_FORMAT)
        this.setState({startDate: formattedDate});
    }

    /**
     * This method is used to handle end date and save it to state
     * @param e
     * @private
     */
    private handleEndDate(e: any) {
        const formattedDate = DateTime.toIso(e, DATE_FORMAT)
        this.setState({endDate: formattedDate});
    }

    /**
     * This method is used to clear all filters and fetch initial data
     * @private
     */
    private async clearFilters() {
        await this.setState({
            displayFilters: false,
            recipientValue: '',
            authorValue: '',
            ecrfValue: '',
            endDate: null,
            startDate: null,
            selectedPage: 1
        });

        this.fetchData().then()
    }

    /**
     * Load config and fetch initial data
     */
    componentDidMount() {
        this.fetchData().then()
    }

    render() {
        return (
            <>
                <StyledDashboardQueriesWrapper>
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <StyledDashboardQueriesTitle>
                            Tabela zapytań (queries)
                        </StyledDashboardQueriesTitle>

                        <Button style={{whiteSpace: 'nowrap', marginTop: '20px'}} color={'primary'} size={'small'}
                            onClick={() => this.setDisplayFilters()} variant={'outlined'}>
                            {this.state.displayFilters ? 'Ukryj filtry' : 'Pokaż filtry'}
                        </Button>
                    </Box>

                    {/* <--- FILTERS ZONE | BEGIN ---> */}
                    <Collapse in={this.state.displayFilters}>
                        <Box display={'flex'} alignItems={'center'} mt={1} border={`1px solid #e7e7e7`} p={3}
                            borderRadius={'10px'}>
                            <Box display="grid" gridTemplateColumns={'1fr 1fr 1fr 1fr 1fr'} alignItems="center" style={{gap: "20px"}}>
                                <Box display={'flex'} gap={'20px'} alignItems="center">
                                    <Tooltip title="Wprowadź początek zakresu zadanych querów" arrow>
                                        <>
                                            <DatePicker
                                                // animateYearScrolling
                                                // size={'small'}
                                                value={this.state.startDate}
                                                label={'Początek'}
                                                // inputVariant="outlined"
                                                // fullWidth
                                                format="yyyy-MM-dd"
                                                onChange={(e: any) => this.handleStartDate(e)}
                                                slotProps={{ textField: { size: 'small' } }}
                                            />
                                        </>
                                    </Tooltip>
                                    -
                                    <Tooltip title="Wprowadź koniec zakresu zadanych querów" arrow>
                                        <>
                                            <DatePicker
                                                // animateYearScrolling
                                                // size={'small'}
                                                value={this.state.endDate}
                                                label={'Koniec'}
                                                // inputVariant="outlined"
                                                // fullWidth
                                                format="yyyy-MM-dd"
                                                onChange={(e: any) => this.handleEndDate(e)}
                                                slotProps={{ textField: { size: 'small' } }}
                                            />
                                        </>
                                    </Tooltip>
                                </Box>

                                <Tooltip title="Wprowadź numer randomizacyjny badania" arrow>
                                    <TextField
                                        label={'Numer randomizacyjny'}
                                        onChange={this.handleEcrfValue.bind(this)}
                                        value={this.state.ecrfValue}
                                        size={'small'}
                                        fullWidth variant={'outlined'}
                                    />
                                </Tooltip>

                                <Tooltip title="Wprowadź nazwisko autora query" arrow>
                                    <TextField
                                        label={'Nazwisko autora'}
                                        onChange={this.handleAuthorValue.bind(this)}
                                        value={this.state.authorValue}
                                        size={'small'}
                                        fullWidth variant={'outlined'}
                                    />
                                </Tooltip>

                                <Tooltip title="Wprowadź nazwisko odbiorcy query" arrow>
                                    <TextField
                                        label={'Nazwisko odbiorcy'}
                                        onChange={this.handleRecipientValue.bind(this)}
                                        value={this.state.recipientValue}
                                        size={'small'}
                                        fullWidth
                                        variant={'outlined'}
                                    />
                                </Tooltip>

                                <Box display={'flex'} gap={'20px'} alignItems={'center'}>
                                    <Tooltip title={'Filtruj po wybranych opcjach'} arrow>
                                        <StyledGlobalMedicalCentersFiltersButton
                                            status={!this.state.isDataLoading}
                                            onClick={() => this.fetchData(true)}
                                        >
                                            {this.state.isDataLoading ? <CircularProgress size={14} /> : <FilterList />}
                                            Filtruj
                                        </StyledGlobalMedicalCentersFiltersButton>
                                    </Tooltip>

                                    <Tooltip title={'Wyczyść wszystkie filtry'} arrow>
                                        <StyledGlobalMedicalCentersFiltersButton
                                            onClick={this.clearFilters.bind(this)}
                                            status={true}
                                        >
                                            <DeleteSweepRounded /> Wyczyść
                                        </StyledGlobalMedicalCentersFiltersButton>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Box>
                    </Collapse>
                    {/* <--- FILTERS ZONE | END ---> */}


                    <StyledDashboardQueriesTable isOpen={this.state.isOpenQueryPanel}>
                        <StyledDashboardQueriesTableHead>
                            <span>Lp.</span>
                            <span />
                            <span>Numer randomizacyjny</span>
                            <span>Data dodania</span>
                            <span>Dodał</span>
                            <span>Otrzymał</span>
                            <span>Sekcja</span>
                            <span>Pole</span>
                            <span>Zawartość pola</span>
                            <span>Treść zapytania</span>
                            <span>Status</span>
                            <span>Akcja</span>
                        </StyledDashboardQueriesTableHead>


                        <StyledDashboardQueriesTableBody>
                            {this.state.data.filter((e: any) => e?._embedded?.element?.id).map((row: any, idx) =>
                                <QueryRow selectRowData={this.selectRowData.bind(this)} idx={idx + 1}
                                    openQueryPanel={this.openQueryPanel.bind(this)}
                                    user={this.props.userProfile}
                                    key={row?.id} data={row} />
                            )}
                        </StyledDashboardQueriesTableBody>
                    </StyledDashboardQueriesTable>

                    {this.state.isDataLoading &&
                        <Box width={'100%'} display={'grid'} mt={5} justifyContent={'center'}>
                            <CircularProgress />
                        </Box>}

                    {this.state.data.length % this.state.itemsPerPage === 0 && this.state.data.length > 0 && (
                        <Box width={'100%'} display={'grid'} mt={5} justifyContent={'center'}>
                            <Button onClick={() => this.fetchData()} color={'secondary'}
                                disabled={this.state.isDataLoading}
                                startIcon={this.state.isDataLoading ?
                                    <CircularProgress color={'secondary'} size={12} />
                                    :
                                    <MoreHorizOutlined />}
                                variant={'outlined'}>
                                Pokaż więcej
                            </Button>
                        </Box>
                    )}
                </StyledDashboardQueriesWrapper>


                {/* <--- Queries Panel ---> */}
                <QueriesPanel
                    currentEcrfId={this.state.selectedQueryPanel.currentEcrfID}
                    isOpen={this.state.isOpenQueryPanel}
                    queryId={this.state.selectedQueryPanel.queryId || '-'}
                    onClose={() => {
                        this.setState({isOpenQueryPanel: false})
                    }}
                    elementDataId={this.state.selectedQueryPanel.elementDataId}
                    element={this.state.selectedQueryPanel.elementName}
                />
            </>
        );
    }
}


/*
 *  This function is used to
 * get userProfile data from
 * Redux state
 */
const mapStateToProps = (state: any) => ({
    userProfile: state.core.user.userProfile
})

// export default withSnackbar(connect(mapStateToProps, null)(DashboardQueries))
export default connect(mapStateToProps, null)(DashboardQueries)