/*
 * Project: ecrf-front
 * Author: Dominik Obłoza
 * User: @dominik.obloza
 * Date: 17.03.2022
 * Time: 14:48
 */

import React, { useState } from 'react';
import { TextField } from "@mui/material";
import { Data } from "./RowItem";

interface ISerialNumber {
    actualValue?: string,
    rowData: any,
    setRowData: (rowData: Data) => void;
}

export const SerialNumber: React.FC<ISerialNumber> = (props) => {
    const [seriesValue, setSeriesValue] = useState(props.actualValue || '')
    const handleSeries = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSeriesValue(e.target.value)
        const newData = Object.assign({}, props.rowData, {serial: e.target.value});
        props.setRowData(newData)
    }

    return (
        <TextField
            value={seriesValue}
            onChange={handleSeries}
            label={'Seria leku'}
            variant={'outlined'}
            size={'small'}
            fullWidth
        />
    );
};