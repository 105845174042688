import React, {Component} from 'react';
import {Box, Checkbox, CircularProgress, MenuItem, TextField } from "@mui/material";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import {debounce} from "lodash";

export class PharmIndexFormik extends Component<any, any> {
    state = {
        drugValue: '',
        groupValue: '',
        drugResponse: [],
        groupResponse: [],
        isGroupDataLoading: false,
        isDrugDataLoading: false,
        isShowingCustomField: this.props.data && this.props.data.length > 1 || this.props.value === "" ? false : true,
        customFieldValue: this.props.data && this.props.data.length > 1 ? '' : this.props.value
    }

    /*
     * This method is used to
     * search drug in DB
     * and fetch it (first 15 records)
     */
    private async fetchDrugData(name?: string) {
        this.setState({isDrugDataLoading: true});
        const response = await axiosSecureInstance.get<any>(`/api/pharm-index/find-drug-group/${name}`)
        if (response.data?.length === 0) {
            this.setState({isNoProperties: true});
        }
        this.setState({drugResponse: response.data, isDrugDataLoading: false});
    }

    /*
    * This method is used to
    * search group in DB
    * and fetch it (first 15 records)
    */
    private async fetchGroupData() {
        try {
            this.setState({isGroupDataLoading: true});

            const response = await axiosSecureInstance.get<any>(`/api/pharm-index/find-drug-group`)

            /* Parse response to unique flat array */
            let filterByMainGroup = []

            if (this.props.options?.mainGroup) {
                filterByMainGroup = response.data.filter((group: any) => group.mainGroup === this.props.options?.mainGroup)
            } else {
                filterByMainGroup = response.data
            }

            let newArray = []

            for (const drug of filterByMainGroup) {
                newArray.push(drug.drugGroup)
            }

            let unique = newArray.filter((x, i, a) => a.indexOf(x) === i)

            this.setState({groupResponse: unique, isGroupDataLoading: false});

        } catch (error: any) {
            this.setState({isGroupDataLoading: false});
        }
    }

    /*
    * This method is used to save
    * input value to state
    */
    private handleCustomField(e: any) {
        this.debounceSaveCustomField(e)
    }

    /*
    * This function is used to
    * save data after 500ms
    * of stop typing
    */
    private debounceSaveCustomField = debounce((e: any) => {
        this.props.setValue(e);
        this.setState({drugValue: '', groupValue: ''});
    }, 500)

    /*
     * This method is used to
     * load initial config
     */
    private async loadConfig() {
        await this.fetchGroupData()
        if (this.props.data && this.props.data[1] !== "" && this.props.data[0] !== '') {
            this.setState({groupValue: this.props.data[0], drugValue: this.props.data[1]});
            await this.fetchDrugData(this.props.data[0])
        }
    }

    /*
     * This method is used to
     * display and hide
     * custom value field
     */
    private toogleSetDisplayCustomField() {
        this.setState({isShowingCustomField: !this.state.isShowingCustomField});
    }

    componentDidMount() {
        this.loadConfig()
    }

    render() {
        return (
            <Box width={'100%'}>
                {!this.state.isShowingCustomField &&
                    <>
                        {/* Drug group select */}
                        <TextField select value={this.state.groupValue} variant={'outlined'} fullWidth
                            label='Wybierz grupę leków'
                            onChange={(e: any) => {
                                this.fetchDrugData(e.target.value);
                                this.setState({groupValue: e.target.value, drugValue: ''});
                            }}
                            disabled={this.state.isGroupDataLoading || this.props.status}
                        >
                            {this.state.groupResponse.map((group: any) => (
                                <MenuItem key={group} value={group}>
                                    {group}
                                </MenuItem>))}
                        </TextField>

                        <Box
                            mt={1}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            gap={'20px'}
                            fontSize={'.8rem'}
                        >
                            {this.state.isDrugDataLoading && <>
                                <CircularProgress size={12}/> Ładowanie danych
                            </>
                            }
                        </Box>

                        {/* Active ingredient select */}
                        <Box mt={1}>
                            <TextField select value={this.state.drugValue} variant={'outlined'} fullWidth
                                label="Wybierz substancję czynną"
                                disabled={this.state.isDrugDataLoading || this.props.status}
                                onChange={(e: any) => {
                                    this.setState({drugValue: e.target.value});
                                    this.props.setValue([this.state.groupValue, e.target.value]);
                                    this.setState({customFieldValue: ''});
                                }}
                            >
                                {this.state.drugResponse.map((drug: any) => (
                                    <MenuItem key={drug.id} value={drug.activeSubstance}>
                                        {drug.activeSubstance}
                                    </MenuItem>))}
                            </TextField>
                        </Box>
                    </>
                }

                {this.state.isShowingCustomField &&
                    <TextField variant={'outlined'} fullWidth value={this.state.customFieldValue}
                        label={'Nazwa międzynarodowa'}
                        onChange={((e: any) => {
                            this.setState({customFieldValue: e.target.value});
                            this.handleCustomField(e.target.value)
                        })}/>
                }

                {/* Custom value */}
                <Box mt={1} fontSize={'.9rem'}>
                    <Checkbox checked={this.state.isShowingCustomField} disabled={this.props.status}
                        onClick={() => this.toogleSetDisplayCustomField()}/> Dodaj własne
                </Box>
            </Box>
        );
    }
}



