import React, {Component} from 'react';
import Button from "@mui/material/Button";
import Modal from '@mui/material/Modal';
import {
    StyledUploaderDesc,
    StyledUploaderField,
    StyledUploaderFieldDesc,
    StyledUploaderFieldSelected,
    StyledUploaderFieldTitle,
    StyledUploaderFilesSize,
    StyledUploaderFilesWrapper,
    StyledUploaderModalWrapper,
    StyledUploaderTitle
} from "./UploadFileFormik.styled";
import {Box, CircularProgress} from "@mui/material";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import FileItem from "./FileItem";
import axiosSecureInstance from "../../../commons/axios/axiosSecureInstance";
import PublishIcon from '@mui/icons-material/Publish';
import { enqueueSnackbar, closeSnackbar } from 'notistack'
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import {ImportExport} from "@mui/icons-material";

interface UploadFileFormik {
    status?: boolean;
    data: any;
    element: any;
    ecrfId: string,
    update: () => void;
    reload: () => void;
    enqueueSnackbar?: any;
}

class UploadFileFormik extends Component<any, any> {
    /**
     * uploadLimit is holding actual file size limit
     * @private
     */
    private readonly uploadLimit = 2147483648

    state = {
        isModalOpen: false,
        files: [],
        isUploading: false,
        isUploaded: false,
        bucket: [],
        isDataFetching: false,
        filesSize: 0
    }

    /**
     * This method is used to open modal
     * @private
     */
    private openModal() {
        this.setState({isModalOpen: true, isUploaded: false});
    }

    /**
     * This method is used to close modal
     * @private
     */
    private closeModal() {
        this.setState({isModalOpen: false, files: [], filesSize: 0});
    }

    /**
     * This method is used to handle select files from input and save it to state
     * @param e
     * @private
     */
    private handleSelectFiles(e: any) {
        e.preventDefault();
        e.stopPropagation();

        let files: any[] = [...this.state.files]
        let size: number = this.state.filesSize

        for (const file of e.target.files) {
            files.push(file)
            size = size + file.size
        }

        this.setState({files: files, isUploaded: false, filesSize: size});
    }

    /**
     * This method is used to remove item from files list
     * @param name
     * @private
     */
    private removeFromList(name: string) {
        let files: any[] = []
        let size = 0

        for (const file of this.state.files as any) {
            if (file.name !== name) {
                files.push(file)
                size = size + file.size
            }
        }

        this.setState({files: files, filesSize: size});
    }

    /**
     * This method is used to upload files to S3 storage
     * @private
     */
    private async uploadFiles() {
        if (this.state.filesSize > this.uploadLimit) return

        try {
            let formData = new FormData()

            for (let i = 0; i < this.state.files.length; i++) {
                formData.append(`file${i}`, this.state.files[i])
            }

            this.setState({isUploading: true});
            await axiosSecureInstance.post(`/api/element_datas/${this.props.ecrfId}/${this.props.element.id}/upload`, formData)

            this.getFilesData(this.props.data.id).then()

            await this.props.reload()

            this.setState({isUploading: false, isUploaded: true, files: []});
        } catch (error: any) {
            this.setState({isUploading: false});
            enqueueSnackbar(error?.response?.data?.detail, {variant: 'warning'})
        }
    }

    /**
     * This method is used to get data of uploader element
     * @param id
     * @private
     */
    private async getFilesData(id: string) {
        try {
            this.setState({isDataFetching: true});
            const response = await axiosSecureInstance.get(`/api/element_resource_transfers?element=${id}`)
            this.setState({bucket: response.data, isDataFetching: false});

        } catch (error: any) {
            this.setState({isDataFetching: false});
            alert(error?.response?.data?.detail)
        }
    }

    componentDidMount() {
        if (this.props.data) {
            this.getFilesData(this.props.data.id).then()
        }
    }

    render() {
        return (
            <>
                <Button
                    variant={'outlined'}
                    size="small"
                    color={'primary'}
                    style={{padding: '5px 30px', width: "100%"}}
                    onClick={this.openModal.bind(this)}
                    startIcon={<ImportExport/>}
                >
                    Import data
                </Button>

                <Modal
                    style={{overflowY: 'auto', width: "100%", display: 'grid', justifyItems: 'center'}}
                    open={this.state.isModalOpen}
                >
                    <Box m={3} maxWidth={'600px'} p={3}>
                        <StyledUploaderModalWrapper>
                            <StyledUploaderTitle>
                                Import data
                            </StyledUploaderTitle>

                            <StyledUploaderDesc>
                                To add a file, click the UPLOAD FILES button and select a file from your computer's
                                disk. If you want to add more than one file, select them using the CTRL button.
                            </StyledUploaderDesc>

                            {/* <--- Initial upload view ---> */}
                            {this.state.files && !this.props.data && this.state.files.length === 0 && (
                                <>
                                    <Button
                                        component={'label'}
                                        style={{all: 'unset', width: '100%'}}
                                        disabled={this.props.status}
                                    >
                                        <StyledUploaderField
                                            onChange={this.handleSelectFiles.bind(this)}
                                            component={'label'}>
                                            <InsertDriveFileOutlinedIcon style={{fontSize: '60px', color: '#929DA7'}} />

                                            <StyledUploaderFieldTitle>
                                                Select files here
                                            </StyledUploaderFieldTitle>

                                            <StyledUploaderFieldDesc>
                                                File format: CSV
                                            </StyledUploaderFieldDesc>

                                            <StyledUploaderFieldDesc>
                                                Max files size: 2GB
                                            </StyledUploaderFieldDesc>

                                            <Button
                                                variant={'outlined'}
                                                component={'label'}
                                                style={{marginTop: '15px'}}
                                                disabled={this.props.status}
                                            >
                                                Select files
                                                <input multiple type={'file'} hidden />
                                            </Button>
                                        </StyledUploaderField>
                                    </Button>
                                    <Button style={{marginTop: '25px'}} onClick={() => this.closeModal()} size={'small'}>
                                        Cancel
                                    </Button>
                                </>
                            )}

                            {/* <--- Loaded files view ---> */}
                            {!this.state.isUploaded && this.state.files?.length > 0 && (
                                <StyledUploaderFieldSelected>
                                    <StyledUploaderFieldTitle>
                                        {(this.state.files.length > 0) ? 'Selected files' : 'Select files'}
                                    </StyledUploaderFieldTitle>

                                    <StyledUploaderFilesWrapper>
                                        {[].slice.call(this.state.files).map((file: any) =>
                                            <FileItem key={file.id} isUploading={this.state.isUploading}
                                                name={file.name} size={file.size} id={file.id}
                                                removeRow={this.removeFromList.bind(this)} />
                                        )}

                                        <StyledUploaderFilesSize limit={this.state.filesSize > this.uploadLimit}>
                                            Files size limit: {FileItem.formatBytes(this.state.filesSize)}{" "}
                                            / {FileItem.formatBytes(this.uploadLimit)}
                                        </StyledUploaderFilesSize>
                                    </StyledUploaderFilesWrapper>

                                    <Box mt={1} display='flex' gap={'20px'}>
                                        <Button onClick={() => this.closeModal()} size={'small'}>
                                            Cancel
                                        </Button>

                                        <Button
                                            disabled={this.state.isUploading
                                                || this.state.filesSize > this.uploadLimit || this.props.status}
                                            component={'label'}
                                            onClick={this.uploadFiles.bind(this)} size={'small'}
                                            startIcon={this.state.isUploading ? <CircularProgress size={14} /> :
                                                <PublishIcon />} color={'primary'}
                                        >
                                            {this.state.isUploading ? 'IMPORTING' : 'IMPORT'}
                                        </Button>

                                    </Box>
                                </StyledUploaderFieldSelected>
                            )}

                            {/* <--- View for positive upload status ---> */}
                            {this.state.isUploaded && (
                                <StyledUploaderFieldSelected style={{marginBottom: '-50px'}}>
                                    <StyledUploaderTitle>
                                        Files imported successfully!
                                    </StyledUploaderTitle>
                                </StyledUploaderFieldSelected>
                            )}

                            {/* List files */}
                            {this.props.data && (
                                <>
                                    {(this.state.files.length === 0) && (
                                        <StyledUploaderFieldSelected>
                                            <StyledUploaderFieldTitle style={{marginBottom: '10px'}}>
                                                Imported files
                                            </StyledUploaderFieldTitle>

                                            <StyledUploaderFilesWrapper>
                                                {[].slice.call(this.state.bucket).map((file: any) =>
                                                    <FileItem name={file.filename} size={file.size} link={file.link}
                                                        key={file.id} id={file.id} dataId={this.props.data.id}
                                                        update={this.getFilesData.bind(this)} />
                                                )}

                                                {/* <--- Spinner when data is fetching --- >*/}
                                                {this.state.isDataFetching && (
                                                    <Box m={3} display={'grid'} justifyContent={'center'}>
                                                        <CircularProgress />
                                                    </Box>
                                                )}
                                            </StyledUploaderFilesWrapper>

                                            <Box mt={1} display='flex' gap={'20px'}>
                                                <Button onClick={() => this.closeModal()} size={'small'}>
                                                    Close
                                                </Button>

                                                <Button
                                                    component={'label'}
                                                    onChange={this.handleSelectFiles.bind(this)}
                                                    startIcon={<AddRoundedIcon />}
                                                    size={'small'}
                                                    disabled={this.props.status}
                                                >
                                                    Upload more
                                                    <input multiple type={'file'} hidden />
                                                </Button>
                                            </Box>
                                        </StyledUploaderFieldSelected>
                                    )}
                                </>
                            )}
                        </StyledUploaderModalWrapper>
                    </Box>
                </Modal>
            </>
        );
    }
}

export default UploadFileFormik;