import Box from "@mui/material/Box";
import Button, {ButtonProps} from "@mui/material/Button";
import {useField} from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import {useEffect, useState} from "react";
import {
  selectTransferFile,
  transferFile,
  transferName,
  transferStatus,
  transferViewLink,
} from "features/ecrf/redux/elementResourceTransferSlice";
import {useAppDispatch, useAppSelector} from "commons/store/hooks";
import {useSnackbar} from "notistack";
import {selectUserProfile} from "core/redux/userSlice";
import {roles} from 'commons/auth/roles';

export interface DicomButtonFormikProps
  extends Omit<ButtonProps, "name" | "value" | "onChange" | "onBlur" | "error"> {
  name: string;
  link: string;
  helperText?: string;
  buttonText?: string;
  options: Array<{ key: string; value: string }>;
  extra: string;
  data: any;
  status?: boolean;
}

const DicomButtonFormik = ({
  name,
  link,
  helperText,
  buttonText = "Upload file",
  options,
  extra,
  status,
  data,
  ...props
}: DicomButtonFormikProps) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [field, meta] = useField(name);
  const [isDisabled, setIsDisabled] = useState(status);
  const [text, setText] = useState(buttonText);
  const transferSelector = useAppSelector(selectTransferFile) as any;
  const [fileName, setFileName] = useState("");
  const [isUploaded, setIsUploaded] = useState(false);

  const errorText = "Wystąpił błąd! Spróbuj ponownie";

  /** Block upload file for Data managers */
  const userProfile = useAppSelector(selectUserProfile);
  const disableButton = () => {
    if (data?.viewUrl || (transferSelector?.data?.viewUrl && isUploaded)) return false;
    else {
      if (userProfile.roles.some((role: string) => roles.dataManager.includes(role))) {
        return true;
      }

      return status;
    }
  };

  const redirectToView = async (url: string) => {
    try {
      const link = await dispatch(transferViewLink(url)).unwrap();
      window.open(`${link?.link}`, "_blank");
    } catch (error: any) {
      enqueueSnackbar(errorText, {
        variant: "error",
      });
    }
  };

  const updateTransferName = async (transferUrl: any) => {
    const fileName = await dispatch(transferName(transferUrl)).unwrap();
    setFileName(fileName.data?.fileId);
  };

  const updateTransferFile = async () => {
    return await dispatch(transferFile({elementId: field.name, ecrfId: extra})).unwrap();
  };

  const updateStatus = async (transferUrl: any) => {
    return await dispatch(transferStatus(transferUrl)).unwrap();
  };

  useEffect(() => {
    if (data?.viewUrl || (transferSelector?.data?.viewUrl && isUploaded)) {
      setText("view file");
      setIsDisabled(false);
      updateTransferName(data?.viewUrl);
    }
  }, []);

  const transferNewFile = async () => {
    setIsDisabled(true);
    setText("processing");

    try {
      const transfer = await updateTransferFile();
      const timeout = transfer.data.timeout;
      const timestamp = Date.now();
      let statusName: string | undefined = "";
      let i = 0;
      const status: any = await updateStatus(transfer.data.statusUrl);

      if (status.statusName === "ERROR") {
        let j = 0;
        let tempStatus: string | undefined = "";

        while (Date.now() - timestamp < timeout && tempStatus !== "FINISH") {
          j++;

          const status: any = await updateStatus(transfer.data.statusUrl);

          tempStatus = status.statusName;

          // redirect if error
          if (j === 1 && status.statusName === "ERROR" && transfer.data?.apiUrl) {
            window.open(transfer.data.apiUrl, "_blank");
          }
        }
      }

      while (
        Date.now() - timestamp < timeout &&
        statusName !== "ERROR" &&
        statusName !== "FINISH"
      ) {
        i++;
        try {
          const status: any = await updateStatus(transfer.data.statusUrl);

          // check status error
          if ((statusName = status.statusName) === "ERROR") {
            enqueueSnackbar(errorText, {
              variant: "error",
            });
            setIsDisabled(false);
            updateTransferFile();
          }

          // redirect if added new transfer record
          if (i === 1 && statusName === "NEW" && transfer.data?.apiUrl) {
            window.open(transfer.data.apiUrl, "_blank");
          }
        } catch (error: any) {
          enqueueSnackbar(errorText, {
            variant: "error",
          });
          statusName = "ERROR";
        }
      }

      if (statusName === "FINISH") {
        const updatedTransfer = await updateTransferFile();
        setIsDisabled(false);
        await updateTransferName(updatedTransfer.data.viewUrl);
        setText("View file");
        setIsUploaded(true);
        enqueueSnackbar("Zadanie zostało zlecone.", {
          variant: "success",
        });
      } else {
        setText("Upload file");
      }
    } catch (error: any) {
      enqueueSnackbar(errorText, {
        variant: "error",
      });
    }
  };

  const handleClick = async () => {
    if (data?.viewUrl || (transferSelector?.data?.viewUrl && isUploaded)) {
      await redirectToView(data?.viewUrl ? data?.viewUrl : transferSelector?.data?.viewUrl);
    } else {
      await transferNewFile();
    }
  };

  return (
    <Box pt={3}>
      {((transferSelector?.data?.viewUrl && isUploaded) || data?.viewUrl) && (
        <Box pb={1}>File ID: {fileName}</Box>
      )}
      <Button
        name={field.name}
        id={field.name}
        value={field.value}
        variant="outlined"
        onClick={handleClick}
        disabled={isDisabled || disableButton()}
        {...props}
      >
        {field.value}
        {text}
      </Button>
      <FormHelperText>{(meta.touched && meta.error) || helperText}</FormHelperText>
    </Box>
  );
};

export default DicomButtonFormik;
