import { OptionsObject, SnackbarKey, useSnackbar } from 'notistack';
import React from 'react';

interface WithSnackbarProps {
    enqueueSnackbar: (message: string, options?: OptionsObject) => SnackbarKey;
}

export function withSnackbar<T extends WithSnackbarProps>(Component: React.ComponentType<T>) {
    return function WrappedComponent(props: Omit<T, keyof WithSnackbarProps>) {
        const snackbar = useSnackbar();

        return <Component {...(props as T)} enqueueSnackbar={snackbar.enqueueSnackbar} />;
    };
}
