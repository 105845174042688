import { Box, Button, CircularProgress } from "@mui/material";
import DashboardLayoutWrapper from "commons/wrappers/DashboardLayoutWrapper";
import DashboardEcrves from 'features/dashboard/components/DashboardEcrves';
import DashboardQueries from 'features/dashboard/components/DashboardQueries';
import DashboardAdverseEvents from 'features/dashboard/components/DashboardAdverseEvents';
import DashboardProvide from 'features/dashboard/components/DashboardProvide';
import DashboardStats from 'features/dashboard/components/DashboardStats';
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { styled } from '@mui/system';
import React, {useState, useEffect, useCallback} from "react";
import {ROUTES_ECRF} from "commons/constants/paths";
import { useNavigate } from "react-router-dom";
import {useAppDispatch, useAppSelector} from "commons/store/hooks";
import {addEcrf} from "features/dashboard/redux/dashboardSlice";
import {selectUserProfile} from "core/redux/userSlice";
import AddIcon from '@mui/icons-material/Add';
import {
    COLOR_BG_NAV,
    COLOR_PRIMARY_LIGHT,
    DISPLAY_PROVIDE,
    PROJECT_NAME
} from "commons/constants/env";
import { useParams } from 'react-router-dom';
import ConfirmationAction from "components/ConfirmationAction";
import { roles } from 'commons/auth/roles';
import { useSnackbar } from "notistack";
import DashboardDataManagement from "../../components/DashboardDataManagement";

const CustomToggleButton = styled(ToggleButton)(({ theme }) => `
  color: ${theme.palette.secondary.main};
  padding: 6px 16px;
  font-size: 0.875rem;
  border-color: ${theme.palette.primary.light};

  &:hover {
    color: ${theme.palette.primary.dark};
    background: ${COLOR_BG_NAV};
    border-color: ${COLOR_BG_NAV};
  }

  &.Mui-selected {
    color: ${theme.palette.primary.main};
    background: ${theme.palette.primary.light};

    &:hover {
      color: ${theme.palette.primary.dark};
      background: ${COLOR_PRIMARY_LIGHT};
    }
  }
`);

const Dashboard = () => {
    const dispatch          = useAppDispatch();
    const navigate          = useNavigate();
    const {type}            = useParams<{ type: string }>();
    const {enqueueSnackbar} = useSnackbar();
    const userProfile       = useAppSelector(selectUserProfile);

    let activeProvide: boolean  = userProfile.roles.some((role: string) => roles.pharmacist.includes(role));
    let displayProvide: boolean = (DISPLAY_PROVIDE && DISPLAY_PROVIDE !== 'FALSE')
        && userProfile.roles.some(
            (role: string) => (
                roles.admin.includes(role) ||
                roles.pharmacist.includes(role) ||
                roles.monitor.includes(role)
            ) && role !== 'ROLE_SPONSOR'
        );
    let displayDashboards: boolean = userProfile.roles.some(
        (role: string) => roles.admin.includes(role) || roles.research.includes(role)
    );
    let displayAddEcrf: boolean    = userProfile.roles.some(
        (role: string) => roles.admin.includes(role) || roles.researcher.includes(role)
    );
    let displayDM: boolean  = userProfile.roles.some(
        (role: string) => (
            roles.admin.includes(role) ||
            roles.dataManager.includes(role)
        )
    );
    let displayAE: boolean  = userProfile.roles.some(
        (role: string) => (
            roles.admin.includes(role) ||
            roles.monitor.includes(role) ||
            roles.researcher.includes(role)
        )
    );
    let displayQueries: boolean = userProfile.roles.some(
        (role: string) => (
            roles.admin.includes(role) ||
            roles.monitor.includes(role) ||
            roles.research.includes(role)
        )
    );
    let displayStats: boolean = userProfile.roles.some(
        (role: string) => roles.admin.includes(role) || roles.monitor.includes(role)
    );

    const [activeSection, setActiveSection] = useState<
        'ECRVS' | 'QUERIES' | 'DATA_MANAGEMENT' | 'ADVERSE_EVENTS' | 'PROVIDE_DRUG' | 'STATS'
        >(activeProvide ? 'PROVIDE_DRUG' : 'ECRVS');

    /**
     * This function is used to create new ecrf
     */
    const [isDataLoading, setIsDataLoading] = useState(false)
    const handleCreateEcrf = async () => {
        setIsDataLoading(true)

        try {
            const ecrf = await dispatch(addEcrf()).unwrap();
            if (ecrf.id) {
                navigate(ROUTES_ECRF.DETAILS(ecrf.id));
            }
        } catch (error: any) {
            enqueueSnackbar(error?.detail, {variant: 'error'});
        }

        setIsDataLoading(false)
    };

    /**
     * This function is used to control actual visible dashboard change if route is changed
     */
    const activeSectionController = () => {
        switch (type) {
            case 'ecrves': {
                if (displayDashboards) {
                    setActiveSection('ECRVS');
                } else {
                    setActiveSection('PROVIDE_DRUG');
                    navigate(`/dashboard/provide`)
                }
                break;
            }
            case 'queries': {
                if (displayDashboards) {
                    setActiveSection('QUERIES');
                } else {
                    setActiveSection('PROVIDE_DRUG');
                    navigate(`/dashboard/provide`)
                }
                break;
            }
            case 'data-management': {
                if (displayDashboards && displayDM) {
                    setActiveSection('DATA_MANAGEMENT');
                    navigate(`/dashboard/data-management`)
                } else {
                    setActiveSection('PROVIDE_DRUG');
                    navigate(`/dashboard/provide`)
                }
                break;
            }
            case 'adverse-events': {
                if (displayDashboards) {
                    setActiveSection('ADVERSE_EVENTS');
                } else {
                    setActiveSection('PROVIDE_DRUG');
                    navigate(`/dashboard/provide`)
                }
                break;
            }
            case 'provide': {
                if (displayProvide) {
                    setActiveSection('PROVIDE_DRUG');
                } else {
                    setActiveSection('ECRVS');
                    navigate(`/dashboard/ecrves`)
                }
                break;
            }
            case 'statistics': {
                setActiveSection('STATS');
                break;
            }
            default: {
                if (displayDashboards) {
                    setActiveSection('ECRVS');
                    navigate(`/dashboard/ecrves`)
                } else {
                    setActiveSection('PROVIDE_DRUG');
                    navigate(`/dashboard/provide`)
                }
                break;
            }
        }
    }

    /**
     * This function is used to change actual section, also will change route
     * @param route
     * @param section
     */
    const changeDashboardTab = (
        route: string,
        section: 'ECRVS' | 'QUERIES' | 'DATA_MANAGEMENT' | 'ADVERSE_EVENTS' | 'PROVIDE_DRUG' | 'STATS'
    ) => {
        setActiveSection(section)
        navigate(`/dashboard/${route}`)
    }

    useEffect(() => {
        document.title = `${PROJECT_NAME} - Dashboard`;
        activeSectionController();

        return () => {
            document.title = PROJECT_NAME;
        };
    }, [dispatch]);

    const renderContent = useCallback(() => {
        switch (activeSection) {
            case 'ECRVS':
                return (<DashboardEcrves/>);
            case 'QUERIES':
                return (<DashboardQueries/>);
            case 'DATA_MANAGEMENT':
                return (<DashboardDataManagement/>);
            case 'ADVERSE_EVENTS':
                return (<DashboardAdverseEvents/>);
            case 'PROVIDE_DRUG':
                return (<DashboardProvide/>);
            case 'STATS':
                return (<DashboardStats/>);
        }
    }, [activeSection]);

    return (
        <DashboardLayoutWrapper>
            <Box mb={1} display="flex" justifyContent="space-between">
                <ToggleButtonGroup
                    size="small"
                    color="secondary"
                    aria-label="outlined primary button group"
                >
                    {displayDashboards && (
                        <CustomToggleButton
                            selected={activeSection === 'ECRVS'}
                            onClick={() => changeDashboardTab('ecrves', 'ECRVS')}
                            value={activeSection}
                        >
                            Uczestnicy badania
                        </CustomToggleButton>
                    )}

                    {(displayDashboards && displayQueries) && (
                        <CustomToggleButton
                            selected={activeSection === 'QUERIES'}
                            onClick={() => changeDashboardTab('queries', 'QUERIES')}
                            value={activeSection}
                        >
                            Queries
                        </CustomToggleButton>
                    )}

                    {(displayDashboards && displayDM) && (
                        <CustomToggleButton
                            selected={activeSection === 'DATA_MANAGEMENT'}
                            onClick={() => changeDashboardTab('data-management', 'DATA_MANAGEMENT')}
                            value={activeSection}
                        >
                            Data management
                        </CustomToggleButton>
                    )}

                    {(displayDashboards && displayAE) && (
                        <CustomToggleButton
                            selected={activeSection === 'ADVERSE_EVENTS'}
                            onClick={() => changeDashboardTab('adverse-events', 'ADVERSE_EVENTS')}
                            value={activeSection}
                        >
                            Zdarzenia niepożądane
                        </CustomToggleButton>
                    )}

                    {displayProvide && (
                        <CustomToggleButton
                            selected={activeSection === 'PROVIDE_DRUG'}
                            onClick={() => changeDashboardTab('provide', 'PROVIDE_DRUG')}
                            value={activeSection}
                        >
                            Wydawanie leków
                        </CustomToggleButton>
                    )}

                    {displayStats && (
                        <CustomToggleButton
                            selected={activeSection === 'STATS'}
                            onClick={() => changeDashboardTab('statistics', 'STATS')}
                            value={activeSection}
                        >
                            Statystyki
                        </CustomToggleButton>
                    )}
                </ToggleButtonGroup>

                {activeSection === 'ECRVS' && displayAddEcrf && (
                    <ConfirmationAction
                        isProcessing={isDataLoading}
                        onClick={handleCreateEcrf}
                        confirmationTitle={"Czy na pewno chcesz dodać nowego uczestnika badania?"}
                    >
                        {({handleClick}) => (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleClick}
                                startIcon={isDataLoading ? <CircularProgress size={16} /> : <AddIcon/>}
                                disabled={isDataLoading}
                            >
                                DODAJ UCZESTNIKA BADANIA
                            </Button>
                        )}
                    </ConfirmationAction>
                )}

            </Box>
            {renderContent()}
        </DashboardLayoutWrapper>
    );
};

export default Dashboard;