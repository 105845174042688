import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import {
  fetchEcrf,
  fetchEcrfElements,
  fetchElement,
  fetchElementDataCollection,
  fetchElementDatas,
  postElementData,
  postElementDataCollection,
  postElementDataCollections,
  putCollectElementData,
  putElementData,
  putElementDataCollection,
  selectElementDataCollection,
  selectElementDatas
} from "features/ecrf/redux/ecrfSlice";
import { useEffect, useState } from "react";
import ModalFormContent from "./ModalFormContent";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import axiosSecureInstance from "../../../commons/axios/axiosSecureInstance";
import getInitialValues from "../../../features/ecrf/utils/getInitialValues";

type ModalFormFieldProps = {
  handleClose: () => void;
  collection: any;
  title: string
}

const ModalFormField = ({
  handleClose,
  collection,
  title
}: ModalFormFieldProps) =>{
  const dispatch = useAppDispatch();
  const elementData = useAppSelector(selectElementDatas)
  const elementDataCollection = useAppSelector(selectElementDataCollection)
  const { enqueueSnackbar} = useSnackbar();

  const {id, section} = useParams<{id: string, section: string}>();
  const [isLoading, setLoading] = useState(true)
  const [isSaving, setSaving] = useState(false)
  const [elements, setElements] = useState<any>([]);
  const [ed, setEd] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [dataLoading, setDataLoading] = useState('Ładowanie danych...')
  const [collectionId, setCollectionId] = useState(null)

  const fetch = async () => {
    setLoading(true)

    // check children elements and add if exists
    if (collection?.children && collection?.children?.length > 0) {
      setElements(collection.children)
    } else if (collection?.elementId) {
      const dataElement = await dispatch(fetchElement(collection?.elementId)).unwrap()
      dataElement && setElements(dataElement.children)
    }

    switch (collection?.type) {
      case 'add':
      case 'created':
        if (!collection.options?.autosave) {
          break;
        }

        let dataId = ed?.id || collection.dataId;
        let postEd = ed;

        if (!dataId) {
          postEd = await dispatch(postElementData({
            ecrf: `/api/ecrves/${collection?.ecrfId}`,
            element: `/api/elements/${collection?.elementId}`
          })).unwrap()

          postEd?.id && setEd(postEd)
          // postEd?.id && await dispatch(fetchElementDatas(postEd?.id))

          dataId = postEd?.id;
        }

        if (!collection.id && dataId) {
          let bindData: any = collection.children.map(
              (element: any) => {
                return {
                  ecrf: `/api/ecrves/${collection?.ecrfId || id}`,
                  element: `/api/elements/${element?.id}`,
                  data: [],
                }
              }
          )

          const col = await dispatch(postElementDataCollections({
            datas: [{id: `/api/element_datas/${dataId}`}],
            elements: bindData
          })).unwrap()

          col && setCollectionId(col?.id)
          col && await dispatch(fetchElementDataCollection(col.id)).unwrap()
          await dispatch(fetchElementDatas(dataId))
        }
        break;
      case 'edit':
      case 'duplicate':
      case 'save':
        if (['button'].includes(collection?.elementType)) {
          const ed = await dispatch(fetchElementDatas(collection.dataId)).unwrap()
          setData(ed?.children)
        } else if (collection.id) {
          setData([])
          const edc = await dispatch(fetchElementDataCollection(collection.id)).unwrap()
          setData(edc?.elements)
        }
        break;
    }

    setLoading(false)
  }

  const reloadData = async () => {
    setData([])
    setData(elementDataCollection?.elements)
  }

  useEffect(() => {
    fetch().then()
  }, [collection])

  useEffect(() => {
    reloadData().then()
  }, [elementDataCollection])

  // const getInitialValues = (els: any, datas: any) => {
  //   const initialValues: any = {}
  //
  //   if (els) {
  //     for (let i = 0; i < els.length; i++) {
  //       const data = datas?.find((el :any)=> el.elementName === els[i].name)
  //
  //       initialValues[els[i].id] = (['checkbox_multiple', 'select_multiple'].includes(els[i].type))
  //           ? data?.data || []
  //           : data?.data[0] || ''
  //     }
  //   }
  //
  //   return initialValues
  // }

  const initialValues = getInitialValues(elements, data || []);

  const handleSubmit = async (values: any) => {
    try {
      const dataId       = collection?.dataId
      const collectionId = collection?.id
      const elementId    = collection?.elementId
      const ecrfId       = collection?.ecrfId

      const collectionElements: any[] = []
      let ed: any;

      const excludeElements = elements?.filter(
          (element: any) => ['table'].includes(element.type)
      );

      // filter element values
      let valueElements = Object.entries(values).filter(
          (element: any) => !excludeElements?.find((exclude :any) => element[0] === exclude?.id)
      )

      let bindData: any = valueElements.map(
          (element: any) => {
            return {
              ecrf: `/api/ecrves/${ecrfId}`,
              element: `/api/elements/${element[0]}`,
              data: Array.isArray(element[1]) ? element[1] : [element[1]],
            }
          }
      )

      switch (collection?.type) {
        case 'add':
        case 'created':
          if (dataId) {
            collectionElements.push({elements: bindData})

            ed = await dispatch(putElementDataCollection({dataId, collections: collectionElements})).unwrap()
          } else {
            ed = await dispatch(postElementDataCollection({elementId, ecrfId, elements: bindData})).unwrap()
          }
          break;
        case 'clone':
        case 'duplicate':
          collectionElements.push({elements: bindData})

          ed = await dispatch(putElementDataCollection({dataId, collections: collectionElements})).unwrap()
          break;
        case 'edit':
          if (collectionId) {
            if (data?.length > 0) {
              bindData = valueElements.map(
                  (element: any) => {
                    if (data?.length > 0) {
                      const elementData = data?.find(
                          (ed: any) => ed?.ecrf?.id === ecrfId && ed?.element?.id === element[0]
                      );

                      if (elementData) {
                        return {
                          id: `/api/element_datas/${elementData.id}`,
                          data: Array.isArray(element[1]) ? element[1] : [element[1]],
                        }
                      }
                    }

                    return {
                      ecrf: `/api/ecrves/${ecrfId}`,
                      element: `/api/elements/${element[0]}`,
                      data: Array.isArray(element[1]) ? element[1] : [element[1]],
                    }
                  },
              )
            }

            await dispatch(putCollectElementData({id: collectionId, elements: bindData})).unwrap()
          }
          break;
        case 'save':
          // collect data and save element data collection
          if (['button'].includes(collection?.elementType || '')) {
            let collections: any[] = []
            if (dataId) {
              if (data?.length > 0) {
                collections = data?.map((el: any) => {
                  if (values[el?.element?.id]) {
                    return {
                      id: `/api/element_datas/${el.id}`,
                      data: [values[el?.element?.id]],
                    }
                  }

                  return {
                    id: `/api/element_datas/${el.id}`,
                    data: el?.data,
                  }
                })
              } else {
                collections = bindData
              }

              ed = await axiosSecureInstance.put(`/api/element_datas/${dataId}`, {
                children: collections
              });
            } else {
              collections = bindData

              ed = await axiosSecureInstance.post(`/api/element_datas`, {
                ecrf: `/api/ecrves/${elementData?.ecrf?.id || ecrfId}`,
                element: `/api/elements/${elementData?.element?.id || elementId}`,
                children: collections
              });
            }
          } else {
            ed = await dispatch(putElementData({elementId, ecrfId, data: [values[elementId]]})).unwrap()
          }
          break;
      }

      // refresh data
      if ((elementData?.ecrf?.id || ecrfId) && section) {
        await dispatch(fetchEcrfElements({id: elementData?.ecrf?.id || ecrfId, sectionId: section}));
      } else if (elementData?.ecrf?.id || ecrfId) {
        await dispatch(fetchEcrf(elementData?.ecrf?.id || ecrfId));
      }

      // close modal
      handleClose()

      enqueueSnackbar(`Saved ${new Date().toLocaleTimeString()}`, {variant: 'info'});
    } catch (error: any) {
      let message = error?.message

      if (error.response.data && error.response.data?.detail) {
        message = error.response.data
      } else if (error?.detail && error?.violations) {
        message = error
      }

      enqueueSnackbar(message?.detail || error?.message, {variant: message?.violations ? 'warning' : 'error'});
    }

    setSaving(false)
  }

  return (
    <ModalFormContent
      id={collectionId}
      elements={elements}
      elementsData={ed}
      elementsDatas={data}
      isLoading={isLoading}
      dataLoading={dataLoading}
      isSaving={isSaving}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      ecrfId={collection?.ecrfId}
      initialValues={initialValues}
      title={title}
      submitText={(collection?.status > 0
          ? collection?.options?.buttonDisabledText
          : collection?.options?.buttonText
      ) ?? `${collection.type} RECORD`}
    />
  )
}

export default ModalFormField