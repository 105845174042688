/**
 * Dictionary statuses
 * list: wolny - przypisany - wysłany - otrzymany - uszkodzony` - wydany`
 */

export const statusDictionary: string[] = [
    'Wolny',
    'Przypisany',
    'Wysłany',
    'Otrzymany',
    'Uszkodzony',
    'Wydany'
]

export const statusDescriptions: string[] = [
    'Lek nie został jeszcze przypisany do żadnego ośrodka',
    'Lek został przypisany do ośrodka',
    'Lek został wysłany do ośrodka',
    'Potwierdzono otrzymanie leku przez ośrodek',
    'Zgłoszono uszkodzenie leku',
    'Lek został wydany',
]