import React, {Component} from 'react';
import {
    StyledUploaderFilesItem,
    StyledUploaderFilesItemSize,
    StyledUploaderFilesItemText
} from "./UploadFileFormik.styled";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import {CircularProgress, Tooltip} from "@mui/material";
import {connect} from "react-redux";
import axiosSecureInstance from "../../../commons/axios/axiosSecureInstance";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

interface IFileItem {
    name: string,
    size: string,
    link?: string,
    id: string,
    userProfile?: any;
    isUploading?: boolean,
    removeRow?: (id: string) => void;
    update?: (id: string) => void;
    dataId?: string;
}

class FileItem extends Component<IFileItem, any> {
    /**
     * This method is used to convert
     * file size to human units
     * @param bytes
     * @param decimals
     */
    public static formatBytes(bytes: any, decimals = 2) {

        if (bytes === null) return 'unknown'

        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    private async deleteFile(id: string) {
        if (this.props.userProfile.role === 'ROLE_ADMIN') {
            await axiosSecureInstance.delete(`/api/element_resource_transfers/${id}`)
            if (this.props.update) this.props.update(this.props.dataId || '-')
        }
    }

    render() {
        return (
            <StyledUploaderFilesItem isAdmin={this.props.userProfile.role === 'ROLE_ADMIN'}>
                <InsertDriveFileOutlinedIcon/>

                <Tooltip title={this.props.name} arrow>
                    <StyledUploaderFilesItemText>
                        {this.props?.name || 'name'}
                    </StyledUploaderFilesItemText>
                </Tooltip>

                <StyledUploaderFilesItemSize>
                    {FileItem.formatBytes(this.props?.size) || 'size'}
                </StyledUploaderFilesItemSize>

                {this.props.isUploading && (
                    <CircularProgress size={22}/>
                )}

                {this.props.link && this.props.userProfile.role === "ROLE_ADMIN" && (
                    <Tooltip title={'DELETE FILE (ONLY ADMIN)'} arrow>
                        <StyledUploaderFilesItemSize onClick={() => this.deleteFile(this.props.id)}
                            style={{cursor: 'pointer'}}>
                            <DeleteRoundedIcon style={{fontSize: '15px'}}/>
                        </StyledUploaderFilesItemSize>
                    </Tooltip>
                )}

            </StyledUploaderFilesItem>
        );
    }
}

/**
 * This function is used to get
 * user profile data from Redux
 * @param state
 */
const mapStateToProps = (state: any) => ({
    userProfile: state.core.user.userProfile
})

export default connect(mapStateToProps, null)(FileItem)