/*
 * Project: ecrf-front
 * Author: Dominik Obłoza
 * User: @dominik.obloza
 * Date: 14.01.2022
 * Time: 14:10
 */

import {Box, Button, CircularProgress, Menu, MenuItem, Tooltip, Typography} from '@mui/material';
import React, {Component} from 'react';
import {StyledDocumentationTableRow} from '../views/Documentation.styled';
import {IDocManagerData} from "../services/documentation-service/AbstractResources/DocManager";
import EventStore from "../../../core/modules/event-store";
import DateTime from "../../../core/helpers/date/DateTime";
import ConfirmationAction from "../../../components/ConfirmationAction";
import { COLOR_PRIMARY, DATETIME_FORMAT, RESEARCH } from "../../../commons/constants/env";
import FileStatus from "../components/FileStatus";
import styled from "styled-components";
import {MoreVert} from "@mui/icons-material";
import DocumentationService from "../services/documentation-service";
import {fetchFile} from "../redux/fileSlice";
import {connect} from "react-redux";
import { roles } from 'commons/auth/roles';

const MenuStyled = styled(Menu)`
  .MuiPaper-elevation8 {
    box-shadow: rgba(50, 50, 93, 0) 0 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0 1px 3px -1px;
    margin-top: 1px;
  }
`

const TitleStyled = styled.span`
  font-weight: bold;
  overflow-wrap: anywhere;
  padding: 10px 15px 0;
`

const InfoStyled = styled.span`
  padding: 2px 0;
`

const InfoTitleStyled = styled.span`
    font-weight: bold;
`


export abstract class FileItemEvents {
    public static ROW_ITEM_CHANGE_STATUS_EVENT = 'RowItem::command:status';
    public static ROW_ITEM_DOWNLOAD_EVENT = 'RowItem::command:download';
    public static ROW_ITEM_DELETE_EVENT = 'RowItem::command:delete';
}

class FileItem extends Component<IDocManagerData, any> {
    /**
     * @DocumentationService object
     * @private
     */
    private readonly docService: DocumentationService;

    /**
     * EventStore object
     * @private
     */
    private readonly eventStore: EventStore;

    state = {
        anchorStatusEl: null,
        anchorActionEl: null,
        openStatus: false,
        openAction: false,
        id: undefined,
        isDataLoading: false,
        statusName: '',
        statuses: [
            'draft',
            'approved',
            'opened',
            'outdated',
        ],
        currentStatuses: [],
        // actions: [
        //     'download',
        //     'history',
        //     'query',
        //     'delete',
        // ],
        actions: [],
    }

    constructor(props: IDocManagerData) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.eventStore = new EventStore();
        this.docService = new DocumentationService();

        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.onDelete       = this.onDelete.bind(this);
        this.onDownload     = this.onDownload.bind(this);
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps: any){
        if (prevProps !== this.props) {
            this.init();
        }
    }

    /**
     * Init
     */
    init() {
        if (this.props.status && !this.state.statuses[this.props.status]) {
            return;
        }

        switch (RESEARCH) {
            case 'IMPROVE': {
                if (this.props.userProfile.roles.some(
                    (role: string) => ['ROLE_CENTRAL_ASSESSMENT'].includes(role)
                )) {
                    this.setState({
                        currentStatuses: [],
                        actions: [
                            'download',
                            'delete',
                        ],
                    });
                } else {
                    this.setState({
                        currentStatuses: [],
                        actions: [
                            'download'
                        ],
                    });
                }
                return;
            }
        }

        if (this.props.userProfile.roles.some(
            (role: string) => roles.admin.includes(role) || roles.control.includes(role)
        )) {
            switch (this.state.statuses[this.props.status]) {
                case 'draft': {
                    this.setState({
                        currentStatuses: [
                            'approved',
                            'opened',
                            'outdated',
                        ],
                        actions: [
                            'download',
                            'history',
                            'delete',
                        ],
                    });
                    break;
                }
                case 'approved': {
                    this.setState({
                        currentStatuses: [
                            'opened',
                            'outdated',
                        ],
                        actions: [
                            'download',
                            'history',
                            'delete',
                        ],
                    });
                    break;
                }
                case 'opened': {
                    this.setState({
                        currentStatuses: [
                            'approved',
                            'outdated',
                        ],
                        actions: [
                            'download',
                            'history',
                            'query',
                            'delete',
                        ],
                    });
                    break;
                }
                case 'outdated': {
                    this.setState({
                        currentStatuses: [
                            'opened',
                        ],
                        actions: [
                            'download',
                            'history',
                            'delete',
                        ],
                    });
                    break;
                }
            }
        } else {
            switch (this.state.statuses[this.props.status]) {
                case 'draft': {
                    this.setState({
                        currentStatuses: [],
                        actions: [
                            'download',
                            'history',
                            'delete',
                        ],
                    });
                    break;
                }
                case 'approved': {
                    this.setState({
                        currentStatuses: [],
                        actions: [
                            'download',
                            'history',
                        ],
                    });
                    break;
                }
                case 'opened': {
                    this.setState({
                        currentStatuses: [],
                        actions: [
                            'download',
                            'history',
                            'delete',
                        ],
                    });
                    break;
                }
                case 'outdated': {
                    this.setState({
                        currentStatuses: [],
                        actions: [
                            'download',
                            'history',
                        ],
                    });
                    break;
                }
            }
        }
    }

    /**
     * Handle click
     * @param event
     * @param type
     */
    handleClick(event: any, type?: 'action' | 'status') {
        switch (type) {
            case 'action':
                this.setState({
                    anchorActionEl: event?.currentTarget,
                    openAction: Boolean(event.currentTarget),
                    id: 'simple-popover'
                });
                break;
            case 'status':
            default:
                this.setState({
                    anchorStatusEl: event?.currentTarget,
                    openStatus: Boolean(event.currentTarget),
                    id: 'simple-popover'
                });
        }
    }

    /**
     * Handle close
     * @param event
     */
    handleClose(event?: any) {
        this.setState({
            anchorActionEl: null, openAction: false, id: undefined,
            anchorStatusEl: null, openStatus: false
        });
    }

    /**
     * Handle change status
     * @param status
     */
    async handleChangeStatus(status: any) {
        this.setState({isDataLoading: true});

        try {
            this.handleClose()
            this.onChangeStatus(status)
        } catch (err: any) {
            alert(err?.details || err?.message)
        }

        this.setState({isDataLoading: false});
    }

    /**
     * Handle history
     * @param file
     */
    async handleHistory(file: any) {
        this.setState({isDataLoading: true});

        try {
            this.handleClose()
            this.props.onHistoryModal(file)
        } catch (err: any) {
            alert(err?.details || err?.message)
        }

        this.setState({isDataLoading: false});
    }

    /**
     * Handle query
     * @param file
     */
    async handleQuery(file: any) {
        this.setState({isDataLoading: true});

        try {
            this.handleClose()
            this.props.onQueryPanel(file)
        } catch (err: any) {
            alert(err?.details || err?.message)
        }

        this.setState({isDataLoading: false});
    }

    private onChangeStatus(status: any): void {
        this.eventStore.emit(FileItemEvents.ROW_ITEM_CHANGE_STATUS_EVENT, {...this.props, statusName: status})
    }

    private onDelete(): void {
        this.handleClose()
        this.eventStore.emit(FileItemEvents.ROW_ITEM_DELETE_EVENT, {...this.props})
    }

    private onDownload(): void {
        this.handleClose()
        this.eventStore.emit(FileItemEvents.ROW_ITEM_DOWNLOAD_EVENT, {...this.props})
    }

    renderMenu(element: string) {
        switch (element) {
            case 'download': {
                return (
                    <MenuItem onClick={this.onDownload}>
                        <Typography variant="body2">Download</Typography>
                    </MenuItem>
                )
            }
            case 'history': {
                return (
                    <MenuItem onClick={() => this.handleHistory(this.props)}>
                        <Typography variant="body2">History</Typography>
                    </MenuItem>
                )
            }
            case 'query': {
                return (
                    <MenuItem onClick={() => this.handleQuery(this.props)}>
                        <Typography variant="body2">Queries</Typography>
                    </MenuItem>
                )
            }
            case 'delete': {
                return (
                    <ConfirmationAction
                        confirmationTitle={"Czy na pewno chcesz usunąć ten plik?"}
                        onClick={this.onDelete}
                    >
                        {({handleClick}) => (
                            <MenuItem onClick={handleClick}>
                                <Typography variant="body2">Delete</Typography>
                            </MenuItem>
                        )}
                    </ConfirmationAction>
                )
            }
        }
    }

    render() {
        const anchorStatusEl = this.state.anchorStatusEl
        const anchorActionEl = this.state.anchorActionEl
        const openStatus     = Boolean(anchorStatusEl);
        const openAction     = Boolean(anchorActionEl);

        return (
            <StyledDocumentationTableRow>
                <div style={{display: 'flex', paddingLeft: '20px'}}>
                    <svg width="61" height="78" viewBox="0 0 61 78" fill="none" xmlns="http://www.w3.org/2000/svg" style={{minWidth:"61px"}}>
                        <path d="M59.621 20.379L40.121 0.879C39.557 0.315 38.795 0 38 0H6.5C3.185 0 0.5 2.685 0.5 6V72C0.5 75.315 3.185 78 6.5 78H54.5C57.815 78 60.5 75.315 60.5 72V22.5C60.5 21.705 60.185 20.943 59.621 20.379ZM39.5 57H15.5C13.844 57 12.5 55.656 12.5 54C12.5 52.344 13.844 51 15.5 51H39.5C41.156 51 42.5 52.344 42.5 54C42.5 55.656 41.156 57 39.5 57ZM45.5 45H15.5C13.844 45 12.5 43.656 12.5 42C12.5 40.344 13.844 39 15.5 39H45.5C47.156 39 48.5 40.344 48.5 42C48.5 43.656 47.156 45 45.5 45ZM39.5 24C37.844 24 36.5 22.656 36.5 21V5.712L54.788 24H39.5Z" fill={COLOR_PRIMARY}/>
                    </svg>
                    <TitleStyled>{this.props.name}</TitleStyled>
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <InfoStyled><InfoTitleStyled>Wersja:</InfoTitleStyled> {`${this.props.version ? this.props.version : '--'}`}</InfoStyled>
                    <InfoStyled><InfoTitleStyled>Dodano:</InfoTitleStyled> {`${DateTime.toIso(this.props.createdAt, DATETIME_FORMAT)}`}</InfoStyled>
                    <InfoStyled><InfoTitleStyled>Rozmiar:</InfoTitleStyled> {`${this.props.size}`} </InfoStyled>
                    {this.state.currentStatuses.length > 0 && <InfoStyled><InfoTitleStyled>Status:</InfoTitleStyled> <FileStatus variant={this.props.status}/></InfoStyled>}
                </div>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    {this.state.currentStatuses.length > 0 &&
                        <>
                            <Tooltip title="Zmień status" arrow>
                                <span>
                                    <Button
                                        id={"status-button-" + this.props.id}
                                        aria-controls="status-menu"
                                        aria-haspopup="true"
                                        aria-expanded={openStatus ? 'true' : undefined}
                                        size="small"
                                        variant={'outlined'}
                                        color="primary"
                                        disabled={this.state.isDataLoading}
                                        style={{padding: '4px 30px', width: "130px"}}
                                        onClick={(event: any) => this.handleClick(event)}
                                        startIcon={this.state.isDataLoading
                                            ? <CircularProgress color={"inherit"} size={14}/>
                                            : null}
                                    >
                                        <Box whiteSpace="nowrap">{this.state.statuses[this.props.status]}</Box>
                                    </Button>
                                </span>
                            </Tooltip>
                        <MenuStyled
                            id={"status-menu" + this.props.id}
                            anchorEl={anchorStatusEl}
                            open={openStatus}
                            onClose={this.handleClose}
                            MenuListProps={{'aria-labelledby': 'status-button' + this.props.id}}
                            style={{boxShadow: 'none'}}
                            >
                            {this.state.currentStatuses.map((label: any, value: any) =>
                                <MenuItem onClick={() => this.handleChangeStatus(label)} key={label + value} value={label}>
                                    {label}
                                </MenuItem>
                            )}
                        </MenuStyled>
                        </>
                    }

                    <Button
                        id={"action-button" + this.props.id}
                        aria-controls="action-menu"
                        aria-haspopup="true"
                        aria-expanded={openAction ? 'true' : undefined}
                        onClick={(event: any) => this.handleClick(event, 'action')}
                        variant="outlined"
                        color="primary"
                        size="small"
                        disabled={this.state.isDataLoading}
                    >
                        {this.state.isDataLoading
                            ? <CircularProgress color={"inherit"} size={14}/>
                            : <MoreVert/>}
                    </Button>
                    <MenuStyled
                        id={"action-menu" + this.props.id}
                        anchorEl={anchorActionEl}
                        open={openAction}
                        onClose={this.handleClose}
                        MenuListProps={{'aria-labelledby': 'action-button' + this.props.id}}
                        style={{boxShadow: 'none'}}
                    >
                        {[].slice.call(this.state.actions).map((element: string) =>
                            this.renderMenu(element)
                        )}
                    </MenuStyled>
                </Box>
            </StyledDocumentationTableRow>
        );
    }
}

const mapStateToProps = (state: any) => ({
    userProfile: state.core.user.userProfile,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchFile: (fileId: string) => dispatch(fetchFile(fileId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (FileItem)