import {useAppDispatch, useAppSelector} from "commons/store/hooks";
import ColoredBox from "components/ColoredBox";
import Table from "components/Table";
import React, {useEffect, useState} from "react";
import {
    fetchCollection,
    fetchElementDataManagement,
    fetchVisits,
    getSectionId,
    selectEcrf
} from "features/ecrf/redux/ecrfSlice";
import {useSnackbar} from "notistack";
import {Link} from "react-router-dom";
import {ROUTES_ECRF} from "commons/constants/paths";
import {CallMade, DeleteSweepRounded, FilterList, MoreVert} from "@mui/icons-material";
import {Box, Button, Collapse, Menu, MenuItem, TextField, Tooltip, Typography} from "@mui/material";
import styled from "styled-components";
import ModalForm from "components/Form/Modal/ModalForm";
import {StyledGlobalMedicalCentersFiltersButton} from "../DashboardStats/DashboardStats.styled";
import DateTime from "core/helpers/date/DateTime";
import {DATETIME_FORMAT} from "commons/constants/env";

const MenuStyled = styled(Menu)`
  .MuiPaper-elevation8 {
    box-shadow: rgba(50, 50, 93, 0) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    margin-top: 45px;
    margin-left: -20px;  
  }
`

const DashboardDataManagement = () => {
    const dispatch          = useAppDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const ecrf              = useAppSelector(selectEcrf)

    const [page, setPage] = useState(1);
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState<any>({
        itemsPerPage: 0,
        totalItems: 0,
        _embedded: {item: []}
    })

    const [anchorEl, setAnchorEl] = useState(null)
    const [row, setRow] = useState<any>(null)

    const open = Boolean(anchorEl)
    const handleClick = (event: any, row: any) => {
        setAnchorEl(event.currentTarget)
        setRow(row)
    }

    /**
     * Handle close
     */
    const handleClose = () => {
        setAnchorEl(null)
        setRow(null)
    }

    /**
     * Open modal
     */
    const openModal = async (type: string) => {
        handleClose()

        const dataId      = row?.id
        const ecrfId      = row?._embedded?.ecrf?.id    || row?.ecrf?.id
        const elementId   = row?._embedded?.element?.id || row?.element?.id
        const elementName = row?._embedded?.element?.name || row?.element?.name
        const elements    = row?._embedded?.elements    || row?.elements

        await dispatch(
            fetchCollection({...row, dataId, elements, ecrfId, elementId, elementName, type}),
        ).unwrap()
    }

    /**
     * Fetch data
     */
    const fetchData = async (initial?: boolean) => {
        setLoading(true)

        try {
            const randomId = (ecrfRandomId !== '' && !initial) ? ecrfRandomId : null
            const visitId  = visit !== '' && !initial ? visit.id : null

            const response = await dispatch(fetchElementDataManagement({page, randomId, visitId})).unwrap()
            setData(response)
        } catch (error: any) {
            enqueueSnackbar(error.detail, {variant: 'error'});
        }

        setLoading(false)
    }

    /**
     * This function is used to fetch visits
     */
    const [visits, setVisits] = useState<any[]>([])
    const fetchVisitList = async () => {
        const visits = await dispatch(fetchVisits()).unwrap()
        setVisits(visits)
    }

    useEffect(() => {
        fetchVisitList().then()
        fetchData(true).then()
    }, [dispatch, page, ecrf]);

    /**
     * Change status of filters buttons
     */
    const [displayFilters, setDisplayFilters] = useState(false)
    const [isButtonFiltersDisabled, setIsButtonFiltersDisabled] = useState(true)
    const changeButtonFiltersStatus = () => {
        if (ecrfRandomId !== '' || visit !== '') {
            setIsButtonFiltersDisabled(false)
        } else {
            setIsButtonFiltersDisabled(true)
        }
    }

    /**
     * Handle change value of ecrf input
     */
    const [ecrfRandomId, setEcrfRandomId] = useState<string>('')
    const handleEcrfRandomId = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEcrfRandomId(e.target.value)
    }

    /**
     * Handle select value of medical center
     */
    const [visit, setVisit] = useState<any>('')
    const handleVisit = (e: any) => {
        setVisit(e.target.value)
    }

    useEffect(() => {
        changeButtonFiltersStatus()
    }, [ecrfRandomId, visit])

    /**
     * Clear all filters values
     */
    const clearFilters = () => {
        setEcrfRandomId('')
        setVisit('')
        setPage(1)
        fetchData(true).then()
    }

    const columns = () => {
        const columns: any[] = [
            {
                title: <Box>Numer randomizacyjny</Box>,
                render: (row: any) => (
                    <Box
                    >
                        {row?._embedded?.ecrf?.randomId}
                    </Box>
                ),
                key: 'randomId',
                options: {
                    sort: true,
                    sortOrder: {name: 'randomId', direction: 'desc'},
                },
            },
            {
                title: <Box ml="20px">Visit</Box>,
                render: (row: any) => (
                    <Box>
                        {row?.visitName}
                    </Box>
                ),
                key: 'name',
            },
            {
                title: <Box ml="20px">Element</Box>,
                render: (row: any) => (
                    <Box>
                        {row?._embedded?.element?.label}
                    </Box>
                ),
                key: 'name',
            },
            {
                title: <Box ml="20px">Data</Box>,
                render: (row: any) => (
                    <Tooltip style={{cursor: 'help'}} title={row?.data[0] ?? ''}>
                        <Box mt="20px" mb="20px">
                            {row?.data[0] ? (String(row?.data[0])?.substring(0, 100) + '...') : ''}
                        </Box>
                    </Tooltip>

                ),
                key: 'data',
            },
            {
                title: <Box ml="20px">Updated At</Box>,
                render: (row: any) => (
                    <Box mt="20px" mb="20px">
                        {DateTime.toIso(row?.updatedAt, DATETIME_FORMAT)}
                    </Box>
                ),
                key: 'updatedAt',
            },
        ]

        columns.push({
            title: <Box ml="20px">ECRF</Box>,
            render: (row: any) => (
                <Box textAlign={'center'}>
                    <Box display="inline-flex">
                        <Button
                            component={Link}
                            disabled={!row?._embedded?.ecrf?.id || !row?.sectionId}
                            to={{
                                pathname:(ROUTES_ECRF.DETAILS_EXTENDED(
                                    row?._embedded?.ecrf?.id || '', row?.sectionId || ''
                                )),
                                hash: '#' + row?._embedded?.element?.id || ''
                            }}
                            onClick={() => {
                                if (row?._embedded?.ecrf?.id && row?.sectionId) {
                                    dispatch(getSectionId({section: row?.sectionId}))
                                }
                            }}
                            variant="outlined"
                            color="primary"
                            size="small"
                            endIcon={<CallMade/>}
                        >
                            PRZEJDŹ
                        </Button>
                    </Box>
                </Box>
            ),
            key: 'ecrf',
        })

        columns.push({
            title: <Box>Action</Box>,
            render: (row: any) => (
                <Box textAlign={'center'}>
                    <Button
                        id="basic-button"
                        aria-controls="basic-menu"
                        aria-haspopup="true"
                        aria-expanded={open ? true : undefined}
                        onClick={(event: any) => handleClick(event, row)}
                        variant="outlined"
                        color="primary"
                        size="small"
                        // disabled={isLoading || isDisabledButton}
                    >
                        <MoreVert/>
                    </Button>
                </Box>
            ),
            key: 'action',
        })

        return columns
    }

    return (
        <ColoredBox pt={0} px={5} maxWidth="100%" ml="auto" mr="auto">
            {/* <--- FILTERS ZONE | BEGIN ---> */}
            <Box display={'flex'} gap={'20px'} justifyContent={'end'}>
                <Button
                    style={{whiteSpace: 'nowrap', marginTop: '20px'}}
                    color={'primary'}
                    size={'small'}
                    onClick={() => setDisplayFilters(!displayFilters)}
                    variant={'outlined'}
                >
                    {displayFilters ? 'Ukryj filtry' : 'Pokaż filtry'}
                </Button>
            </Box>
            <Collapse in={displayFilters}>
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    mt={1}
                    border={`1px solid #e7e7e7`}
                    p={3}
                    borderRadius={'10px'}
                >
                    <Box display="grid"
                         gridTemplateColumns={'1fr 1fr 1fr 1fr 1fr'}
                         alignItems="center"
                         style={{gap: "20px"}}
                    >
                        <Tooltip title="Randomization number" arrow>
                            <TextField
                                label={'Randomization number'}
                                onChange={handleEcrfRandomId}
                                value={ecrfRandomId}
                                fullWidth
                                variant={'outlined'}
                                size={'small'}
                            />
                        </Tooltip>

                        <Tooltip title="Select visit" arrow>
                            <TextField
                                select
                                fullWidth
                                variant={'outlined'}
                                label={'Visit'}
                                size={'small'}
                                onChange={(e: any) => handleVisit(e)}
                                value={visit}
                            >
                                {/** Display list of all numbers */}
                                {visits.map((value: any) => (
                                    <MenuItem key={value?.id} value={value}>
                                        {value?.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Tooltip>

                        <Box display={'flex'} gap={'20px'} alignItems={'center'}>
                            <Tooltip title={'Filtruj po wybranych opcjach'} arrow>
                                <StyledGlobalMedicalCentersFiltersButton
                                    onClick={() => {
                                        if (!isButtonFiltersDisabled) fetchData()
                                    }}
                                    status={!isButtonFiltersDisabled && !isLoading}>
                                    <FilterList/> Filtruj
                                </StyledGlobalMedicalCentersFiltersButton>
                            </Tooltip>
                            <Tooltip title={'Wyczyść wszystkie filtry'} arrow>
                                <StyledGlobalMedicalCentersFiltersButton
                                    onClick={() => {
                                        if (!isButtonFiltersDisabled) clearFilters()
                                    }}
                                    status={!isButtonFiltersDisabled && !isLoading}
                                >
                                    <DeleteSweepRounded/> Wyczyść
                                </StyledGlobalMedicalCentersFiltersButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
            </Collapse>
            {/* <--- FILTERS ZONE | END ---> */}

            <Table
                pagination={{
                    current: page,
                    pageSize: data?.itemsPerPage,
                    total: data?.totalItems,
                }}
                onChangePage={(page) => setPage(page)}
                data={data._embedded?.item || []}
                tableName='Data management'
                columns={columns()}
                isLoading={isLoading}
            />

            <MenuStyled
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{'aria-labelledby': 'basic-button'}}
                style={{boxShadow: 'none'}}
            >
                <MenuItem
                    onClick={() => openModal('save')}>
                    <Typography variant="body2"> EDIT </Typography>
                </MenuItem>
            </MenuStyled>

            <ModalForm/>
        </ColoredBox>
    );
};

export default DashboardDataManagement;
