import React, { useState, ReactNode, useEffect, useCallback, useRef } from 'react'
import { Box, Button, FormControlLabelProps, LinearProgress } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'commons/store/hooks'
import {
  fetchElementDataCollections,
  putCollectElementData,
  putDataToElement,
  putElementData,
  selectCollection,
  selectCurrentSection,
  selectElementDatas,
} from 'features/ecrf/redux/ecrfSlice'
import { useParams } from 'react-router-dom'
import {
  DataGrid,
  DataGridProps,
  GRID_DATETIME_COL_DEF,
  GridActionsCellItem,
  GridCellEditStopParams,
  GridCellEditStopReasons,
  GridCellParams,
  GridColDef,
  GridColTypeDef,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModel,
  GridRowParams,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridValueOptionsParams, useGridApiRef,
  ValueOptions,
} from '@mui/x-data-grid';
import {flatten, map} from "lodash";
import { useSnackbar } from "notistack";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import ConfirmationAction from "../ConfirmationAction";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Dependency from 'core/helpers/Dependency';
import DateTime from "core/helpers/date/DateTime";
import {DATE_FORMAT, DATETIME_FORMAT, TIME_FORMAT} from "../../commons/constants/env";
import GridCell from "./DataGrid/GridCell";
import GridEditCell from "./DataGrid/GridEditCell";
import DataGridHelper from "./DataGrid/DataGridHelper";
import { InputProps } from "@mui/material/Input";
import { GridBaseColDef, GridSingleSelectColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import Cell from "./DataGrid/Cell";
import * as util from "util";
import {isDate} from "date-fns";
import { enUS as locale } from 'date-fns/locale';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {GRID_DATE_COL_DEF} from "@mui/x-data-grid/colDef/gridDateColDef";
import HistoryIcon from "@mui/icons-material/History";
import {RowHistoryModal} from "./RowHistoryModal";
import {selectUserProfile} from "../../core/redux/userSlice";
import {roles} from "../../commons/auth/roles";

type CustomGridColDef = GridColDef & {
  elementId?: string;
  position?: number;
}

export interface DataGridFormikProps
  extends Omit<InputProps, 'name' | 'onChange' | 'onBlur' | 'error'> {
  name: string
  label?: ReactNode
  labelPlacement?: FormControlLabelProps['labelPlacement']
  children?: any[]
  element: any;
  setIsOpenQueryPanel: (elementId: string) => void
  isOpenQueryPanel: string | null
  dataId: string
  options?: any;
  status?: boolean
}

const DataGridFormik = ({
  name,
  label,
  labelPlacement,
  value,
  options,
  status,
  element,
  children,
  setIsOpenQueryPanel,
  isOpenQueryPanel,
  dataId,
  ...props
}: DataGridFormikProps) => {
  const dispatch= useAppDispatch()
  const collection= useAppSelector(selectCollection)
  const [dependencies, setDependencies] = useState<any>({});
  const currentSection = useAppSelector(selectCurrentSection);
  const selectedElementDatas = useAppSelector(selectElementDatas)
  const userProfile = useAppSelector(selectUserProfile);
  const { id } = useParams<{ id: string }>()
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState<number>(options?.pageSize || 20);
  const [columns, setColumns] = useState<GridColDef[]>([])
  const [rows, setRows] = useState<any[]>([])
  const [collections, setCollections] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false);
  const {enqueueSnackbar} = useSnackbar();
  const [field, setField] = useState<any>(null)
  const editingRow = React.useRef<GridRowModel | null>(null);
  const apiRef= useGridApiRef();
  const [openHistory, setOpenHistory] = useState<any>(false);

  const radio: GridColTypeDef = {
    type: 'radio',
    width: 130
  };

  // const renderRange = (params: GridRenderCellParams, options: any) => {
  //   const value = Array.isArray(params.value) ? params.value[0] : params.value
  //
  //   return <Slider defaultValue={70} valueLabelDisplay="auto"/>;
  // }

  const getOptions = (params: GridValueOptionsParams, element: any) => {
    let options: ValueOptions = [];
    const row = rows.find(({ id }) => id === params?.id);

    if (row && row[params?.field] && row[params?.field]?.options && row[params?.field]?.options?.choices) {
      options = map(row[params?.field]?.options?.choices,
          (value: any, label: any) => ({label: label, value: value})
      )
    } else {
      options = (element?.options && element?.options?.choices) ? map(element?.options?.choices,
          (value: any, label: any) => ({label: label, value: value})
      ) : []
    }

    return options;
  }

  const getType = (params: GridRenderCellParams, element: any) => {
    let type: string = 'string';

    const row = rows.find(({ id }) => id === params?.id);

    if (row && row[params?.field] && row[params?.field]?.type) {
      type = row[params?.field]?.type
    } else {
      type = element?.type || type
    }

    return type;
  }

  const renderCell = (params: GridRenderCellParams, element: any) => {
    const options = getOptions(params, element);
    const type = getType(params, element);
    
    return <GridCell type={type} options={options} params={params} />;
  }

  const renderEditCell = (params: GridRenderEditCellParams, element: any) => {
    const options = getOptions(params, element);
    const type = getType(params, element);

    return (
        <GridEditCell type={type} options={options} value={params.value} params={params} />
    );
  }

  // const dateAdapter = new AdapterDateFns({ locale });

  const createColumns = () => {
    // const columns: CustomGridColDef[] = []
    // const initColumns: GridColDef[] = []
    const initColumns = []
    children?.forEach((el: any, index: any) => {
      let identifier = `field${index}`;
      let type = el.type;

      switch (type) {
        case 'divider': {
          break;
        }
        // unsupported column elements
        case 'checkboxes':
        case 'checkbox_multiple':
        case 'range':
        case 'select_multiple': {
          initColumns.push({
            elementId: el.id,
            field: identifier,
            headerName: el.title || el.label || el.name,
            description: el?.options?.help || 'Unsupported column element',
            type: DataGridHelper.retype(el.type),
            minWidth: options?.columnWidth || 170,
            sortable: false,
            editable: false,
            position: index,
            renderCell: (params: GridRenderCellParams<Text>) => (<i>-- unsupported --</i>),
          })
          break;
        }
        case 'date': {
          initColumns.push({
            // ...GRID_DATE_COL_DEF,
            elementId: el.id,
            field: identifier,
            headerName: el.title || el.label || el.name,
            description: el?.options?.help || null,
            type: DataGridHelper.retype(type),
            minWidth: options?.columnWidth || 170,
            sortable: false,
            editable: !status && !el?.options?.attr?.readonly,
            position: index,
            renderCell: (params: GridRenderCellParams) => renderCell(params, el),
            renderEditCell: (params: GridRenderEditCellParams) => renderEditCell(params, el),
            valueFormatter: (params: any) => {
              // console.log('valueFormatter')
              // console.log(params)

              if (DateTime.isTimeStamp(params?.value?.value || params?.value)) {
                return DataGridHelper.formatValue(type, params?.value?.value || params?.value)
              }

              return '';
            },
          })
          break;
        }
        case 'dateTime':
        case 'datetime': {
          initColumns.push({
            ...GRID_DATETIME_COL_DEF,
            elementId: el.id,
            field: identifier,
            headerName: el.title || el.label || el.name,
            description: el?.options?.help || null,
            type: DataGridHelper.retype(type),
            minWidth: options?.columnWidth || 170,
            sortable: false,
            editable: !status && !el?.options?.attr?.readonly,
            position: index,
            renderCell: (params: GridRenderCellParams) => renderCell(params, el),
            renderEditCell: (params: GridRenderEditCellParams) => renderEditCell(params, el),
            valueFormatter: (params: any) => {
              if (DateTime.isTimeStamp(params?.value?.value || params?.value)) {
                return DataGridHelper.formatValue(type, params?.value?.value || params?.value)
              }

              return '';
            },
          })
          break;
        }
        case 'radio': {
          initColumns.push({
            elementId: el.id,
            field: identifier,
            headerName: el.title || el.label || el.name,
            description: el?.options?.help || null,
            type: DataGridHelper.retype(el.type),
            minWidth: options?.columnWidth || 170,
            sortable: false,
            editable: !status && !el?.options?.attr?.readonly,
            position: index,
            renderCell: (params: GridRenderCellParams) => renderCell(params, el),
            renderEditCell: (params: GridRenderEditCellParams) => renderEditCell(params, el),
            valueFormatter: (params: any) => DataGridHelper.valueGetter(params.value),
            valueOptions: (params: GridValueOptionsParams) => getOptions(params, el),
            ...radio
          })
          break;
        }
        case 'checkbox':
        case 'choice':
        case 'select': {
          initColumns.push({
            elementId: el.id,
            field: identifier,
            headerName: el.title || el.label || el.name,
            description: el?.options?.help || null,
            type: DataGridHelper.retype(el.type),
            minWidth: options?.columnWidth || 170,
            sortable: false,
            editable: !status && !el?.options?.attr?.readonly,
            position: index,
            renderCell: (params: GridRenderCellParams) => renderCell(params, el),
            renderEditCell: (params: GridRenderEditCellParams) => renderEditCell(params, el),
            valueFormatter: (params: any) => DataGridHelper.valueGetter(params.value),
            valueOptions: (params: GridValueOptionsParams) => getOptions(params, el)
          })
          break;
        }
        default: {
          initColumns.push({
            elementId: el.id,
            field: identifier,
            headerName: el.title || el.label || el.name,
            description: el?.options?.help || null,
            type: DataGridHelper.retype(el.type),
            minWidth: options?.columnWidth || 170,
            sortable: false,
            editable: !status && !el?.options?.attr?.readonly,
            position: index,
            renderCell: (params: GridRenderCellParams) => renderCell(params, el),
            renderEditCell: (params: GridRenderEditCellParams) => renderEditCell(params, el),
            valueFormatter: (params: any) => DataGridHelper.valueGetter(params.value),
          })
          break;
        }
      }
    })

    if ((userProfile.roles.some(
        (role: string) => roles.admin.includes(role) || roles.monitor.includes(role)
    ) || options?.removeRow)) {
      initColumns.push({
        field: 'actions',
        type: 'actions',
        minWidth: 90,
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem icon={<HistoryIcon />} onClick={handleHistoryRow(params.id)} label="History" />,
          <>
            {(options?.removeRow ?? true) && (
                <ConfirmationAction
                    confirmationTitle={'Are you sure you want to delete this record?'}
                    onClick={handleDeleteRow(params.id)}>
                    {({ handleClick }) => (
                        <GridActionsCellItem icon={<DeleteIcon />} onClick={handleClick} label="Delete" />
                    )}
                </ConfirmationAction>
            )}
          </>,
        ]
      })
    }

    setColumns(initColumns || [])
  }

  const fetch = useCallback(async () => {
    setLoading(true)

    let data = [];
    if (dataId) {
      data = await dispatch(fetchElementDataCollections({id: dataId})).unwrap()
    } else if (selectedElementDatas?.element?.id === name) {
      data = await dispatch(fetchElementDataCollections({id: selectedElementDatas?.id,})).unwrap()
    }

    data && setCollections(data)

    // if (data && data.length > 0) {
    //   data = await Dependency.prepareCollection(element, data, currentSection?.dependencies);
    //   setDependencies(data);
    // }

    // console.log('element')
    // console.log(element)
    // console.log(element?.dependencies)

    if (data?.length > 0) {
      const dataRows: any[] = []
      data?.forEach((collect: any, i: any) => {
        const row: GridRowModel = { id: collect?.id }

        // sort elements
        const elements: any =  collect?.elements?.slice().sort(
            (a: any, b: any) => a.position - b.position
        );

        // dependencies
        // const depends = Dependency.assign(
        //     currentSection?.elements, elements, currentSection?.dependencies
        // );
        const depends = Dependency.createDep(
            element?.children, elements, currentSection?.dependencies
        );
        // console.log('depends')
        // console.log(depends)
        // console.log(currentSection?.dependencies)

        elements.forEach((ed: any, index: any) => {
          let identifier = `field${index}`;

          // console.log('ed')
          // console.log(ed?.element)

          // const data = elements.find(
          //     (el: any, index: any) => el?.element?.id === params.field || `field${index}` === params.field
          // );

          // if (ed?.id) {
          //   identifier = ed?.id;
          // }

          // if (options?.identifier) {
          //   identifier = ed?.element[options?.identifier];
          // }

          const coordinate = (index + 1) + ':' + (i + 1);
          let dependencies: any[] = []
          if (element?.dependencies.length > 0) {
            // console.log('coordinate')
            // console.log(ed?.element?.name)
            // console.log(i)
            // console.log(index)
            //
            // console.log('dep==============')
            // console.log(coordinate)

            // dependencies = element?.dependencies.find(
            //     // (d: any) => d.fields.length > 0 && d.fields.includes(coordinate)
            //     (d: any) => Array.prototype.includes.call(d.fields, coordinate)
            // );

            // for (const depend of element?.dependencies) {
            //   if (!Array.prototype.filter.call(
            //       Object.values(depend.fields), value => value == coordinate
            //   )) {
            //     continue;
            //   }
            //
            //   dependencies.push(depend)
            // }
            //
            // console.log(dependencies)
            // console.log('dep=end==========')
          }

          row[identifier] = {
            id: ed?.element?.id,
            dataId: ed?.id,
            // type: ed?.element?.type,
            type: DataGridHelper.retype(ed?.element?.type),
            options: ed?.element?.options,
            // value: DataGridHelper.formatValue(ed?.element?.type, ed?.data[0]),
            value: ed?.data[0],
            coordinate: coordinate,
            dependencies: dependencies
          }
        })

        dataRows.push(row)
      })

      setRows(dataRows || [])
    }

    setLoading(false)
  }, [children, dataId, selectedElementDatas]);

  /**
   * This method is used to add new row to element
   * @param collections
   */
  const addNewRow = async (collections: any) => {
    let collection = [];
    for (const col of collections) {
      collection.push({id: '/api/element_data_collections/' + col.id});
    }

    let elements = [];
    for (const element of children || []) {
      elements.push({
        ecrf: '/api/ecrves/' + id,
        element: '/api/elements/' + element?.id,
        data: [''],
      });
    }

    collection.push({elements: elements});
    collection && await createNewRow(collection, dataId || selectedElementDatas?.id);
  }

  /**
   * This method is used to put initial data and create first row
   * @param children
   */
  const createFirstRow = async (children?: any) => {
    try {
      const response = await dispatch(putElementData({elementId: name, ecrfId: id || '', data: [0]})).unwrap()

      if (!children) return;

      let elements = [];
      for (const element of children) {
        const data = {
          ecrf: '/api/ecrves/' + id,
          element: '/api/elements/' + element.id,
          data: [''],
        };
        elements.push(data);
      }

      response?.id && await createNewRow([{elements: elements}], response?.id);

      // response?.id && await dispatch(fetchElementDataCollections({id: response?.id}))

      fetch().then()
    } catch (error: any) {
      enqueueSnackbar(error?.response?.data?.detail, {variant: 'warning'});
    }
  }

  /**
   * This method is used to add now row - copy last collection and append new blank
   * @param collections
   * @param dataId
   */
  const createNewRow = async (collections: any, dataId: string) => {
    try {
      await axiosSecureInstance.put(`/api/element_datas/${dataId}`, {
        data: [],
        collection: [...collections],
      });
    } catch (error: any) {
      enqueueSnackbar(error?.response?.data?.detail, {variant: 'warning'});
    }
  }

  /**
   * This method is used to add row
   */
  const handleAddRow = async () => {
    setLoading(true)

    try {
      if (collections && collections.length > 0) {
        await addNewRow(collections);
      } else {
        await createFirstRow(children);
      }

      await fetch().then()
    } catch (error: any) {
      enqueueSnackbar(error?.response?.data?.detail || error?.detail || error?.message, {variant: 'warning'});
    }

    setLoading(false)
  };

  const handleCellEditStart: DataGridProps['onCellEditStart'] = (params) => {
    // console.log('DataGridFormik handleCellEditStart')
    // console.log(params)

    setField(params.field)
    editingRow.current = rows.find((row) => row.id === params.id) || null;
  };

  // const handleCellEditStop = React.useCallback(
  //     async (params: GridCellEditStopParams, event: MuiEvent) => {
  //       setField(params.field)
  //     },[],
  // );

  // const handleCellEditStop: DataGridProps['onCellEditStop'] = (params) => {
  //   // let valueData = editingRow?.current ? editingRow?.current[params.field]?.value : ''
  //   // let valueData = params.row[params.field]?.value?.value || params.row[params.field]?.value
  // };

  /**
   * processRowUpdate()
   */
  const processRowUpdate = React.useCallback(
      async (newRow: GridRowModel, oldRow: GridRowModel) => {
        setLoading(true)

        let oldValueData = oldRow[field] ?? ''
        let valueData = newRow[field] ?? ''
        let editRow = editingRow?.current
        let editData = editRow ? editRow[field] : null
        let rowsData = []

        // console.log('Save---------')
        // console.log(oldValueData)
        // console.log(valueData)
        // console.log(editRow)
        // console.log(editData)

        /**
         * Dependencies
         */
        if (element?.dependencies.length > 0) {
          // console.log('processRowUpdate')
          // console.log(field)
          // console.log(oldRow)
          // console.log(newRow)
          // console.log(editingRow.current)
          // console.log(oldValueData)
          // console.log(valueData)
          // console.log('-----------------------------')

          for (const depend of element?.dependencies) {
            const filter = Array.prototype.filter.call(
                Object.values(depend.fields ?? []), value => value.includes('=') || value.includes('sum')
            )

            const filterCoordinate = Array.prototype.filter.call(
                Object.values(depend.fields ?? []), value => !value.includes('=') && !value.includes('sum')
            )

            // console.log('filterCoordinate')
            // console.log(filterCoordinate)

            // if (filterCoordinate && filterCoordinate.includes(oldValueData?.coordinate) && filter) {
            if (filterCoordinate && filterCoordinate.includes(editData?.coordinate) && filter) {
              for (const f of filter) {
                // sum/fn
                let c = f.replace('=', '');
                const sumRow = Array.prototype.find.call(
                    rows, value => Array.prototype.filter.call(
                        value, v => v?.coordinate == c
                    )
                )

                const sumCell = Object.keys(sumRow).find(key => sumRow[key]?.coordinate === c)

                // console.log('filter==========')
                // console.log(c)
                // console.log(sumRow)
                // console.log(sumCell)

                const calcValues = []
                if (sumRow?.id && sumCell) {
                  const coordinateRows = Array.prototype.filter.call(
                      rows, value => Array.prototype.filter.call(
                          value, v => filterCoordinate.includes(v?.coordinate)
                      )
                  )

                  for (const coordinateRow of coordinateRows) {
                    const crValues: any = Object.fromEntries(Object.entries(coordinateRow)
                        .filter(([key]) => !key.includes('id')));

                    const test: any[] = Object.values(crValues)

                    for (const cr of test) {
                      if (cr?.coordinate && !filterCoordinate.includes(cr?.coordinate)) {
                        continue;
                      }

                      // if (cr?.coordinate === oldValueData?.coordinate) {
                      if (cr?.coordinate === editData?.coordinate) {
                        calcValues.push(valueData)
                      } else {
                        calcValues.push(cr?.value)
                      }
                    }
                  }

                  // console.log('calcValues=================')
                  // console.log(calcValues)

                  const fn = util.format('%s + %s', ...calcValues);
                  const calc = eval(fn)
                  // console.log('calc=================')
                  // console.log(calc)
                  // console.log('editing--------------')

                  let editing: any = {};
                  editing[sumCell] = { ...sumRow[sumCell], value: calc }

                  // console.log(sumRow[sumCell])
                  // console.log(editing)

                  // rowsData = rows.map((row) => row?.id === sumRow?.id ? { ...row, ...editing } : row)
                  setRows((prevRows) =>
                      prevRows.map((row) => row?.id === sumRow?.id ? { ...row, ...editing } : row),
                  );
                  // setRows(rowsData);

                  (sumRow[sumCell] && sumRow[sumCell]?.dataId) && await dispatch(putDataToElement({
                    data: [calc],
                    elementDataId: sumRow[sumCell]?.dataId
                  }));
                }
              }
            }
          }
        }

        if (oldValueData === valueData) {
          setLoading(false)
          return newRow;
          // return rows.find((row) => row.id === newRow?.id);
        }

        // find column
        const col = collections.find((collect: any) => collect?.id === newRow?.id);
        if (!col || col?.elements?.length === 0) {
          return;
        }

        const sortData: any = col?.elements?.slice().sort(
            (a: any, b: any) => a.position - b.position
        );

        // find element data
        const data = sortData.find(
            // (el: any, index: any) => el?.element?.id === newRow?.id || `field${index}` === field
            (el: any, index: any) => `field${index}` === field
        );

        if (valueData instanceof Object) {
          valueData = DataGridHelper.valueGetter(valueData);
        }

        // console.log('data----------------------')
        // // console.log(data)
        // console.log(valueData)
        // console.log(editData?.type)
        // console.log(data?.element?.type)

        const type = data?.element?.type || editData?.type;
        switch (type?.toLowerCase()) {
          case 'date':
          case 'datetime':
          case 'time': {
            valueData = DataGridHelper.formatValue(type, valueData);
            break;
          }
        }

        if (!data) {
          const elements = [];

          if (sortData?.length > 0) {
            for (const element of sortData) {
              elements.push({
                id: '/api/element_datas/' + element.id,
              });
            }
          }

          editData && elements.push({
            ecrf: '/api/ecrves/' + id,
            element: '/api/elements/' + editData?.element?.id,
            data: [valueData]
          })

          await dispatch(putCollectElementData({id: newRow.id, elements: elements})).unwrap()
        } else {
          await dispatch(putDataToElement({data: [valueData], elementDataId: data?.id}));
        }

        enqueueSnackbar(<span>Saved <b>{data?.element?.label || ''}</b> ({new Date().toLocaleTimeString()})</span>, {
          variant: 'info'
        });

        setLoading(false)
        // return newRow;
        // return rows.find((row) => row.id === newRow?.id);

        let current: any = {};
        let currentValue: any = newRow[field]?.value;
        current[field] = { ...newRow[field], value: valueData }
        // rowsData = rowsData.map((row) => row?.id === newRow?.id ? { ...row, ...current } : row)

        setRows((prevRows) =>
            prevRows.map((row) => row?.id === newRow?.id ? { ...row, ...current } : row),
        );

        for (const row of rows) {
          apiRef.current.updateRows([row])
          // apiRef.current.forceUpdate([row])
        }

        let currentState: any = apiRef.current.store.value;

        // console.log('rows--------------------------------')
        // console.log(rows)
        // console.log(apiRef.current.state.rows.dataRowIdToModelLookup)
        // console.log(apiRef.current.store.value.rows.dataRowIdToModelLookup)
        // console.log(apiRef.current)
        // console.log(currentState.rows.dataRowIdToModelLookup)
        // console.log(apiRef.current.getRowModels())

        // console.log(rowsData)

        // setRows(rowsData);
        // await fetch().then()
        // apiRef.current.setState((state) => {rows: rows})

        return rows.find((row) => row.id === newRow?.id);
        // return newRow
      },
      [dispatch, field]
  );

  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    enqueueSnackbar(error?.message, { variant: 'error' });
  }, []);

  // const handleCellEditable = (params: GridCellParams): boolean => {
  //
  //   const row = rows.find(({ id }) => id === params?.id);
  //
  //   if (row[params.field]) {
  //   }
  //
  //   // dependencies
  //   // const depends = Dependency.assign(
  //   //     currentSection?.elements, elements, currentSection?.dependencies
  //   // );
  //
  //   return true
  // };

  /**
   * Fetch history data of selected element
   * @param id
   */
  const handleHistoryRow = React.useCallback(
      (id: GridRowId) => async () => {
        setOpenHistory(id)
      },[],
  );

  const handleDeleteRow = React.useCallback(
      (id: GridRowId) => async () => {
        setLoading(true)

        try {
          await axiosSecureInstance.delete<any>(`/api/element_data_collections/${id}`);
          setRows((prevRows) => prevRows.filter((row) => row.id !== id));
        } catch (error: any) {
          enqueueSnackbar(
              error?.response?.data?.detail || error?.detail || error?.message,
              {variant: 'warning'}
          );
        }

        setLoading(false)
      },[],
  );

  useEffect(() => {
    createColumns()
    fetch().then()
  }, [collection, id, dataId])

  const GridToolbar = () => {
    return (
        <GridToolbarContainer>
          <GridToolbarDensitySelector />
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            {(options?.addRow ?? true) && <Button
                size="small"
                color={'primary'}
                startIcon={<AddIcon />}
                disabled={loading}
                onClick={handleAddRow}
            >
              Add a row
            </Button>}
          </Box>
        </GridToolbarContainer>
    );
  }

  return (
    <Box pt={3}>
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', height: 400 }}>
      <DataGrid
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { pageSize: pageSize } },
        }}
        pageSizeOptions={[5, 10, 20, 30, 50]}
        pagination
        rowCount={rows.length}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableRowSelectionOnClick
        density='standard'
        localeText={{
          toolbarDensity: 'Size',
          toolbarDensityLabel: 'Size',
          toolbarDensityCompact: 'Small',
          toolbarDensityStandard: 'Medium',
          toolbarDensityComfortable: 'Large',
        }}
        loading={loading}
        // isCellEditable={handleCellEditable}
        // onCellEditStop={(params, event) => {
        //   if (params.reason !== GridCellEditStopReasons.enterKeyDown) {
        //     return;
        //   }
        // }}
        onCellEditStart={handleCellEditStart}
        // onCellEditStop={handleCellEditStop}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        processRowUpdate={processRowUpdate}
        slots={{
          // cell: Cell,
          toolbar: GridToolbar,
          loadingOverlay: LinearProgress,
        }}
        />
      </Box>

      {/** Modal with history: possible to open when changes count > 1 */}
      <RowHistoryModal children={children} open={openHistory} onClose={() => setOpenHistory(false)}/>
    </Box>
  )
}

export default DataGridFormik
