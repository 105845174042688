import ur from "./images/logo-ur.jpg";
import neoVinci from "./images/neovinci.png";
import orcon from "./images/logo-orcon-pharma.svg";
import logo from "./images/logo.png";
import headerImg from "./images/header.png";
import {useRef} from "react";
import {
    Box,
    Button,
    Container,
    Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ROUTES_CORE } from "commons/constants/paths";
import {
    StyledHeaderSection,
    StyledHeaderImg,
    StyledHeaderHolder,
    StyledHeaderTitle,
    StyledHeaderSubtitle,
    StyledSectionTitle,
    StyledInfoSectionDescription,
    StyledAnchor,
    StyledLogo,
    StyledNavbarList,
    StyledNavbarListItem,
    StyledLoginButton,
    StyledFooter,
    StyledFooterSection,
    StyledFooterTitle,
    StyledFooterMenu,
    StyledFooterMenuLink,
    StyledFooterImg,
    StyledFooterImgSpan,
    StyledContactWayTitle,
    StyledFooterAnchor,
} from './Home.styled';
import MedicalCenter from "../components/MedicalCenter";
import { PROJECT_EMAIL, PROJECT_NAME, PROJECT_PHONE } from "../../../../commons/constants/env";

function Home() {
    let info: any = useRef();

    return (
        <div>
            <header>
                <Container maxWidth="lg">
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid container item lg={3} xs={12} spacing={0}>
                            <StyledLogo src={logo} alt="Projekt Idole logo"/>
                            <Box height={85}></Box>
                        </Grid>
                        <Grid container item lg={9} xs={12} spacing={0}>
                            <StyledNavbarList>
                                <StyledNavbarListItem>
                                    <Button
                                        onClick={() =>
                                            window.scrollTo({
                                                behavior: 'smooth',
                                                top: info.current.offsetTop,
                                            })
                                        }
                                        size="medium"
                                    >
                                        INFORMACJE O BADANIU
                                    </Button>
                                </StyledNavbarListItem>
                                <StyledNavbarListItem>
                                    <StyledLoginButton
                                        variant="contained"
                                        color="primary"
                                        component={Link}
                                        to={ROUTES_CORE.LOGIN}
                                    >
                                        LOGOWANIE
                                    </StyledLoginButton>
                                </StyledNavbarListItem>
                            </StyledNavbarList>
                        </Grid>
                    </Grid>
                </Container>
            </header>

            <StyledHeaderSection>
                <StyledHeaderImg src={headerImg} alt="Background img"/>
                <StyledHeaderHolder>
                    <Container maxWidth="lg">
                        <StyledHeaderTitle>
                            PROJEKT <b>{PROJECT_NAME}</b>
                        </StyledHeaderTitle>
                        <StyledHeaderSubtitle>
                            Cytyzyna w leczeniu uzależnienia od elektronicznych papierosów: randomizowane placebo-kontrolowane badanie
                            [Cytisine for electronic cigarette cessation: A randomized placebo-controlled trial]
                        </StyledHeaderSubtitle>
                    </Container>
                </StyledHeaderHolder>
            </StyledHeaderSection>
            <section ref={info}>
                <Container maxWidth="lg">
                    <StyledSectionTitle textAlign="center">
                        Informacje o badaniu
                    </StyledSectionTitle>
                    <StyledInfoSectionDescription>
                        Uzależnienie od elektronicznych papierosów stało się w krótkim czasie jednym z największych wyzwań medycyny. Innowacyjne niekomercyjne badanie kliniczne jest prowadzone przez zespół badaczy Zakładu Farmakologii Doświadczalnej i Klinicznej Uniwersytetu Rzeszowskiego. Głównym celem badania jest ocena skuteczności leczenia cytyzyną (stosowaną w formie tabletek w zmodyfikowanym, innowacyjnym schemacie dawkowania,) uzależnienia od elektronicznych papierosów oraz "podwójnego" uzależnienia od elektronicznych i tytoniowych papierosów. Cytyzyna jest lekiem stosowanym w Polsce od ponad 50-lat jako lek bez recepty pomagający skutecznie rzucić palenie papierosów tytoniowych. Badanie ma charakter wieloośrodkowy, randomizowany, podwójnie zaślepiony i jest kontrolowane placebo. Jego uczestnikami mogą być osoby powyżej 16 roku życia używające e-papierosów lub e-papierosów i papierosów tytoniowych. Czas trwania badania: 2021-2027.
                        Głównym efektem zdrowotnym może być pełne wyleczenie pacjenta z uzależnienia od nikotyny (ciągła abstynencja). Zakłada się, że odsetek abstynentów nikotynowych po 6 miesiącach od wyznaczonej daty zaprzestania używania papierosów elektronicznych lub papierosów elektronicznych i tytoniowych będzie istotnie większy u leczonych cytyzyną w porównaniu do przyjmujących placebo. Wyleczenie z uzależnienia przełoży się na możliwość pełnego wyleczenia z chorób uwarunkowanych nikotynizmem i poprawi stan zdrowia palaczy. Efektem projektu będzie opracowanie po raz pierwszy na świecie leczenia farmakologicznego uzależnienia od papierosów elektronicznych i „podwójnego” uzależnienia oraz opracowanie skutecznych strategii i programów pomocy w rzucaniu palenia e-papierosów dla milionów palaczy na świecie.
                    </StyledInfoSectionDescription>
                    <StyledSectionTitle textAlign="center">
                        I- i II-rzędowe punkty końcowe
                    </StyledSectionTitle>
                    <StyledInfoSectionDescription>
                        Pierwszorzędowym punktem końcowym jest weryfikowana biochemicznie ciągła abstynencja od elektronicznych papierosów i papierosów tytoniowych przez 26 tygodni poprzedzających wizytę końcową po 30 tygodniach (od 4 do 30 tygodnia) od rozpoczęcia leczenia.
                        Drugorzędowymi punktami końcowymi są:
                        1) zgłaszana przez uczestnika badania ciagła abstynencja od elektronicznych papierosów definiowana jako abstynencja przez 26 tygodni poprzedzających wizytę końcową po 30 tygodniach od rozpoczęcia leczenia
                        2) zgłaszana przez uczestnika badania ciagła abstynencja od elektronicznych papierosów po 2, 4 i 12 tygodnich od rozpoczęcia leczenia.
                        3) zgłaszana przez uczestnika badania abstynencja od tytoniowych papierosów definiowana jako abstynencja przez 26 tygodni poprzedzających wizytę końcową po 30 tygodniach od rozpoczęcia leczenia
                        4) zgłaszana przez uczestnika badania ciagła abstynencja od tytoniowych papierosów po 2, 4 i 12 tygodniach od rozpoczęcia leczenia.
                    </StyledInfoSectionDescription>
                    <MedicalCenter />
                </Container>
            </section>
            <StyledFooter>
                <Container maxWidth="lg">
                    <StyledFooterSection>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <StyledFooterTitle>Menu:</StyledFooterTitle>
                                <StyledFooterMenu>
                                    <StyledFooterMenuLink href="#">
                                        INFORMACJE O BADANIU
                                    </StyledFooterMenuLink>
                                </StyledFooterMenu>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <StyledFooterTitle>Kontakt:</StyledFooterTitle>
                                <StyledContactWayTitle>e-mail: </StyledContactWayTitle>
                                <StyledAnchor href={`mailto:${PROJECT_EMAIL}`}>
                                    {PROJECT_EMAIL}
                                </StyledAnchor>
                                <br/>

                                <StyledContactWayTitle>telefon: </StyledContactWayTitle>
                                <StyledFooterAnchor href={`tel:${PROJECT_PHONE}`}>
                                    {PROJECT_PHONE}
                                </StyledFooterAnchor>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <StyledFooterTitle>Logowanie do eCRF:</StyledFooterTitle>
                                <Box>
                                    <StyledLoginButton
                                        color="primary"
                                        variant="contained"
                                        component={Link}
                                        to={ROUTES_CORE.LOGIN}
                                    >
                                        LOGOWANIE
                                    </StyledLoginButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </StyledFooterSection>
                    <StyledFooterSection>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <StyledFooterTitle>INFORMACJE O BADANIU:</StyledFooterTitle>
                                <StyledContactWayTitle><b>Źródło finansowania:</b> Agencja Badań Medycznych</StyledContactWayTitle>
                                <br/>
                                <StyledContactWayTitle><b>Nr projektu:</b> 2021/ABM/02/00007</StyledContactWayTitle>
                                <br/>
                                <StyledContactWayTitle><b>Wysokość dofinansowania:</b> 9 860 039,10 zł</StyledContactWayTitle>
                            </Grid>
                            <br/>
                            <Grid item xs={12} md={6}>
                                <StyledFooterTitle>PARTNER PROJEKTU:</StyledFooterTitle>
                                <StyledContactWayTitle>Instytut Informatyki Uniwersytetu Rzeszowskiego</StyledContactWayTitle>
                            </Grid>
                        </Grid>
                    </StyledFooterSection>
                    <StyledFooterSection>
                        <Grid container spacing={3} justifyContent={'center'}>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <StyledFooterImg
                                    src={logo}
                                    alt={PROJECT_NAME}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <StyledFooterImg src={'./images/logo-abm.svg'} alt="ABM logo"/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <StyledFooterImg src={ur} alt="UR logo"/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <StyledFooterImg src={orcon} alt="Orcon Pharma logo"/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <StyledFooterImgSpan>
                                    Organizacja techniczna:
                                </StyledFooterImgSpan>
                                <StyledFooterImg src={neoVinci} alt="Neo-Vinci logo"/>
                            </Grid>
                        </Grid>
                    </StyledFooterSection>
                </Container>
            </StyledFooter>
        </div>
    );
}

export default Home;
