import { Box, Typography } from "@mui/material";
import {ROUTES_USERS} from "commons/constants/paths";
import {useAppDispatch, useAppSelector} from "commons/store/hooks";
import DashboardLayoutWrapper from "commons/wrappers/DashboardLayoutWrapper";
import ColoredBox from "components/ColoredBox";
import UserForm from "features/users/components/UserForm";
import {
    editUser,
    fetchUser,
    selectUserDetails,
} from "features/users/redux/usersSlice";
import {Formik} from "formik";
import {useSnackbar} from "notistack";
import React, {useEffect} from "react";
// import { useParams } from "react-router";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";

type UserEditParams = {
    id: string;
};

const editUserValidationSchema = yup.object().shape({
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string().required(),
    roles: yup.string().required(),
});

const UserEdit = () => {
    const {id} = useParams<UserEditParams>();
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useAppDispatch();
    const userDetails = useAppSelector(selectUserDetails);
    const navigate = useNavigate();
    const initialValues = {
        firstname: userDetails?.firstname || "",
        lastname: userDetails?.lastname || "",
        email: userDetails?.email || "",
        phone: userDetails?.phone || "",
        roles: userDetails?.roles
            ? (userDetails?.roles[0] as any)
            : ("" as any),
        medicalCenters: userDetails?.medicalCenters?.map(
            (medicalCenter) => medicalCenter.id || ""
        ) || [""],
    };

    useEffect(() => {
        if (id) {
            dispatch(fetchUser(id));
        }
    }, [dispatch, id]);

    const handleSubmit = async (values: typeof initialValues) => {
        try {
            await dispatch(editUser({id, values}));
            navigate(ROUTES_USERS.LIST);
        } catch (error: any) {
            error &&
            enqueueSnackbar(error?.detail, {variant: 'error'});
        }
    };

    return (
        <DashboardLayoutWrapper>
            <ColoredBox p={1} width={352} maxWidth="100%" ml="auto" mr="auto">
                <Box mb={3.5}>
                    <Typography variant="h5">Edycja użytkownika</Typography>
                </Box>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    enableReinitialize
                    validationSchema={editUserValidationSchema}
                >
                    <UserForm/>
                </Formik>
            </ColoredBox>
        </DashboardLayoutWrapper>
    );
};

export default UserEdit;
