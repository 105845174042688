import { Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import React, {useState} from 'react';
import { StyledEcrfSectionsItemChild } from '../Ecrf.styled';

interface Props {
    section: any;
    selectedSection: any;
    setSelectedSection: (selectedSection: any) => void;
    isDataLoading: boolean;
    setIsDataLoading: (isDataLoading: boolean) => void;
    displayCount: boolean;
    changeSection: (section: any) => void;
}

export const MenuItemChild: React.FC<Props> = ({
   section,
   selectedSection,
   isDataLoading,
   setIsDataLoading,
   setSelectedSection,
   displayCount,
   changeSection
}) => {
    const [expandSection, setExpandSection] = useState(false);

    return (
        <>
            <StyledEcrfSectionsItemChild
                section={section}
                selected={selectedSection}
                onClick={() => {if (!isDataLoading) {
                    setExpandSection(!expandSection)
                    changeSection(section)
                }}}
            >
                {section?.name} {displayCount ? `(${section?.countElementsWithData}/${section?.countElements})` : ''}
                {section?.children.length > 0 && <>{expandSection ? <ExpandLess/> : <ExpandMore/>}</>}
            </StyledEcrfSectionsItemChild>

            {section?.children.length > 0 && (
                <Collapse in={expandSection} unmountOnExit>
                    {section?.children.map((childrenSection: any) => (
                        <MenuItemChild
                            key={childrenSection?.id}
                            section={childrenSection}
                            selectedSection={selectedSection}
                            setSelectedSection={setSelectedSection}
                            isDataLoading={isDataLoading}
                            setIsDataLoading={setIsDataLoading}
                            displayCount={displayCount}
                            changeSection={changeSection}
                        />
                    ))}
                </Collapse>
            )}
        </>
    );
};
