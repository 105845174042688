import {CircularProgress, Box} from '@mui/material';
import {useAppDispatch, useAppSelector} from 'commons/store/hooks';
import Nav from 'commons/wrappers/DashboardLayoutWrapper/components/Nav';
import {
    fetchDependencies,
    fetchEcrf,
    fetchEcrfElements,
    fetchSectionByEcrf,
    fetchVisitsWithCounters,
    getSectionId,
    resetCollection,
    resetCurrentSection,
    resetDependencies,
    resetEcrf,
    resetElementDatas,
    selectCurrentSection,
    selectSectionId,
    selectVisits,
} from 'features/ecrf/redux/ecrfSlice';
import React, {useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { StyledEcrfLayout, StyledEcrfTitle } from './Ecrf.styled';
import { FormContent } from './FormContent/FormContent';
import { LeftPanel } from './Menu/LeftPanel';
import { VisitsPanel } from './VisitsList/VisitsPanel';
import { useSnackbar } from 'notistack';
import { ROUTES_ECRF } from 'commons/constants/paths';

export const EcrfForm: React.FC = () => {
    const dispatch          = useAppDispatch();
    const navigate          = useNavigate();
    const {id, section}     = useParams<{ id: string, section: string }>();
    const {enqueueSnackbar} = useSnackbar();

    /**
     * State is holding props from routing redirect
     * @example: from dashboard, ae or query
     */
    const redirectSection = useAppSelector(selectSectionId);

    /** Current selected section data */
    const currentSection = useAppSelector(selectCurrentSection);

    /** Visits */
    const visits = useAppSelector(selectVisits);

    /** State is holding actual selected visit */
    const [selectedVisit, setSelectedVisit] = useState<any>({});

    /** State is holding actual selected section */
    const [selectedSection, setSelectedSection] = useState<any>({});

    /** Initial loading state status */
    const [initialLoading, setInitialLoading] = useState(true);

    /** Response pending status - if is true you can't change section */
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState('Ładowanie danych...')

    /**
     * Initial config - load first section of first visit
     */
    const initialConfig = async (visits: any) => {
        setIsDataLoading(true);

        if (!visits) return;
        setSelectedVisit(visits[0]);

        if (visits.length === 0 || !visits[0]?.sections[0]) return;
        setSelectedSection(visits[0].sections[0]);

        if (visits[0].sections[0]?.id) {
            await fetchSectionData(visits[0].sections[0].id, visits)
            await fetchElements(visits[0].sections[0].id)
        }

        setIsDataLoading(false);
    };

    /**
     * fetchElements()
     */
    const fetchElements = async (sectionId: any) => {
        setDataLoading('Ładowanie zależności elementów...')
        await dispatch(fetchDependencies(sectionId));
        setDataLoading('Ładowanie danych elementów...')
        await dispatch(fetchEcrfElements({id, sectionId}));
    }

    /**
     * fetchSectionData()
     * @todo one fn reload current section (elements, data, etc)
     */
    const fetchSectionData = async (sectionId: any, visits: any = []) => {
        setDataLoading('Ładowanie danych sekcji...')
        // const section: any = await dispatch(fetchSection(sectionId)).unwrap();
        const section: any = await dispatch(fetchSectionByEcrf({id: sectionId, ecrfId: id})).unwrap();
        if (id && section?.id) {
            navigate(ROUTES_ECRF.DETAILS_EXTENDED(id, section?.id || ''))
            setSelectedSection(section)
        }

        if ((visits || visits.length > 0)) {
            const visit = visits.find((visit: any) => visit?.id === section?.visit?.id);
            if (visit) setSelectedVisit(visit)
        }
    };

    /**
     * loadSectionData()
     */
    const loadSectionData = async (sectionId: any, visits: any = []) => {
        if (isDataLoading) return;

        setIsDataLoading(true);

        try {
            await fetchSectionData(sectionId, visits)
            await fetchElements(sectionId)
        } catch (error: any) {
            enqueueSnackbar(error?.detail, {variant: 'error'});
        }

        setIsDataLoading(false);
    };

    /** Config for redirect option */
    const redirectConfig = async (visits?: any) => {
        setIsDataLoading(true);

        if (redirectSection?.section) {
            await fetchSectionData(redirectSection.section, visits)
            await fetchElements(redirectSection.section)
        }

        if (redirectSection?.element) {
            setIsOpenQueryPanel(redirectSection.element);
        }

        /** clear redirect-data after redirect */
        dispatch(getSectionId({}));

        setIsDataLoading(false);
    };

    /** Config for section from route params */
    const routeSectionConfig = async (sectionId: string, visits?: any) => {
        setIsDataLoading(true);

        try {
            await fetchSectionData(sectionId, visits)
            await fetchElements(sectionId)
        } catch (e: any) {
            await initialConfig(visits)
        }

        setIsDataLoading(false);
    };

    /** Status of Queries Panel */
    const [isOpenQueryPanel, setIsOpenQueryPanel] = useState<null | string>(null);

    /**
     * Used to fetch patient data including ECRF data and visits
     */
    const fetchEcrfData = async () => {
        // init data ecrf
        setInitialLoading(true);

        setDataLoading('Ładowanie wizyt...')
        const visits = await dispatch(fetchVisitsWithCounters(id)).unwrap();
        setDataLoading('Ładowanie danych ecrf...')
        const ecrf = id ? await dispatch(fetchEcrf(id)).unwrap() : null;

        // init data section
        // incoming redirect data (AE, Query)
        if (redirectSection?.section !== undefined) {
            await redirectConfig(visits);
        } else if (section) {
            await routeSectionConfig(section, visits)
        // if exists current `section` in ecrf data
        } else if (ecrf?.currentSection?.id) {
            await fetchSectionData(ecrf?.currentSection?.id, visits)
        } else {
            await initialConfig(visits)
        }

        setInitialLoading(false);
    }

    const changeSectionWithVisit = async () => {
        if (selectedVisit?.sections && selectedVisit?.sections.length > 0 && !isDataLoading) {
            setIsDataLoading(true);
            try {
                dispatch(resetCurrentSection());
                setSelectedSection(selectedVisit.sections[0]);

                await loadSectionData(selectedVisit?.sections[0]?.id)
            } catch (error: any) {
                enqueueSnackbar(error?.detail || error?.message, {variant: 'error'});
            }

            setIsDataLoading(false);
        }
    }

    useEffect(() => {
        fetchEcrfData();

        /** clear state after unmount */
        return () => {
            dispatch(resetEcrf());
            dispatch(resetCurrentSection());
            dispatch(resetElementDatas());
            dispatch(resetCollection());
            dispatch(resetDependencies());
        };
    }, [id]);

    useEffect(() => {
        if (Object.keys(selectedVisit).length > 0) {
            changeSectionWithVisit();
        }
    }, [selectedVisit])

    useEffect(() => {
        if (
            (Object.keys(selectedSection).length > 0)
            && selectedSection?.id
            && selectedSection?.id !== currentSection?.id
        ) {
            loadSectionData(selectedSection?.id, visits)
        }
    }, [selectedSection])

    return (
        <>
            <Nav />
            {!initialLoading && (
                <StyledEcrfLayout>
                    <LeftPanel
                        selectedVisit={selectedVisit}
                        selectedSection={selectedSection}
                        setSelectedSection={setSelectedSection}
                        isDataLoading={isDataLoading}
                        setIsDataLoading={setIsDataLoading}
                    />
                    <VisitsPanel
                        selectedVisit={selectedVisit}
                        setSelectedVisit={setSelectedVisit}
                        isDataLoading={isDataLoading}
                    />
                    <FormContent
                        isDataLoading={isDataLoading}
                        setSelectedVisit={setSelectedVisit}
                        setIsDataLoading={setIsDataLoading}
                        selectedSection={selectedSection}
                        setSelectedSection={setSelectedSection}
                        isOpenQueryPanel={isOpenQueryPanel}
                        setIsOpenQueryPanel={setIsOpenQueryPanel}
                        updateSection={loadSectionData.bind(this)}
                    />
                </StyledEcrfLayout>
            )}

            {/** Initial view with loading animation */}
            {initialLoading && (
                <Box display="grid" style={{placeItems: 'center', height: '100vh'}}>
                    <Box display="grid" style={{placeItems: 'center', gap: '15px'}}>
                        <CircularProgress />
                        <StyledEcrfTitle>{dataLoading}</StyledEcrfTitle>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default EcrfForm;
