import React from 'react';

interface Error {
    message: string;
}

export const SnackbarErrors = ({ title = 'Wystąpił błąd', errors = [] }: { title: string; errors: Error[] }) => {
    return (
        <div>
            <p style={{ marginBottom: '10px' }}>
                <b>{title}</b>
            </p>
            {errors.map((error: Error) => (
                <div style={{ marginBottom: '10px' }}>{error.message}</div>
            ))}
        </div>
    );
};
