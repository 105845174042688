import { Box, Button, Typography } from "@mui/material";
import {ROUTES_DASHBOARD} from "commons/constants/paths";
import { useAppDispatch } from "commons/store/hooks";
import TextFieldFormik from "components/Form/TextFieldFormik";
import { login } from "core/redux/userSlice";
import { Form, Formik } from "formik";
import { useSnackbar} from "notistack";
import { Navigate, useNavigate } from "react-router-dom";
import ColoredBox from "components/ColoredBox";
import yup from "commons/yup";
import { Link } from "react-router-dom";
import { ROUTES_CORE } from "commons/constants/paths";
import { getToken, isTokenExpired } from "commons/auth/tokens";
import { PROJECT_NAME } from "commons/constants/env";
import { useState } from "react";

const validationSchema = yup.object({
    email: yup.string().email().required(),
    password: yup.string().required(),
});

const Login = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const token = getToken();

    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)

    const initialValues = {
        email: "",
        password: "",
    };

    const handleSubmit = async (values: typeof initialValues) => {
        setIsProcessing(true)
        try {
            await dispatch(login(values));
            navigate(ROUTES_DASHBOARD.DASHBOARD);
            setIsProcessing(false)
        } catch (error: any) {
            setIsProcessing(false)
            enqueueSnackbar('Wpisano błędne dane. Wpisz ponownie poprawne dane', {
                variant: 'error',
            });
        }
    };

    if (token && !isTokenExpired(token)) {
        return <Navigate to={ROUTES_DASHBOARD.DASHBOARD}/>;
    }

    return (
        <Box
            minHeight="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
            pl={2}
            pr={2}
        >
            <ColoredBox p={5} width={352} maxWidth="100%">
                <Typography variant="body2">{PROJECT_NAME}</Typography>
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    <Form>
                        <Typography variant="h5">Logowanie</Typography>

                        <Box mt={3.5}>
                            <TextFieldFormik
                                autoFocus
                                margin="dense"
                                name="email"
                                label="Email"
                                type="email"
                                variant="outlined"
                                fullWidth
                            />
                            <TextFieldFormik
                                margin="dense"
                                name="password"
                                label="Hasło"
                                type={isPasswordVisible ? 'text' : 'password'}
                                variant="outlined"
                                fullWidth
                            />
                            <Button size={"small"} onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                color={'primary'}> {isPasswordVisible ? 'Ukryj hasło' : 'Pokaż hasło'} </Button>
                        </Box>
                        <Box mt={2} display="flex" justifyContent="flex-end">
                            <Box>
                                <Button type="submit" color="primary" variant="contained" disabled={isProcessing}>
                                    Zaloguj
                                </Button>
                            </Box>
                        </Box>
                        <Box mt={1.5} display="flex" justifyContent="flex-end">
                            <Button
                                color="primary"
                                component={Link}
                                to={ROUTES_CORE.FORGOT_PASSWORD}
                                sx={{ textTransform: 'unset' }}
                            >
                                Zmień hasło
                            </Button>
                        </Box>
                    </Form>
                </Formik>
            </ColoredBox>
        </Box>
    );
};

export default Login;
