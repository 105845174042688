import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { getRoleByRoleId } from "commons/constants/ditionaries";
import { ROUTES_USERS } from "commons/constants/paths";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import DashboardLayoutWrapper from "commons/wrappers/DashboardLayoutWrapper";
import ColoredBox from "components/ColoredBox";
import ConfirmationAction from "components/ConfirmationAction";
import Table from "components/Table";
import {
  deleteUser,
  fetchUsers,
  selectUsers,
} from "features/users/redux/usersSlice";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const UsersList = () => {
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsers);

  useEffect(() => {
    dispatch(fetchUsers({ page }));
  }, [dispatch, page]);

  const handleDeleteUser = async (userId: string) => {
    await dispatch(deleteUser(userId));
    await dispatch(fetchUsers({ page }));
  };

  return (
    <DashboardLayoutWrapper>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
          component={Link}
          to={ROUTES_USERS.ADD}
          variant="contained"
          color="primary"
          disabled={true}
        >
          Dodaj użytkownika
        </Button>
      </Box>
      <ColoredBox p={1} maxWidth="100%" ml="auto" mr="auto">
        <Table
          pagination={{
            pageSize: users.itemsPerPage,
            current: page,
            total: users.totalItems,
          }}
          onChangePage={(page) => setPage(page)}
          data={users.data}
          tableName="List użytkowników"
          columns={[
            {
              title: "Imię",
              render: (row: any) => row.firstname,
              key: "firstname",
            },
            {
              title: "Nazwisko",
              render: (row: any) => row.lastname,
              key: "lastname",
            },
            {
              title: "Email",
              render: (row: any) => row.email,
              key: "email",
            },
            {
              title: "Telefon",
              render: (row: any) => row.phone,
              key: "phone",
            },
            {
              title: "Role",
              render: (row: any) => row.roles && getRoleByRoleId(row.roles[0]),
              key: "role",
            },
            {
              title: "Actions",
              noWrap: true,
              render: (row: any) => (
                <div>
                  <Box mr={1} display="inline-flex">
                    <Button
                      component={Link}
                      to={ROUTES_USERS.EDIT(row.id || "")}
                      variant="contained"
                      color="primary"
                      size="small"
                    >
                      Edytuj
                    </Button>
                  </Box>
                  <ConfirmationAction
                    onClick={() => handleDeleteUser(row.id || "")}
                  >
                    {({ handleClick }) => (
                      <Button
                        type="button"
                        onClick={handleClick}
                        variant="contained"
                        size="small"
                        color="primary"
                      >
                        Usuń
                      </Button>
                    )}
                  </ConfirmationAction>
                </div>
              ),
              key: "actions",
            },
          ]}
        />
      </ColoredBox>
    </DashboardLayoutWrapper>
  );
};

export default UsersList;
