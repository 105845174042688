import React, { useState } from 'react';
import { Box, Tab } from "@mui/material";
import { useAppSelector } from "commons/store/hooks";
import { useSearchParams } from "react-router-dom";
import { selectDrugTypes } from "../../drugs/redux/drugsSlice";
import { TabContext } from "@mui/lab";
import TabList from "@mui/lab/TabList/TabList";

export const FilterTab = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [selected, setSelected] = useState<string>(searchParams.get('type') ?? '');
    const drugTypes = useAppSelector(selectDrugTypes);

    const handleChangeTab = async (event: any, value: any) => {
        setLoading(true);

        if (value === null || value === '') {
            searchParams.delete('type');
        } else {
            searchParams.set('type', String(value));
        }

        setSelected(value);
        setSearchParams(searchParams);

        setLoading(false);
    }

    return (
        <Box mt={3}>
            <TabContext value={selected}>
                <Box>
                    <TabList
                        onChange={handleChangeTab.bind(this)}
                        aria-label="lab API tabs example" textColor="primary"
                        indicatorColor="primary"
                    >
                        <Tab disabled={loading} label={'Wszystkie leki'} value={''} key={'*'} />
                        {drugTypes?.data?.map((drug: any, index) =>
                            <Tab disabled={loading} label={drug?.name} value={drug?.id} key={index} />
                        )}
                    </TabList>
                </Box>
            </TabContext>
        </Box>
    );
};
