/*
 * Project: ecrf-front
 * Author: Dominik Obłoza
 * User: @dominik.obloza
 * Date: 14.01.2022
 * Time: 09:35
 */

import React from 'react';
import { Box, CircularProgress } from '@mui/material';

export const DataLoading: React.FC = () => {
    return (
        <Box mt={12} display="grid" width="100%" style={{ placeItems: 'center' }}>
            <CircularProgress color="primary" />
            <Box mt={2}>Ładowanie danych</Box>
        </Box>
    );
};
