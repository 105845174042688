/*
 * Project: ecrf-front
 * Author: Dominik Obłoza
 * User: @dominik.obloza
 * Date: 14.01.2022
 * Time: 14:06
 */

import Nav from 'commons/wrappers/DashboardLayoutWrapper/components/Nav';
import React, { Component } from 'react';
import {
    StyledDocumentationWrapper,
    StyledDocumentationLayout,
    StyledDocumentationTitle,
    StyledDocumentationTable,
    StyledDocumentationButton,
    StyledDocumentationDate,
} from './Documentation.styled';
import { RowItemEvents } from './RowItem';
import AddIcon from '@mui/icons-material/Add';
import { Box, CircularProgress, MenuItem, Select, FormControl, FormLabel, TextField } from '@mui/material';
import DocumentationService from '../services/documentation-service';
import { IDocManagerData, IDocPutData } from '../services/documentation-service/AbstractResources/DocManager';
import { IDocUploaderProps } from '../services/documentation-service/AbstractResources/DocUploader';
import EventStore from '../../../core/modules/event-store';
import { connect } from 'react-redux';
import { roles } from 'commons/auth/roles';
import { RESEARCH } from '../../../commons/constants/env';
import { StyledEcrfTitle } from '../../ecrf/views/EcrfForm/Ecrf.styled';
import QueriesPanel from '../components/QueriesPanel';
import { HistoryModal } from '../components/HistoryModal';

import FileManagerView from '../components/FileManagerView';
import { withSnackbar } from 'utils/withSnackbar';
import { SnackbarErrors } from '../components/SnackbarErrors';

interface IDocumentationState {
    initialLoading: boolean;
    isDataLoading: boolean;
    isModalOpen: boolean;
    isModalSelectOpen: boolean;
    selectedModalSelectItem: any;
    allDocs: any;
    selectedItem: any;
    selectedTypeId: any;
    isAllSelected: boolean;
    isDataUploading: boolean;
    isDataWorking: boolean;
    isOpenHistoryModal?: boolean;
    isOpenQueryPanel?: boolean;
    medicalCenters: any[];
    participants: any[];
    categories: any[];
    actualTab: any;
    version: string;
    type: number; // type 1: Private, type 0: Public
    selectedCenters: string[];
    file?: any;
    isDataChanged?: boolean;
    selectedMedicalCenter: string; // eISF number
    fileType: string; // eTMF / eISF
    selectedFileCategoryId: string | null;
    files: any[] | null;
    filesLoading: boolean;
}

class Documentation extends Component<any, IDocumentationState> {
    private readonly eventStore: EventStore;
    private readonly docService: DocumentationService;
    private readonly fileInp: React.RefObject<HTMLInputElement>;
    private readonly addFile: boolean = true;
    private readonly hasAccess: boolean;
    private readonly title: string = 'Zarządzanie dokumentacją';
    private readonly titleList: string = 'eTMF';
    private readonly hasStaffMemeberRole: boolean;
    private readonly hasAdminRole: boolean;

    constructor(props: unknown) {
        super(props);
        this.state = {
            initialLoading: true,
            isDataLoading: true,
            isModalOpen: false,
            isModalSelectOpen: false,
            selectedModalSelectItem: undefined,
            allDocs: [],
            selectedItem: '',
            selectedCenters: [],
            categories: [],
            selectedTypeId: '',
            actualTab: '',
            isAllSelected: false,
            isDataUploading: false,
            isDataWorking: false,
            isOpenHistoryModal: false,
            isOpenQueryPanel: false,
            medicalCenters: [],
            participants: [],
            type: 0,
            version: '',
            file: undefined,
            isDataChanged: false,
            selectedMedicalCenter: '',
            fileType: '',
            selectedFileCategoryId: null,
            files: null,
            filesLoading: false,
        };
        this.eventStore = new EventStore();
        this.docService = new DocumentationService();
        this.fileInp = React.createRef();
        this.hasAccess = this.props.userProfile.roles.some(
            (role: string) => roles.admin.includes(role) || roles.control.includes(role)
        );
        this.hasAdminRole = this.props.userProfile.roles.some(
            (role: string) => roles.admin.includes(role) || roles.monitor.includes(role) || roles.control.includes(role)
        );
        this.hasStaffMemeberRole = this.props.userProfile.roles.some((role: string) =>
            roles.staffMember.includes(role)
        );
        this.setSelectedType = this.setSelectedType.bind(this);
        this.sendDocument = this.sendDocument.bind(this);

        switch (RESEARCH) {
            case 'IMPROVE': {
                this.title = 'Raporty centralne';
                this.titleList = 'Wszystkie pliki';
                this.addFile = this.props.userProfile.roles.some(
                    (role: string) => roles.admin.includes(role) || ['ROLE_CENTRAL_ASSESSMENT'].includes(role)
                );
            }
        }
    }

    private async handleChangeFileType(type: any) {
        this.setState({
            isDataLoading: true,
            fileType: type,
            selectedMedicalCenter: '',
            allDocs: [],
            files: null,
            selectedFileCategoryId: null,
        });

        if (type === 'eTMF' && type !== '') {
            try {
                this.setState({ actualTab: '' });
                await this.fetchAllDocs();
            } catch (err: any) {
                alert(err?.detail);
            }
        } else {
            this.setState({ isDataLoading: false });
            //TODO
        }
    }

    private async handleChangeCenter(id: any) {
        this.setState({
            isDataLoading: true,
            selectedMedicalCenter: id,
            allDocs: [],
            files: null,
            selectedFileCategoryId: null,
        });

        if (id !== '') {
            try {
                this.setState({ actualTab: id });
                await this.fetchFilterDocs(id);
            } catch (err: any) {
                alert(err?.detail);
            }
        } else {
            this.setState({ actualTab: '' });
            await this.fetchAllDocs();
        }
    }

    private handleChangeFileCategoryId(id: string) {
        this.setState({ selectedFileCategoryId: id });
    }

    private handleVersion(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ version: e.target.value });
    }

    private setSelectedType(type: any) {
        this.setState({ selectedTypeId: type });
    }

    public get selectedFile(): File | undefined {
        if (!this.fileInp.current) {
            return undefined;
        }
        if (!this.fileInp.current.files) {
            return undefined;
        }
        if (this.fileInp.current.files.length === 0) {
            return undefined;
        }
        return this.fileInp.current.files[0];
    }

    /**
     * @sendDocument is used to upload selected document.
     * @private
     */
    private async sendDocument() {
        if ((!this.state.selectedFileCategoryId && !this.state.selectedItem?.id) || !this.selectedFile) {
            return;
        }
        const self = this;

        let data = null;
        if (RESEARCH === 'IMPROVE') {
            data = {
                file: this.selectedFile,
                ecrf: ['/api/ecrves/' + this.state.selectedFileCategoryId],
                medicalCenters: this.state.selectedMedicalCenter && [
                    '/api/medical_centers/' + this.state.selectedMedicalCenter,
                ],
                version: this.state.version,
            } as IDocUploaderProps;
        } else {
            data = {
                file: this.selectedFile,
                category: `/api/file_categories/${this.state.selectedFileCategoryId}`,
                medicalCenters: this.state.selectedMedicalCenter && [
                    '/api/medical_centers/' + this.state.selectedMedicalCenter,
                ],
                version: this.state.version,
            } as IDocUploaderProps;
        }

        this.setState({ isDataUploading: true });

        try {
            await self.docService.docUploader().upload(data);
            this.fetchFiles(this.state.selectedFileCategoryId);
            self.fetchDocs();
        } catch (error: any) {
            let errors;
            if (error?.response?.data?.violations) {
                errors = error?.response?.data?.violations.map((error: { message: string }) => ({
                    message: error.message,
                }));
            } else if (error?.response?.data?.detail || error?.message || error?.detail) {
                errors = [{ message: error?.response?.data?.detail || error?.message || error?.detail }];
            }
            this.props.enqueueSnackbar(
                <SnackbarErrors title="Wystąpił błąd podczas dodawania pliku" errors={errors} />,
                { variant: 'error' }
            );
        }

        if (this.fileInp.current?.value) {
            this.fileInp.current.value = '';
        }
        self.setState({ isDataUploading: false, type: 1, version: '' });
    }

    private async changeStatus(doc: IDocPutData) {
        let status = ['draft', 'approved', 'opened', 'outdated'].findIndex((element) => element === doc.statusName);

        if (!status) {
            return;
        }

        const request = {
            id: doc.id,
            status: status,
        };

        await this.docService.docManager().put(request);
        await this.fetchAllDocs();
        this.fetchFiles(this.state.selectedFileCategoryId);
    }

    private async softDelete(doc: IDocManagerData) {
        await this.docService.docManager().softDelete(doc.id);
        this.fetchDocs();
        this.setState({ files: this.state.files?.filter((file) => file.id !== doc.id) ?? null });
    }

    private async download(doc: IDocManagerData) {
        return await this.docService.docManager().download(doc);
    }

    private async fetchAllDocs(): Promise<void> {
        let response = [];
        let result = [];

        switch (RESEARCH) {
            case 'IMPROVE':
                response = await this.docService.docManager().participants();

                for (const item of response) {
                    const data = {
                        name: item?.screeningNumber,
                        files: item?.files ?? [],
                        children: item?.children ?? [],
                        ...item,
                    };
                    result.push(data);
                }

                break;
            default:
                response = await this.docService.docManager().all();

                if (this.state.actualTab !== '') {
                    result = await this.fetchFilterDocs(this.state.actualTab);
                } else {
                    result = response;
                }

                break;
        }

        this.setState({
            allDocs: result,
            categories: response,
            isDataLoading: false,
            selectedFileCategoryId: this.state.selectedFileCategoryId || result[0]?.id,
        });
    }

    private async fetchFilterDocs(id: string): Promise<any[]> {
        try {
            const response = await this.docService.docManager().allWithFilters(id);
            this.setState({ allDocs: response, isDataLoading: false, selectedFileCategoryId: response[0]?.id });
            return [...response] as any[];
        } catch (err: any) {
            alert(err?.detail);
            return [];
        }
    }

    fetchFiles = async (selectedFileCategoryId: string | null) => {
        this.setState({ filesLoading: true });
        try {
            let response;
            if (RESEARCH === 'IMPROVE') {
                response = await this.docService
                    .docManager()
                    .ecrfFiles(selectedFileCategoryId, this.state.selectedMedicalCenter);
            } else {
                response = await this.docService
                    .docManager()
                    .categoryFiles(selectedFileCategoryId, this.state.selectedMedicalCenter);
            }
            this.setState({ files: response, filesLoading: false });
        } catch (error) {
            this.setState({ filesLoading: false });
        }
    };

    private async fetchMedicalCenters(): Promise<void> {
        const response = await this.docService.docManager().centersList();
        response && this.setState({ medicalCenters: response, initialLoading: false, isDataLoading: false });
    }

    private async fetchUserMedicalCenters(): Promise<void> {
        const response = await this.docService.docManager().userCentersList();
        response && this.setState({ medicalCenters: response, initialLoading: false, isDataLoading: false });
        this.handleChangeCenter(response[0]?.id);
    }

    private async fetchDocs(): Promise<void> {
        if (this.hasStaffMemeberRole) {
            this.fetchUserMedicalCenters();
        } else {
            await this.fetchMedicalCenters();
        }
    }

    listenForEvents(): void {
        const self = this;

        this.eventStore.on(RowItemEvents.ROW_ITEM_CHANGE_STATUS_EVENT, async function (e: CustomEvent) {
            await self.changeStatus(e.detail as IDocPutData);
        });

        this.eventStore.on(RowItemEvents.ROW_ITEM_DOWNLOAD_EVENT, async function (e: CustomEvent) {
            await self.download(e.detail as IDocManagerData);
        });

        this.eventStore.on(RowItemEvents.ROW_ITEM_DELETE_EVENT, async function (e: CustomEvent) {
            await self.softDelete(e.detail as IDocManagerData);
        });
    }

    componentDidMount() {
        this.fetchDocs().then(() => {
            this.hasAdminRole && this.handleChangeFileType('eTMF');
        });
        this.listenForEvents();
    }

    render() {
        let showContent;
        let showAddFileForm;
        let showFileTypeSelect = true;
        let showCenterSelect = this.state.fileType === 'eISF';

        if (RESEARCH === 'IMPROVE') {
            showFileTypeSelect = false;
            showContent = this.state.selectedMedicalCenter;
            showAddFileForm = showContent && this.state.selectedFileCategoryId;
        } else {
            showContent =
                (this.hasStaffMemeberRole && this.state.selectedMedicalCenter) ||
                this.state.fileType === 'eTMF' ||
                (this.state.fileType === 'eISF' && this.state.selectedMedicalCenter);
            showAddFileForm = showContent && this.state.selectedFileCategoryId;
        }

        if (this.hasStaffMemeberRole) {
            showFileTypeSelect = false;
            showCenterSelect = true;
        }

        return (
            <>
                <Nav />
                {!this.state.initialLoading && (
                    <>
                        <StyledDocumentationWrapper>
                            <StyledDocumentationLayout>
                                {this.addFile && (
                                    <>
                                        <StyledDocumentationTitle>{this.title}</StyledDocumentationTitle>
                                    </>
                                )}
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ padding: '0 0 10px 0', display: 'flex', alignItems: 'center' }}>
                                        {/* {RESEARCH !== 'IMPROVE' && ( */}
                                        {showFileTypeSelect && (
                                            <FormControl variant="outlined">
                                                <FormLabel>Wybierz eTMF lub eISF</FormLabel>
                                                <Select
                                                    value={this.state.fileType}
                                                    onChange={(event) => this.handleChangeFileType(event.target.value)}
                                                    style={{ width: '100px' }}>
                                                    <MenuItem
                                                        value="eTMF"
                                                        style={{ display: 'flex', justifyContent: 'center' }}>
                                                        eTMF
                                                    </MenuItem>
                                                    <MenuItem
                                                        value="eISF"
                                                        style={{ display: 'flex', justifyContent: 'center' }}>
                                                        eISF
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        )}

                                        {(RESEARCH === 'IMPROVE' || showCenterSelect) && (
                                            <FormControl variant="outlined" style={{ paddingLeft: '20px' }}>
                                                <FormLabel>Wybierz numer ośrodka</FormLabel>
                                                <Select
                                                    value={this.state.selectedMedicalCenter}
                                                    onChange={(event) => this.handleChangeCenter(event.target.value)}
                                                    style={{ width: '100px' }}>
                                                    {this.state.medicalCenters.map((center: any, i: number) => (
                                                        <MenuItem
                                                            value={center.id}
                                                            key={i}
                                                            style={{ display: 'flex', justifyContent: 'center' }}>
                                                            {center.number}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                    </div>
                                    {showAddFileForm && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                border: '1px dashed #dddddd',
                                                borderRadius: '8px',
                                                padding: '10px 20px',
                                                alignItems: 'center',
                                                gap: '10px',
                                                background: '#F7F7F7',
                                            }}>
                                            <div>
                                                <b>Dodaj plik:</b>
                                            </div>
                                            <Box>
                                                <TextField
                                                    fullWidth
                                                    value={this.state.version}
                                                    variant={'outlined'}
                                                    onChange={this.handleVersion.bind(this)}
                                                    label={'Podaj wersję'}
                                                    size={'small'}
                                                    disabled={this.state.isDataUploading}
                                                    style={{ background: 'white' }}
                                                />
                                            </Box>

                                            <StyledDocumentationDate
                                                type="file"
                                                style={{ cursor: 'pointer' }}
                                                ref={this.fileInp}
                                                disabled={this.state.isDataUploading}
                                            />
                                            <div>
                                                {this.state.isDataUploading ? (
                                                    <div
                                                        style={{
                                                            width: '100px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                        }}>
                                                        <CircularProgress size={12} />
                                                    </div>
                                                ) : (
                                                    <StyledDocumentationButton
                                                        style={{ width: '100px' }}
                                                        onClick={() => {
                                                            if (!this.selectedFile) return;
                                                            this.sendDocument();
                                                        }}>
                                                        <AddIcon />
                                                        Dodaj plik
                                                    </StyledDocumentationButton>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <StyledDocumentationTable>
                                        {this.state.isDataLoading ? (
                                            <Box
                                                display="grid"
                                                style={{ placeItems: 'center', height: 'calc(100vh - 300px)' }}>
                                                <Box display="grid" style={{ placeItems: 'center', gap: '15px' }}>
                                                    <CircularProgress />
                                                    <StyledEcrfTitle>Ładowanie danych</StyledEcrfTitle>
                                                </Box>
                                            </Box>
                                        ) : showContent ? (
                                            <div>
                                                <FileManagerView
                                                    selectedFileCategoryId={this.state.selectedFileCategoryId}
                                                    handleChangeFileCategoryId={(id) =>
                                                        this.handleChangeFileCategoryId(id)
                                                    }
                                                    data={this.state.allDocs}
                                                    files={this.state.files}
                                                    filesLoading={this.state.filesLoading}
                                                    fetchFiles={this.fetchFiles}
                                                />
                                            </div>
                                        ) : null}
                                    </StyledDocumentationTable>
                                </div>
                            </StyledDocumentationLayout>
                        </StyledDocumentationWrapper>
                    </>
                )}
                {/** History modal */}
                {this.state.isOpenHistoryModal && (
                    <HistoryModal
                        isOpen={this.state.isOpenHistoryModal}
                        onClose={() => this.setState({ isOpenHistoryModal: false })}
                        file={this.state.file}
                    />
                )}
                {/** Queries panel */}
                {this.state.isOpenQueryPanel && (
                    <QueriesPanel
                        isOpen={this.state.isOpenQueryPanel || false}
                        onClose={() => this.setState({ isOpenQueryPanel: false })}
                        file={this.state.file}
                        queryId={this.props.id}
                    />
                )}

                {/** Initial view with loading animation */}
                {this.state.initialLoading && (
                    <Box display="grid" style={{ placeItems: 'center', height: '100vh' }}>
                        <Box display="grid" style={{ placeItems: 'center', gap: '15px' }}>
                            <CircularProgress />
                            <StyledEcrfTitle>Ładowanie danych</StyledEcrfTitle>
                        </Box>
                    </Box>
                )}
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({
    userProfile: state.core.user.userProfile,
});

export default connect(mapStateToProps, null)(withSnackbar(Documentation));
