/*
 * Project: ecrf-front
 * Author: Dominik Obłoza
 * User: @dominik.obloza
 * Date: 09.03.2022
 * Time: 11:04
 */

import {Box, Checkbox, CircularProgress, ListItemText, ListSubheader} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, {Component} from 'react';
import Button from "@mui/material/Button";
import ProvideService from "./ProvideService";
import { enqueueSnackbar } from 'notistack'
import DateTime from "../../../core/helpers/date/DateTime";
import {API_URL, DATE_FORMAT} from "../../../commons/constants/env";

interface StateProvideFormik {
    selectedNumbers: any;
    displayDrugs: boolean
    isLoading: boolean
    allocatedDrugs: any[]
    selectedIds: any
    isProvided: boolean
    isProcessing: boolean
}

interface IProvideFormik {
    ecrfId: string,
    name: string,
    data: any,
    enqueueSnackbar?: any;
}

class ProvideFormik extends Component<any, StateProvideFormik> {

    private readonly provideService: ProvideService;

    constructor(props: any) {
        super(props);

        this.state = {
            selectedIds: [],
            selectedNumbers: [],
            displayDrugs: false,
            isLoading: false,
            allocatedDrugs: [],
            isProvided: false,
            isProcessing: false,
        }

        this.provideService = new ProvideService();
        this.showDrugs = this.showDrugs.bind(this)
        this.provideDrugs = this.provideDrugs.bind(this)
    }

    /**
     * This method is used to
     * fetch available drugs
     * @private
     */
    private async fetchAllocation() {
        this.setState({isLoading: true});

        try {
            const response: any = await this.provideService.allocation(this.props.ecrfId, this.props.name);

            if (response?.status === 200) {
                this.setState({allocatedDrugs: response.data.totalItems > 0 ? response.data?._embedded?.item : []});
                this.setState({displayDrugs: true});
            }

            this.setState({isLoading: false});

            if (response?.status === 400 || response?.status === 500) {
                this.props.enqueueSnackbar(response?.data?.detail, {
                    variant: "error",
                })
            }
        } catch (error: any) {
            enqueueSnackbar(error?.detail || error?.data?.detail, {variant: 'warning'});
        }
    }

    private selectNumber(value: any) {
        const idsArray: any[] = this.state.selectedIds

        if (idsArray.includes(value.id)) {
            const removeNumber = this.state.selectedNumbers.filter((number: any) => number !== value.number)
            const removeId = this.state.selectedIds.filter((id: any) => id !== value.id)

            this.setState({selectedIds: removeId});
            this.setState({selectedNumbers: removeNumber});

        } else {
            this.setState({selectedIds: [...this.state.selectedIds, value.id]});
            this.setState({selectedNumbers: [...this.state.selectedNumbers, value.number]});
        }
    }

    /**
     * This method is used to display select with drugs
     * @private
     */
    private async showDrugs() {
        await this.fetchAllocation();
    }

    /**
     * This method is used to provide drugs
     * @private
     */
    private async provideDrugs() {
        this.setState({isProcessing: true});
        const dataReq = []
        for (const id of this.state.selectedIds) {
            const dataItem = {
                drugManagement: '/api/drug_managements/' + id,
                element: '/api/elements/' + this.props.name
            }
            dataReq.push(dataItem);
        }

        const response: any = await this.provideService.provide(this.props.ecrfId, {drugs: dataReq});

        if (response?.status === 200) {
            this.setState({isProvided: true, isProcessing: false});
            await this.provideService.putElementData(this.props.name, this.props.ecrfId, this.state.selectedNumbers)
            await this.props.update()

            enqueueSnackbar('Leki zostały wydane!', {variant: 'success'})
        }

        if (response?.status === 400 || response?.status === 500 || response?.status === 422) {
            this.setState({isProcessing: false});

            enqueueSnackbar(response?.detail || response?.data?.detail, {variant: 'error'})
        }
    }


    /**
     * This method is used to build item
     * string which is displaying in select
     * @param drug
     * @private
     */

    private static buildSelectItemString(drug: any): string {
        const number = drug?.number || 'Nieznany numer'
        const serial = drug?.serial || 'Nieznana seria'
        const expireDate = DateTime.toIso(drug?.expiredAt, DATE_FORMAT)
        const spacer = " / "

        return number + spacer + serial + spacer + expireDate
    }


    /**
     * This method is used to download pdf
     * @private
     */
    private downloadPdf() {
        window.open(`${API_URL}/api/ecrves/${this.props.ecrfId}/${this.props.name}/pdf/drug_provide`, '_blank')
    }

    render() {
        return (
            <div>
                {/* <--- Display this banner after provide drugs ---> */}
                {this.props.data && (
                    <Box p={2} bgcolor={'#DCFCE7'} color={'#16A34A'} borderRadius={1} mb={1}>
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'10px'}
                            flexWrap={'wrap'}>
                            <Box>
                                Leki zostały wydane.
                            </Box>
                            <Button onClick={() => this.downloadPdf()} color={'secondary'}>
                                Pobierz PDF
                            </Button>
                        </Box>

                        <Box display={'flex'} flexWrap={'wrap'} mt={1} gap={'10px'}>
                            {[].slice.call(this.props.data).map((drug: string) =>
                                <div>
                                    {drug}
                                </div>
                            )}
                        </Box>
                    </Box>
                )}

                {!this.state.displayDrugs && (
                    <Box display={'grid'} justifyContent={'center'} p={3}>
                        <Button onClick={this.showDrugs} color={'primary'} variant={'contained'}>
                            Pokaż dostępne leki
                        </Button>
                    </Box>
                )}

                {this.state.isLoading && (
                    <Box display={'grid'} justifyContent={'center'} p={3}>
                        <CircularProgress/>
                    </Box>
                )}

                {this.state.displayDrugs && (
                    <>

                        {this.state.allocatedDrugs.length === 0 && (
                            <Box p={2} bgcolor={'#FEE2E2'} color={'#DC2626'} borderRadius={1} mb={1}>
                                Brak dostępnych leków.
                            </Box>
                        )}

                        <Select multiple fullWidth variant={'outlined'} id="grouped-select"
                            value={this.state.selectedNumbers}
                            disabled={this.state.isProvided
                                || this.state.allocatedDrugs.length === 0 || this.props.status}
                            // MenuProps={{getContentAnchorEl: null}}
                            renderValue={() => this.state.selectedNumbers.join(', ')}>

                            {[].slice.call(this.state.allocatedDrugs).map((group: any, idx) =>
                                <div key={group.id}>
                                    <ListSubheader>Lek {idx + 1} - Numer / Seria / Data ważności</ListSubheader>
                                    {group?._embedded?.drugManagements && (
                                        <>
                                            {[].slice.call(group?._embedded?.drugManagements).map((drug: any) =>
                                                <MenuItem key={drug.id} value={drug.id}
                                                    onClick={() => this.selectNumber(drug)}>
                                                    <Checkbox
                                                        checked={this.state.selectedIds.includes(drug.id)}
                                                        disabled={this.props.status}
                                                    />
                                                    <ListItemText primary={ProvideFormik.buildSelectItemString(drug)}/>
                                                </MenuItem>
                                            )}
                                        </>
                                    )}

                                    {!group?._embedded?.drugManagements && (
                                        <ListSubheader>
                                            - Brak dostępnych leków
                                        </ListSubheader>
                                    )}
                                </div>
                            )}
                        </Select>

                        <Box mt={1}>
                            <Button
                                onClick={this.provideDrugs}
                                fullWidth
                                variant={'outlined'}
                                disabled={this.state.selectedIds.length === 0
                                    || this.state.isProvided
                                    || this.state.isProcessing
                                    || this.props.status}
                            >
                                Wydaj leki
                            </Button>
                        </Box>
                    </>
                )}
            </div>
        );
    }
}

export default ProvideFormik;