import { Box, Button, CircularProgress, Typography } from "@mui/material";
import {useAppDispatch, useAppSelector} from "commons/store/hooks";
import FormField from "features/ecrf/views/EcrfForm/FormField";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import Dependency from "core/helpers/Dependency";
import {
  fetchElementDataCollection,
  fetchElementDatas, postElementDataCollection, postElementDataCollections,
  putCollectElementData,
  selectCollection
} from "features/ecrf/redux/ecrfSlice";
import SaveIcon from "@mui/icons-material/Save";
import QueriesPanel from "../../../features/ecrf/components/QueriesPanel";
import { roles } from 'commons/auth/roles';
import { selectUserProfile } from "../../../core/redux/userSlice";
import ModalAutoSave from "./ModalAutoSave";
import {flatten, forEach} from "lodash";
import {useSnackbar} from "notistack";
import {StyledEcrfTitle} from "../../../features/ecrf/views/EcrfForm/Ecrf.styled";

type ModalFormContentProps = {
  id?: string | null;
  elements: any[];
  elementsData?: any;
  elementsDatas?: any[];
  isLoading?: boolean;
  dataLoading?: any;
  isSaving?: boolean;
  setSaving?: (isSaving: any) => void | undefined;
  handleClose: () => void;
  handleSubmit: (values: any) => void;
  ecrfId: string;
  submitText: string;
  initialValues?: any;
  title?: string;
  buttonDisabled?: boolean;
};

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const ModalFormContent = ({
  id,
  elements,
  elementsData,
  elementsDatas,
  isLoading,
  dataLoading,
  isSaving,
  setSaving = () => {},
  handleClose,
  handleSubmit,
  ecrfId,
  submitText,
  initialValues,
  title,
  ...props
}: ModalFormContentProps) => {
  const dispatch = useAppDispatch();
  const collection  = useAppSelector(selectCollection);
  const userProfile = useAppSelector(selectUserProfile);
  const { enqueueSnackbar } = useSnackbar();

  const [dependencySection, setDependencySection] = useState<any>([]);
  const [actualValues, setActualValues] = useState<any>([]);
  const [isOpenQueryPanel, setOpenQueryPanel] = useState<null | string>(null);
  const [autoSaving, setAutoSaving] = useState<any>(null)

  /** Timer debounce state */
  const [timer, setTimer] = useState<any>(null);

  const handleDependencies = async (elements: any, formikValues: any) => {
    // @todo call depends from new structure
    const dependencies = await Dependency.createModalArray(elements, formikValues);
    setDependencySection(dependencies);
  };

  /**
   * Handle dependencies. Function will start 1s after stop typing
   */
  const updateForm = () => {
    clearTimeout(timer);
    setTimer(
      setTimeout(async () => {
        await handleDependencies(elements, actualValues);

        /** TODO: Refresh formik state onload */
        if (actualValues.length === 0 && elementsDatas) setDependencySection(elements);
      }, 500)
    );
  };

  const onSave = async (values: any, data: any) => {
    setSaving(true)
    setAutoSaving(values)

    const promises = [] as Array<Promise<any>>;
    const collectionId: any = collection?.id || id;
    let collections: any[] = [];
    let dataId = elementsData?.id || collection?.dataId;

    if (collectionId) {
      // map previous elements data
      collections = Object.values(data || []).map(
          (el: any) => {
            if (typeof values[el?.element?.id] !== 'undefined') {
              return {
                id: `/api/element_datas/${el.id}`,
                data: flatten([values[el?.element?.id]]),
              }
            }

            return {
              id: `/api/element_datas/${el.id}`,
            }
          }
      )

      forEach(values, (value, eId) => {
        if (Object.values(data || []).findLast(
            (el: any) => el?.element?.id === eId
        )) {
          return;
        }

        collections.push({
          ecrf: `/api/ecrves/${ecrfId}`,
          element: `/api/elements/${eId}`,
          data: flatten([value]),
        });
      });

      promises.push(dispatch(
          putCollectElementData({id: collectionId, elements: collections})
      ))
    } else {
      let bindData: any = [];
      forEach(values, (value, eId) => {
        bindData.push({
          ecrf: `/api/ecrves/${ecrfId}`,
          element: `/api/elements/${eId}`,
          data: flatten([value]),
        });
      });

      if (dataId) {
        const collection = await dispatch(postElementDataCollections({
          datas: [{id: `/api/element_datas/${dataId}`}],
          elements: bindData,
        })).unwrap();

        dataId && await dispatch(fetchElementDatas(dataId));
      } else {
        if (collection?.elementId && ecrfId) {
          promises.push(dispatch(
              postElementDataCollection({elementId: collection?.elementId, ecrfId, elements: bindData})
          ));
        }
      }
    }

    await Promise.all(promises)
        .then(async () => {
          collectionId && await dispatch(fetchElementDataCollection(collectionId));
          dataId && await dispatch(fetchElementDatas(dataId));
          setAutoSaving(null)
        })
        .catch((error) => {
          enqueueSnackbar(error?.detail || error?.message, {
            variant: 'warning',
            autoHideDuration: 2000,
            preventDuplicate: true,
          });
          setAutoSaving(null)
        });
  };

  /** Using to re-render section elements */
  useEffect(() => {
    if (elements) updateForm();
  }, [actualValues, elements]);

  return (
    <>
      <Box fontSize="30px" fontWeight="bold" mb={5}>{title} {autoSaving ? <CircularProgress size={17} /> : ''}</Box>
      {elements && !isLoading && dependencySection.length > 0 ? (
        <>
          <Formik
            enableReinitialize
            initialValues={initialValues || {}}
            validate={(values) => {
              const valuesArray = Object.entries(values);
              setActualValues(valuesArray);
            }}
            onSubmit={handleSubmit}
          >
            <Form>
              {collection?.options?.autosave && (
                  <ModalAutoSave isSaving={autoSaving} setSaving={setSaving} onSave={(a, b) => onSave(a, b)}/>
              )}

              {dependencySection?.map((element: any) => {
                const childData = elementsDatas?.findLast((el: any) => el.elementName === element.name);

                if (element.type === 'divider') {
                  return (
                    <Box mt={5} mb={2}>
                      <Typography variant={"h4"}>{element.label}</Typography>
                    </Box>
                  );
                }

                return (
                  <FormField
                    actions={['history', 'clear', 'status', 'query']}
                    element={element}
                    key={element.id}
                    ecrfId={ecrfId}
                    hasActionPanel={element?.helper === 0}
                    hasBoxContainer={userProfile.roles.some(
                        (role: string) => (roles.admin.includes(role))
                    )}
                    hasElementDataInstance={!!childData}
                    hasQueryPanel={element?.helper === 0}
                    statusElement={collection?.status === 3 || isSaving}
                    isAllSaving={isSaving}
                    elementStatus={childData?.status}
                    elementData={childData}
                    isOpenQueryPanel={isOpenQueryPanel}
                    setIsOpenQueryPanel={() => setOpenQueryPanel(childData?.elementName)}
                    onClear={(params: any) => params.setValue('')}
                    parent={{
                      id: collection?.elementId,
                      type: collection?.elementType
                    }}
                    autoSave={
                      autoSaving && (typeof autoSaving[childData?.element?.id] !== 'undefined')
                    }
                  />
                );
              })}
              <Box textAlign="end">
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginTop: 24, marginRight: 12 }}
                  onClick={handleClose}
                >
                  CLOSE
                </Button>
                {!collection?.options?.autosave && dependencySection?.find((element: any) => !['file'].includes(element?.type)) && (
                  <Button
                      variant="contained"
                      color="primary"
                      style={{marginTop: 24}}
                      type="submit"
                      disabled={isSaving}
                      startIcon={(isSaving) ? <CircularProgress color={"inherit"} size={14}/> : <SaveIcon/>}
                  >
                    {submitText}
                  </Button>
                )}
              </Box>
            </Form>
          </Formik>

          {/* <--- Queries Panel ---> */}
          <QueriesPanel
              currentEcrfId={collection?.ecrfId || ''}
              elementDataId={elementsDatas?.find(
                  (data: any) => data?.elementName === isOpenQueryPanel
              )?.id || null}
              isOpen={!!isOpenQueryPanel}
              onClose={() => setOpenQueryPanel(null)}
          />
        </>
      ) : (
            <Box display="grid" style={{placeItems: 'center', gap: '15px'}} p={3}>
              <CircularProgress />
              <StyledEcrfTitle>{dataLoading}</StyledEcrfTitle>
            </Box>
      )}
    </>
  );
};

export default ModalFormContent;
