import {
    Box,
    Button,
    Checkbox,
    Collapse,
    FormControlLabel,
    MenuItem,
    TextField,
    Tooltip
} from "@mui/material";
import { ROUTES_ECRF } from "commons/constants/paths";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import ColoredBox from "components/ColoredBox";
import Table from "components/Table";
import {
    fetchEcrfVisits,
    resetEcrfVisits,
} from "features/dashboard/redux/dashboardSlice";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import DateTime from "core/helpers/date/DateTime";
import { selectUserProfile } from "core/redux/userSlice";
import { ecrfStatusDictionary } from "features/ecrf/views/EcrfForm/Menu/LeftPanel";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { StyledGlobalMedicalCentersFiltersButton } from "../DashboardStats/DashboardStats.styled";
import FilterListIcon from "@mui/icons-material/FilterList";
import DeleteSweepRoundedIcon from "@mui/icons-material/DeleteSweepRounded";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import { HalResource } from "types/halResource.types";
import { EcrfEcrfEcrfReadIdReadSectionReadCommentReadContentReadStatusReadOwnerRead } from "types/api.types";
import { DatePicker } from "@mui/x-date-pickers";
import { roles } from 'commons/auth/roles';
import ObservationList from "./ObservationList";
import { DATE_FORMAT, DATETIME_FORMAT } from "commons/constants/env";
import { enqueueSnackbar } from 'notistack'
import Ecrf from "../../../../core/helpers/Ecrf";

const DashboardEcrves = () => {
    const dispatch = useAppDispatch();
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(30);

    const [searchParams, setSearchParams] = useSearchParams();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        dispatch(resetEcrfVisits())
        setOpen(false)
    };

    const [ecrves, setEcrves] = useState<any>({
        itemsPerPage: 0,
        totalItems: 0,
        _embedded: {item: []}
    })

    /**
     * This function is used to fetch ecrves list
     */
    const [isDataLoading, setIsDataLoading] = useState(false)
    const fetchData = async (initial?: boolean) => {
        setIsDataLoading(true)

        try {
            const randomId = ecrfValue !== '' && !initial ? ecrfValue : null
            const status = selectedStatus !== '' && !initial ? selectedStatus : null
            const medicalCenter = centerValue !== '' && !initial ? centerValue.id : null

            const config = {
                headers: {
                    'Content-Type': 'application/hal+json',
                    Accept: 'application/hal+json',
                },
                params: {
                    pagination: true,
                    page,
                    itemsPerPage: itemsPerPage,
                    randomId,
                    status,
                    medicalCenter
                },
            }
            const response = await axiosSecureInstance.get<HalResource<EcrfEcrfEcrfReadIdReadSectionReadCommentReadContentReadStatusReadOwnerRead[]>>
            (`/api/ecrves?${selectedStartDate && !initial ? `&randomAt[after]=${selectedStartDate}` : ''}${selectedEndDate && !initial ? `&randomAt[before]=${selectedEndDate}` : ''}${isCheckboxSelected && !initial ? `&exists[elements.comment.children]=${1}` : ''}`, config)

            setEcrves(response.data)
        } catch (error: any) {
            enqueueSnackbar(error?.detail || error?.response?.data?.detail, {variant: 'info'})
        }

        setIsDataLoading(false)
    }

    /**
     * This function is used to fetch data of ecrf's visits
     */
    const [isVisitsLoading, setIsVisitsLoading] = useState(false)
    const fetchVisits = async (id: string) => {
        setIsVisitsLoading(true)
        handleOpen();
        await dispatch(fetchEcrfVisits(id))
        setIsVisitsLoading(false)
    }

    /**
     * Check user role and hide export button for
     * All users except: Admin, Coordinator, Monitor, CRO, Sponsor
     */
    const userProfile = useAppSelector(selectUserProfile);

    const displayAE: boolean = userProfile.roles.some(
        (role: string) => (
            roles.admin.includes(role) ||
            roles.monitor.includes(role) ||
            roles.researcher.includes(role)
        )
    );

    // const displayExportButton: boolean = userProfile.roles.some(
    //     (role: string) => roles.admin.includes(role) || roles.monitor.includes(role)
    // );

    const displayMedicalCenterFilter: boolean = userProfile.roles.some(
        (role: string) => roles.admin.includes(role) || roles.research.includes(role)
    );

    // const displayListObservation: boolean = userProfile.roles.some(
    //     (role: string) => !roles.dataManager.includes(role) && !roles.control.includes(role)
    // );

    useEffect(() => {
        fetchMedicalCenters().then()
        fetchData().then()
    }, [dispatch, page]);

    /* FILTERS ZONE */
    /**
     * Fetch medical centers list and save it to state display in select
     */
    const [medicalCentersList, setMedicalCentersList] = useState<any[]>([])
    const fetchMedicalCenters = async () => {
        const response = await axiosSecureInstance.get<any>(`/api/medical_centers`)
        response && setMedicalCentersList(response.data)
    }

    /**
     * Handle select value of medical center
     */
    const [centerValue, setCenterValue] = useState<any>("")
    const handleCenterValue = (e: any) => {
        setCenterValue(e.target.value)
    }

    // /** Open/close menu list status */
    // const [isMenuOpen, setIsMenuOpen] = useState(false);

    /**
     * Handle change value of ecrf input
     */
    const [ecrfValue, setEcrfValue] = useState<string>("")
    const handleEcrfValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEcrfValue(e.target.value)
    }

    /**
     * Handle select checkbox for 'contains queries'
     */
    const [isCheckboxSelected, setIsCheckboxSelected] = useState<boolean>(false)
    const handleSelectCheckbox = () => {
        setIsCheckboxSelected(!isCheckboxSelected)
    }

    /**
     * Handle select start date of randomization range
     */
    const [selectedStartDate, setSelectedStartDate] = useState<any>(null)
    const handleSelectStartDate = (e: any) => {
        const formattedDate = DateTime.toIso(e, DATE_FORMAT)
        setSelectedStartDate(formattedDate)
    }

    /**
     * Handle select end date of randomization range
     */
    const [selectedEndDate, setSelectedEndDate] = useState<any>(null)
    const handleSelectEndDate = (e: any) => {
        const formattedDate = DateTime.toIso(e, DATE_FORMAT)
        setSelectedEndDate(formattedDate)
    }

    /**
     * Handle select status
     */
    const [selectedStatus, setSelectedStatus] = useState<any>('')
    const handleStatus = (e: any) => {
        setSelectedStatus(e.target.value)
    }

    /**
     * Change status of filters buttons
     */
    const [displayFilters, setDisplayFilters] = useState(false)
    const [isButtonFiltersDisabled, setIsButtonFiltersDisabled] = useState(true)
    const changeButtonFiltersStatus = () => {
        if (selectedStatus !== '' || selectedEndDate !== null || selectedStartDate !== null || isCheckboxSelected ||
            ecrfValue !== '' || centerValue !== "") setIsButtonFiltersDisabled(false)
        else {
            setIsButtonFiltersDisabled(true)
        }
    }

    useEffect(() => {
        changeButtonFiltersStatus()
    }, [selectedStatus, selectedEndDate, selectedStartDate, isCheckboxSelected, ecrfValue, centerValue])

    const columns = () => {
        const columns: any[] = [
            {
                title: <Box>Numer screeningowy</Box>,
                render: (row: any) => (
                    <Box
                        style={{
                            opacity: Ecrf.statusClosed(row?.status) ? '50%' : '100%',
                            textAlign: "center",
                        }}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                paddingLeft: "30px",
                                paddingRight: "30px",
                            }}
                            component={Link}
                            to={ROUTES_ECRF.DETAILS(row?.id || '')}
                        >{row?.screeningNumber}</Button>
                    </Box>
                ),
                key: 'participantNumber',
            },
            {
                title: <Box ml="20px">Data randomizacji</Box>,
                render: (row: any) => (
                    <Box minWidth="120px"
                         style={{opacity: Ecrf.statusClosed(row?.status) ? '50%' : '100%'}}>
                        {row?.randomAt && (
                            <Box>
                                {DateTime.toIso(row?.randomAt, DATETIME_FORMAT)}
                            </Box>
                        )}
                    </Box>
                ),
                key: 'randomAt',
            },
            {
                title: <Box ml="20px">Numer randomizacyjny</Box>,
                render: (row: any) => (
                    <Box minWidth="120px"
                         style={{opacity: Ecrf.statusClosed(row?.status) ? '50%' : '100%'}}>
                        {row?.randomAt && <Box>{row?.randomId}</Box>}
                    </Box>
                ),
                key: 'randomNumber',
            },
            {
                title: <Box ml="20px">Numer ośrodka</Box>,
                render: (row: any) => (
                    <Box style={{opacity: Ecrf.statusClosed(row?.status) ? '50%' : '100%'}}
                    >{row?._embedded?.medicalCenter?.number}</Box>
                ),
                key: 'medicalCenter',
            },
            {
                title: <Box ml="20px">Status pacjenta</Box>,
                render: (row: any) => (
                    <Box style={{opacity: Ecrf.statusClosed(row?.status) ? '50%' : '100%'}}>
                        {ecrfStatusDictionary[row?.status]}
                    </Box>
                ),
                key: 'ecrfStatus',
            },
            {
                title: <Box ml="20px">Nazwisko badacza</Box>,
                render: (row: any) => (
                    <Box style={{opacity: Ecrf.statusClosed(row?.status) ? '50%' : '100%'}}
                    >{row?._embedded?.owner?.lastname}</Box>
                ),
                key: 'secondName',
            },
            {
                title: <Box ml="20px">Lista obserwacji</Box>,
                render: (row: any) => (
                    <Box style={{opacity: Ecrf.statusClosed(row?.status) ? '50%' : '100%'}}>
                        <div>
                            <Button
                                disabled={isVisitsLoading
                                    // || !displayListObservation
                            }
                                variant="outlined"
                                color="primary"
                                size="small"
                                startIcon={<FormatListBulletedIcon/>}
                                onClick={() => {
                                    fetchVisits(row?.id);
                                }}
                            >
                                Wybierz
                            </Button>
                        </div>
                    </Box>
                ),
                key: 'list',
            },
        ]

        displayAE && columns.push({
            title: <Box textAlign={'center'} ml="20px">AE</Box>,
            render: (row: any) => (
                <Box textAlign={'center'}
                     style={{opacity: Ecrf.statusClosed(row?.status) ? '50%' : '100%'}}>
                    <Box display="inline-flex">
                        <Button
                            component={Link}
                            disabled={!row?.adverseEventSection}
                            to={ROUTES_ECRF.DETAILS_EXTENDED(row.id, row?.adverseEventSection)}
                            variant="outlined"
                            color="primary"
                            size="small"
                            endIcon={<CallMadeIcon/>}
                        >
                            PRZEJDŹ
                        </Button>
                    </Box>
                </Box>
            ),
            key: 'aesae',
        })

        columns.push({
            title: <Box textAlign={'center'} minWidth="60px">Queries</Box>,
            render: (row: any) => (
                <Box style={{opacity: Ecrf.statusClosed(row?.status) ? '50%' : '100%', placeItems: "center"}}
                     textAlign={'center'}
                     display="grid">
                    <Box
                        fontWeight={row?.unreadComments > 0 ? 700 : 400}
                        display="flex"
                        textAlign="center"
                    >
                        {row?.unreadComments}
                    </Box>
                </Box>
            ),
            key: 'queries',
        })

        columns.push({
            title: <Box ml="20px">Formularz</Box>,
            noWrap: true,
            render: (row: any) => (
                <Box style={{opacity: Ecrf.statusClosed(row?.status) ? '50%' : '100%'}}>
                    <Box display="inline-flex">
                        <Button
                            component={Link}
                            to={ROUTES_ECRF.DETAILS(row?.id || "")}
                            variant="outlined"
                            color="primary"
                            size="small"
                            endIcon={<CallMadeIcon/>}
                        >
                            Przejdź
                        </Button>
                    </Box>
                </Box>
            ),
            key: 'actions',
        })

        return columns
    }

    /**
     * Clear all filters values
     */
    const clearFilters = () => {
        setSelectedStatus('')
        setSelectedEndDate(null)
        setSelectedStartDate(null)
        setEcrfValue('')
        setIsCheckboxSelected(false)
        setCenterValue('')
        setPage(1)
        setDisplayFilters(false)
        fetchData(true).then()
    }

    return (
        <ColoredBox pt={0} px={5} maxWidth="100%" ml="auto" mr="auto">
            <Box display={'flex'} gap={'20px'} justifyContent={'end'}>
                {/*<Box mt={'20px'}>*/}
                {/*    {displayExportButton &&*/}
                {/*        <Button size={'small'} color={'primary'} variant={'outlined'}*/}
                {/*            onClick={() => window.open(`${process.env.REACT_APP_API_URL}/api/ecrves/export.xlsx`, "_blank")}>*/}
                {/*            Export*/}
                {/*        </Button>*/}
                {/*    }*/}
                {/*</Box>*/}
                <Button style={{whiteSpace: 'nowrap', marginTop: '20px'}} color={'primary'} size={'small'}
                    onClick={() => setDisplayFilters(!displayFilters)} variant={'outlined'}>
                    {displayFilters ? 'Ukryj filtry' : 'Pokaż filtry'}
                </Button>
            </Box>

            {/* <--- FILTERS ZONE | BEGIN ---> */}
            <Collapse in={displayFilters}>
                <Box display={'flex'} alignItems={'center'} mt={1} border={`1px solid #e7e7e7`} p={3} borderRadius={'10px'}>
                    <Box display="grid" gridTemplateColumns={'1fr 1fr 1fr 1fr 1fr'} alignItems="center" style={{gap: "20px"}}>
                        <Box display={'flex'} gap={'20px'} alignItems="center">
                            <Tooltip title="Wprowadź początek zakresu randomizacji" arrow>
                                <>
                                    <DatePicker
                                        // animateYearScrolling
                                        value={selectedStartDate}
                                        // size={'small'}
                                        label={'Początek'}
                                        // inputVariant="outlined"
                                        // fullWidth
                                        format="yyyy-MM-dd"
                                        onChange={(e: any) => handleSelectStartDate(e)}
                                        slotProps={{ textField: { size: 'small' } }}
                                    />
                                </>
                            </Tooltip>
                            -
                            <Tooltip title="Wprowadź koniec zakresu randomizacji" arrow>
                                <>
                                    <DatePicker
                                        // animateYearScrolling
                                        value={selectedEndDate}
                                        // size={'small'}
                                        label={'Koniec'}
                                        // inputVariant="outlined"
                                        // fullWidth
                                        format="yyyy-MM-dd"
                                        onChange={(e: any) => handleSelectEndDate(e)}
                                        slotProps={{ textField: { size: 'small' } }}
                                    />
                                </>
                            </Tooltip>
                        </Box>

                        <Tooltip title="Wybierz ośrodek badawczy" arrow>
                            <TextField
                                disabled={!displayMedicalCenterFilter}
                                select
                                fullWidth
                                variant={'outlined'}
                                label={'Ośrodek badawczy'}
                                size={'small'}
                                onChange={(e: any) => handleCenterValue(e)}
                                value={centerValue}
                            >
                                {/** Display list of all numbers */}
                                {medicalCentersList.map((value: any) => (
                                    <MenuItem key={value?.id} value={value}>
                                        {value?.number} {" | "}
                                        {value?.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Tooltip>

                        <Tooltip title="Wprowadź numer randomizacyjny badania" arrow>
                            <TextField
                                label={'Numer randomizacyjny'}
                                onChange={handleEcrfValue}
                                value={ecrfValue}
                                fullWidth
                                variant={'outlined'}
                                size={'small'}
                            />
                        </Tooltip>

                        <Tooltip title="Wybierz status" arrow>
                            <TextField
                                select
                                fullWidth
                                variant={'outlined'}
                                label={'Status badania'}
                                onChange={(e: any) => handleStatus(e)}
                                value={selectedStatus}
                                size={'small'}
                            >
                                {/** Display list of all numbers */}
                                {ecrfStatusDictionary.map((value: string, index) => (
                                    <MenuItem key={value} value={index}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Tooltip>

                        <Tooltip title="Pokaż badania z niezamkniętymi Querami" arrow>
                            <FormControlLabel
                                control={<Checkbox
                                    onClick={() => handleSelectCheckbox()}
                                    checked={isCheckboxSelected}
                                />}
                                label="Zawierające Queries"/>
                        </Tooltip>

                        <Box display={'flex'} gap={'20px'} alignItems={'center'}>
                            <Tooltip title={'Filtruj po wybranych opcjach'} arrow>
                                <StyledGlobalMedicalCentersFiltersButton onClick={() => {
                                    if (!isButtonFiltersDisabled) fetchData().then()
                                }}
                                    status={!isButtonFiltersDisabled && !isDataLoading}>
                                    <FilterListIcon/> Filtruj
                                </StyledGlobalMedicalCentersFiltersButton>
                            </Tooltip>

                            <Tooltip title={'Wyczyść wszystkie filtry'} arrow>
                                <StyledGlobalMedicalCentersFiltersButton onClick={() => clearFilters()} status={true}>
                                    <DeleteSweepRoundedIcon/> Wyczyść
                                </StyledGlobalMedicalCentersFiltersButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
            </Collapse>
            {/* <--- FILTERS ZONE | END ---> */}

            <Table
                pagination={{
                    current: page,
                    pageSize: ecrves.itemsPerPage,
                    total: ecrves.totalItems,
                }}
                onChangePage={(page) => setPage(page)}
                data={ecrves._embedded?.item || []}
                tableName='Tabela uczestników badania'
                columns={columns()}
                isLoading={isDataLoading}
            />

            <ObservationList loading={isVisitsLoading} open={open} handleClose={handleClose}/>
        </ColoredBox>
    );
};

export default DashboardEcrves;
