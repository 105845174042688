import React, {useState} from 'react';
import {Box} from '@mui/material';
import styled from "styled-components";

import {COLOR_PRIMARY_LIGHT} from "../../../commons/constants/env";
import FileItem from "./FileItem";
import QueriesPanel from "../components/QueriesPanel";
import {HistoryModal} from "../components/HistoryModal";

interface FileListViewProps {
    files: any[] | null;
}

const FileListStyled = styled.div`
    width: 100%;
    overflow-y:auto;


    ::-webkit-scrollbar {
    width: 6px;
    }

    ::-webkit-scrollbar-thumb {
    background-color: ${COLOR_PRIMARY_LIGHT};
    border-radius: 6px;
    }

    ::-webkit-scrollbar-track {
    background-color: rgba(0,0,0,0);
    }
`

export default function FileListView(props: FileListViewProps) {
    const [file, setFile] = useState<any>();
    const [isOpenHistoryModal, setIsOpenHistoryModal] = useState(false);
    const [isOpenQueryPanel, setIsOpenQueryPanel] = useState(false);

    const handleHistoryModal = (file?: any) => {
        setFile(file);
        setIsOpenHistoryModal(true);
    }


    const handleQueryPanel = (file?: any) => {
        setFile(file);
        setIsOpenQueryPanel(true);
    }

    return (
        <>
            {props.files === null ? 
                (
                    <div></div>
                ) : (
                <FileListStyled>
                    {props.files.length === 0 ? 
                        (
                            <Box display="grid" style={{placeItems: 'center', width: '100%', padding: '140px 0'}}>
                                <div>Brak plików</div>
                            </Box>
                        ) : (
                            <>
                                {props.files.map((file: any, i:number) => {
                                    return(
                                        <FileItem
                                            key={i}
                                            variant={'public'}
                                            {...file}
                                            onHistoryModal={(file: any) => handleHistoryModal(file)}
                                            onQueryPanel={(file: any) => handleQueryPanel(file)}
                                        />
                                    )
                                })}

                                {isOpenHistoryModal && (
                                    <HistoryModal
                                        isOpen={isOpenHistoryModal}
                                        onClose={() => setIsOpenHistoryModal(false)}
                                        file={file}
                                    />
                                )}
                
                                {/** Queries panel */}
                                {isOpenQueryPanel && (
                                    <QueriesPanel
                                        isOpen={isOpenQueryPanel || false}
                                        onClose={() => setIsOpenQueryPanel(false)}
                                        file={file}
                                    />
                                )}
                            </>
                        )
                    }
                </FileListStyled>
                )
            }
        </>
    );
}